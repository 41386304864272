import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { getCollectionOrganizationDetails } from "src/api/collection/collectionService";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import ContentContext from "src/providers/content";
import MainLayout from "src/components/layouts/MainLayout";
import { CollectionOrganisationForm } from "./components/CollectionOrganisationForm";
import { LaboratoryFormWrapper } from "./components/LaboratoryFormWrapper";
import { PrincipalAndPracticeWrapper } from "./components/PrincipalAndPracticeWrapper";
import { CollectionOrganisationPageWrapper } from "./index.style";
export const CollectionOrganisationPage = () => {
    const [data, setData] = useState([]);
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const location = useLocation();
    const [adminInfo, setAdminInfo] = useState([]);
    const userInfo = useSelector(checkAuth.dataSelector);
    const getData = async () => {
        const result = await getCollectionOrganizationDetails(userInfo.accessToken);
        if (result.status === "error") {
            setData([]);
        }
        if (result.status === "success") {
            setData(result.data);
            setAdminInfo(result.data.adminInfo);
        }
        closeLoadingScreen();
    };
    useEffect(() => {
        if (userInfo) {
            openLoadingScreen();
            getData();
        }
    }, [userInfo]);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithNoTab, { location: location.pathname, heading: "Collection Organization", children: _jsxs(CollectionOrganisationPageWrapper, { children: [_jsx(CollectionOrganisationForm, { data: data }), _jsx(LaboratoryFormWrapper, { data: data }), _jsx(PrincipalAndPracticeWrapper, { adminInfo: adminInfo, setAdminInfo: setAdminInfo })] }) }) }));
};
