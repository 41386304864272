import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getCommunication = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/authority/communications`, {
            // params,
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCommunicationTemplates = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/authority/communication-templates`, {
            // params,
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
