import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Upload } from "antd";
import { useState } from "react";
import { uploadImage } from "src/api/uploadImage";
import { Wrapper } from "./index.style";
export const Uploadx = (props) => {
    const storagePath = props.storagePath || "equipment/images";
    const [file, setFile] = useState();
    const beforeUpload = (curFile) => {
        setFile(curFile);
        return false;
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const onChange = () => {
        if (file) {
            getBase64(file, async (imgUrl) => {
                props.loading(true);
                const url = await uploadImage(storagePath, imgUrl, file.type.split("/")[1]);
                props.onChange(url, file.name);
                props.loading(false);
            });
        }
    };
    return (_jsx(Wrapper, { children: _jsx(Upload, { className: "upload-btn", showUploadList: false, onChange: onChange, beforeUpload: beforeUpload, disabled: props.disabled, children: props.children }) }));
};
