import styled from "styled-components";
export const Wrapper = styled.div `
  background: #fafafa;
  height: 445px;
  width: 760px;
  margin-top: -220px;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    height: 50px;
    background: #1a8cff;
    border-radius: 4px 4px 0 0;
  }
  .box-title {
    flex: 30%;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 22px;
  }
  .close-btn {
    flex: 30%;
    text-align: right;
    margin-right: 15px;
  }
`;
