import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Image, Row, Select, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getDashboardDevicesData } from "src/api/dashboard/dashboardService";
import { getDeviceTypes } from "src/api/testingDevices/testingDeviceService";
import { mainAppColours } from "src/constants";
import { HeaderFile } from "./HeaderFile";
import { InUseDevicesTable } from "./Tables/InUseDevicesTable";
export const InUseDevices = () => {
    const [testDeviceTypeData, setTestDeviceTypeData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const userInfo = useSelector(checkAuth.dataSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState("All");
    const [deviceType, setDeviceType] = useState([]);
    useEffect(() => {
        if (testDeviceTypeData && testDeviceTypeData.length) {
            setDeviceType(testDeviceTypeData.map((item) => ({
                label: item.name,
                value: item.name,
            })));
        }
    }, [testDeviceTypeData]);
    const getDeviceTypeData = async () => {
        try {
            const result = await getDeviceTypes(userInfo.accessToken);
            if (result.status === "success") {
                setTestDeviceTypeData(result.data);
            }
            else {
                setTestDeviceTypeData([]);
            }
        }
        catch (error) {
            console.error("Error in getDeviceTypeData:", error);
        }
    };
    const getDeviceData = async () => {
        setIsLoading(true);
        try {
            const result = await getDashboardDevicesData(userInfo.accessToken);
            if (result.status === "success") {
                setDeviceData(result.data);
            }
            else {
                setDeviceData([]);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.error("Error in getDeviceTypeData:", error);
        }
    };
    useEffect(() => {
        if (userInfo) {
            getDeviceTypeData();
            getDeviceData();
        }
    }, [userInfo]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderFile, { name: "Testing Devices in Use", icon: "ic-test.svg" }), _jsxs(Row, { align: "middle", children: [_jsx(Col, { span: 12, style: { padding: "10px 15px" }, children: _jsx("span", { style: { fontSize: "16px" }, children: "In Use Devices" }) }), _jsx(Col, { span: 6, children: _jsxs("div", { style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }, children: [_jsx(Text, { style: { fontSize: 13, color: "black", marginRight: "10px" }, children: "Show" }), _jsx(Select, { defaultValue: "All", style: { width: 265 }, size: "small", options: [
                                        {
                                            label: "All",
                                            value: "All",
                                        },
                                        ...deviceType,
                                    ], onChange: (value) => setType(value) })] }) }), _jsx(Col, { span: 6, children: _jsx("div", { style: {
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }, children: _jsxs(Button
                            // onClick={handleAddNew}
                            , { 
                                // onClick={handleAddNew}
                                style: {
                                    border: `1px solid ${mainAppColours.MID_BLUE}`,
                                    borderRadius: "4px",
                                }, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#1A8CFF" }, children: "Add New" })] }) }) })] }), _jsx("div", { style: { position: "relative" }, children: isLoading && (_jsxs(_Fragment, { children: [_jsx(Spin, { style: {
                                position: "absolute",
                                top: "100px",
                                left: "40vw",
                            } }), _jsx("span", { style: {
                                color: "#156CC9",
                                position: "absolute",
                                top: "130px",
                                left: "37vw",
                            }, children: "Fetching table details..." })] })) }), _jsx("div", { style: { opacity: isLoading ? 0.3 : 1 }, children: _jsx(InUseDevicesTable, { deviceData: deviceData && deviceData.length > 0
                        ? type === "All"
                            ? deviceData
                            : deviceData.filter((item) => item.typeName === type)
                        : [] }) })] }));
};
