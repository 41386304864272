import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Popover } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Image, Text } from "src/components/base";
import { getDate } from "src/utils/helper";
import { DataTable } from "src/components/commonElement/DataTable";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppSelector } from "src/store/hooks";
import { TimeFormat } from "src/store/app/types";
import { TEST_COLOR } from "../store/types";
import { AudioPopover } from "./AudioPopover";
export const RecordsTable = ({ invalidResultData, selectedTestType, startDate, endDate, }) => {
    const filterInvalidResultData = (data, selectedValue, newStartDate, newEndDate) => {
        const validData = Array.isArray(data) ? data : [];
        return validData.filter((item) => {
            const isTypeMatch = selectedValue ? item.testTypeId === selectedValue : true;
            const createdAt = moment(getDate(item.createdAt));
            if (newStartDate && newEndDate) {
                return (isTypeMatch &&
                    createdAt.isBetween(newStartDate.startOf("day"), newEndDate.endOf("day"), null, "[]"));
            }
            else if (newStartDate && !newEndDate) {
                return isTypeMatch && createdAt.isSameOrAfter(newStartDate.startOf("day"));
            }
            else if (!newStartDate && newEndDate) {
                return isTypeMatch && createdAt.isSameOrBefore(newEndDate.endOf("day"));
            }
            else {
                return isTypeMatch;
            }
        });
    };
    const filteredData = filterInvalidResultData(invalidResultData, selectedTestType.value, startDate, endDate);
    const appInformation = useAppSelector(getApplicationInformation);
    const columns = [
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => _jsx("span", { children: moment(getDate(text)).format("DD/MM/YYYY") }),
            sorter: (a, b) => getDate(a.createdAt).getTime() - getDate(b.createdAt).getTime(),
            defaultSortOrder: "descend",
        },
        {
            title: "Time",
            dataIndex: "createdAt",
            key: "createdAt_time",
            render: (text) => (_jsx("span", { children: moment(getDate(text)).format(appInformation?.collectionOrganization.timeFormat === TimeFormat.TwelveHour
                    ? "HH:mm a"
                    : "HH:mm") })),
            sorter: (a, b) => getDate(a.createdAt).getTime() - getDate(b.createdAt).getTime(),
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            render: (text) => _jsx("a", { children: text }),
        },
        {
            title: "Lot Number",
            dataIndex: "lotNumber",
            key: "lotNumber",
            sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            sorter: (a, b) => a.reason.localeCompare(b.reason),
            render: (text, record) => record?.audioData && record.audioData.length > 0 ? (_jsx(CustomPopover, { content: _jsx(CardContent, { children: _jsx(AudioPopover, { audioData: record?.audioData }) }), trigger: "click", placement: "bottomRight", showArrow: false, children: _jsxs(React.Fragment, { children: [_jsx(Text, { name: text }), _jsx(Image, { src: "/assets/svg/ic-noti-info.svg", height: 18, width: 18, preview: false })] }) })) : (_jsx(Text, { name: text })),
        },
        {
            title: "Type of Test",
            dataIndex: "testName",
            key: "testName",
            sorter: (a, b) => a.testName.localeCompare(b.testName),
            render: (text) => _jsx(TestColorDiv, { background: TEST_COLOR[text], children: text }),
        },
        {
            title: "Reported by Collector",
            dataIndex: "collectorName",
            key: "collectorName",
            sorter: (a, b) => a.collectorName.localeCompare(b.collectorName),
        },
    ];
    return (_jsx(PageWrapper, { children: _jsx(DataTable, { columns: columns, dataSource: filteredData }) }));
};
const PageWrapper = styled.div `
  margin: 20px 0;

  .ant-typography {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-image-img {
    margin-left: 5px;
    cursor: pointer;
  }

  .ant-table table {
    width: 100% !important;
    border-top: 1px solid #adb3bc;
    border-bottom: 1px solid #adb3bc;
  }

  .ant-table-column-has-sorters {
    background-color: #e6f7fa !important;
  }
  .ant-table-container {
    width: 100% !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 30px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
    padding-top: 3px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
`;
const TestColorDiv = styled.div `
  background: ${(props) => props.background};
  display: flex;
  justify-content: center;
  padding: 5px 0;
  color: #fff;
  width: 60%;
  min-width: 200px;
  border-radius: 2rem;
`;
const CustomPopover = styled(Popover) `
  div.ant-popover-inner-content {
    padding: 0 !important;
    border: 0.1rem solid #1a8cff !important;
  }
`;
const CardContent = styled(Card) `
  border: none !important;
  width: 30rem;

  .ant-card-body {
    padding: 1rem !important;
  }
`;
