import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getCollectionPoints = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/collection-points`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getDeviceTypes = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/device-types`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestingDevice = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getArchivedTestingDevice = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/archived-devices`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const deleteTestingDevice = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/delete/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const archiveTestingDevice = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/archive/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const restoreTestingDevice = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/restore/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const toggleInUseTestingDevice = async (idToken, id, updateUsage) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/testing-devices/update-inuse/${id}`, updateUsage, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateTestingDevice = async (idToken, id, newData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/testing-devices/update/${id}`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createTestingDevice = async (idToken, newData) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/testing-devices/create/`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createTestingDeviceHistory = async (idToken, historyData) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/testing-devices/history/create`, historyData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestingDeviceHistory = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/testing-devices/history/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
