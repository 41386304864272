import { createSlice } from "@reduxjs/toolkit";
import { checkAuthentication, getAppInformation } from "./action";
import { appInitialState } from "./types";
export const initialState = {
    isAuth: false,
    user: null,
    app: appInitialState,
    isLoading: false,
};
const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setIsAuth(state, action) {
            state.isAuth = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkAuthentication.fulfilled, (state, action) => {
            const data = action.payload.data;
            if (data) {
                state.isAuth = true;
                state.user = data;
            }
        })
            .addCase(checkAuthentication.rejected, (state) => {
            state.isAuth = false;
        })
            .addCase(getAppInformation.fulfilled, (state, action) => {
            const data = action.payload.data;
            if (data) {
                state.app = data;
            }
            else {
                state.app = appInitialState;
            }
        })
            .addCase(getAppInformation.rejected, (state) => {
            state.app = appInitialState;
        });
    },
});
export const { setIsAuth, setUser, setIsLoading } = appSlice.actions;
export default appSlice.reducer;
