import { TestTypeRecordIndex } from "./types";
const getAuthStatus = (state) => state.authentication.isAuth;
const getUserData = (state) => state.authentication.user;
const getApplicationInformation = (state) => state.authentication.app;
const getCollectionOrganisationInformation = (state) => state.authentication.app.collectionOrganization;
const selectIsLoading = (state) => state.authentication.isLoading;
const selectCollectionPoints = (state) => state.authentication.app.collectionPoints;
const selectTestTypes = (state) => state.authentication.app.testTypes;
const selectDrugTestTypes = (state) => state.authentication.app.testTypes.filter((testType) => testType.recordIndex !== TestTypeRecordIndex.BreathAlcoholTest);
const getCollectionPointInformation = (state) => state.authentication.app.collectionPoints;
const getRequestedTestTypeInformation = (state) => state.authentication.app.testTypes;
export { getAuthStatus, getUserData, getApplicationInformation, selectIsLoading, selectCollectionPoints, selectTestTypes, selectDrugTestTypes, getCollectionOrganisationInformation, getCollectionPointInformation, getRequestedTestTypeInformation, };
