import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getCollectionManagerSetting } from "src/api/settings/settingService";
const defaultValue = {
    settings: {
        organizationSetting: {
            countryCode: "AU",
            timeFormat: "hh:mm a",
            dateFormat: "",
            timezone: "",
        },
        collectionPointSetting: [],
    },
    updateSettings: () => { },
    reload: false,
    setReload: () => { },
};
const SettingsContext = createContext(defaultValue);
export const SettingsContextProvider = ({ children }) => {
    const [settings, setSettings] = useState(defaultValue.settings);
    const [reload, setReload] = useState(false);
    const authData = useSelector(checkAuth.dataSelector);
    const fetchData = async () => {
        try {
            if (authData && authData.accessToken) {
                const fetchedSettings = await getCollectionManagerSetting(authData.accessToken);
                const timeFormat = fetchedSettings.data.organizationSetting.timeFormat === 24 ? "HH:mm" : "hh:mm a";
                const updatedOrganizationSetting = {
                    ...fetchedSettings.data.organizationSetting,
                    timeFormat: timeFormat,
                };
                const updatedFetchedSettings = {
                    ...fetchedSettings,
                    data: {
                        ...fetchedSettings.data,
                        organizationSetting: updatedOrganizationSetting,
                    },
                };
                setSettings(updatedFetchedSettings.data);
            }
            else {
                throwHttpRequestError("Error fetching settings: Auth data or access token is missing.");
            }
        }
        catch (error) {
            throwHttpRequestError("Error fetching settings:", error);
        }
    };
    useEffect(() => {
        if (authData) {
            fetchData();
        }
    }, [authData, reload]);
    const updateSettings = (newSettings) => {
        setSettings(newSettings);
    };
    return (_jsx(SettingsContext.Provider, { value: { settings, updateSettings, reload, setReload }, children: children }));
};
export const useSettings = () => useContext(SettingsContext);
