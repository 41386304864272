import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, Row, Select, Table } from "antd";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { SAColourScheme, mainAppColours } from "src/styles";
import { Text } from "src/components/base";
import { useAppSelector } from "src/store/hooks";
import { selectIsLoading } from "src/store/app/selector";
export const PaginatedDataTable = ({ dataSource, columns, pagingMetaData, onPageChange, }) => {
    const [size, setSize] = useState(pagingMetaData?.limit || 10);
    const [currentPage, setCurrentPage] = useState(pagingMetaData?.page || 1);
    const isLoading = useAppSelector(selectIsLoading);
    // Update pagination settings based on pagingMetaData
    useEffect(() => {
        setCurrentPage(pagingMetaData?.page || 1);
        setSize(pagingMetaData?.limit || 10);
    }, [pagingMetaData]);
    // Handle page change
    const handleTableChange = (pagination) => {
        const newPage = pagination.current;
        setCurrentPage(newPage);
        onPageChange(newPage, size);
    };
    const select = (pageSize) => {
        setSize(pageSize);
        const totalItems = pagingMetaData?.totalItem ?? 0;
        // Calculate the total number of pages after changing the page size
        const totalPages = Math.ceil(totalItems / pageSize);
        // If the current page exceeds the new total pages, reset to the first page
        const updatedPage = currentPage > totalPages ? 1 : currentPage;
        onPageChange(updatedPage, pageSize);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(TableWrapper, { children: _jsx(Table, { columns: [
                        {
                            title: "No.",
                            dataIndex: "no",
                            key: "no",
                            width: 70,
                            render: (_, __, index) => 
                            // Render index based on whether the data is loaded or not
                            !isLoading && (currentPage - 1) * size + index + 1,
                        },
                        ...columns,
                    ], dataSource: dataSource, loading: isLoading, scroll: { x: 900 }, pagination: {
                        current: currentPage,
                        pageSize: size,
                        total: pagingMetaData?.totalItem,
                        itemRender: (current, type, originalElement) => {
                            if (type === "prev") {
                                return (_jsx(PrevIcon, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, className: "prev-icon" }));
                            }
                            if (type === "next") {
                                return (_jsx(NextIcon, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, className: "next-icon" }));
                            }
                            return originalElement;
                        },
                        onChange: (page) => handleTableChange({ current: page }),
                    }, rowKey: (record) => record.id }) }), dataSource.length > 0 && (_jsxs(StyledRow, { justify: "start", align: "middle", children: [_jsx(Text, { name: "Show" }), _jsxs(Select, { value: size, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] })] }))] }));
};
const TableWrapper = styled.div `
  z-index: 10;
  width: 100% !important;

  .ant-table {
    border: 1px solid #d9d9d9;
  }

  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASSMA};
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }

  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
`;
const StyledRow = styled(Row) `
  margin-top: -50px;
  margin-left: 10px;
  display: flex !important;
  gap: 10px;
  width: 100% !important;

  .ant-typography {
    font-size: 1rem;
    color: #367893 !important;
  }
  .ant-select {
    max-width: 4.5em;
  }
  .ant-image-img {
    height: 8px;
    width: 14px;
  }
`;
const PrevIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const NextIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
