import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where, } from "firebase/firestore";
import { env } from "src/constants";
import { app } from "src/firebase";
import { throwHttpRequestError } from "src/infra/http";
const db = getFirestore(app);
export const getById = async (collectionName, docId) => {
    const docRef = doc(db, `${env.DATA_COLLECTION}/${collectionName}`, docId);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
        else {
            return new Error("Document does not exist");
        }
    }
    catch (error) {
        throwHttpRequestError(error);
        return error;
    }
};
export const searchData = async (collectionName, queryCondition, orderCondition) => {
    let queryRef = query(collection(db, `${env.DATA_COLLECTION}/${collectionName}`));
    if (queryCondition) {
        queryRef = query(queryRef, ...queryCondition.map((condition) => where(condition.fieldPath, condition.opStr, condition.value)));
    }
    if (orderCondition && orderCondition.length > 0) {
        queryRef = query(queryRef, ...orderCondition.map((order) => orderBy(order.fieldPath, order.directionStr)));
    }
    try {
        const querySnap = await getDocs(queryRef);
        if (!querySnap.empty) {
            return querySnap.docs.map((snapDoc) => ({ id: snapDoc.id, ...snapDoc.data() }));
        }
        else {
            return [];
        }
    }
    catch (error) {
        throwHttpRequestError(error);
        return error;
    }
};
