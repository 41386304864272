import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import Flag from "react-country-flag";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkAuth } from "src/api/auth/login";
import { getCollectionManagerSetting, updateCollectionManagerSetting, } from "src/api/settings/settingService";
import IconTitle from "src/components/IconTitle";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getAllCountryMapping, getAllTimezonesMapping } from "src/utils/helper";
const ManagerSetting = () => {
    const [timezones, setTimezones] = useState({});
    const [isCountryAndTimezoneEdited, setIsCountryAndTimezoneEdited] = useState(false);
    const [settingsForCollectionPoints, setSettingsForCollectionPoints] = useState([]);
    const navigate = useNavigate();
    const [countries, setCountries] = useState({});
    const [selectedCountry, setSelectedCountry] = useState("");
    const [rtoTime, setRtoTime] = useState(15);
    const authData = useSelector(checkAuth.dataSelector);
    const [timeFormat, setTimeFormat] = useState(0);
    const [dateFormat, setDateFormat] = useState("");
    const [timezoneGlobalSetting, setTimezoneGlobalSetting] = useState(null);
    const { reload, setReload } = useSettings();
    const { openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    useEffect(() => {
        setTimezones(getAllTimezonesMapping());
        setCountries(getAllCountryMapping());
        getSettingData();
    }, []);
    const getSettingData = async () => {
        openLoadingScreen();
        const result = await getCollectionManagerSetting(authData.accessToken);
        const collectionPointSettingFormatted = result.data.collectionPointSetting;
        collectionPointSettingFormatted.forEach((obj) => {
            // eslint-disable-next-line no-param-reassign
            obj.timezone = getAllTimezonesMapping()[obj.timezone];
        });
        setSettingsForCollectionPoints(collectionPointSettingFormatted);
        setTimeFormat(result.data.organizationSetting.timeFormat);
        setDateFormat(result.data.organizationSetting.dateFormat);
        setTimezoneGlobalSetting(getAllTimezonesMapping()[result.data.organizationSetting.timezone]);
        setSelectedCountry(getAllCountryMapping()[result.data.organizationSetting.countryCode]);
        closeLoadingScreen();
    };
    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }
    const cmSettingItems = [
        {
            title: "Calendar",
            iconUrl: "/assets/svg/platform-settings/ic-calendar.svg",
            iconAlt: "ic-calendar",
            desc: "Working week, Lunch time, Date & Time format settings",
            buttonText: "Change Settings",
            navigateUrl: "/settings/calendar",
        },
        {
            title: "Geofence",
            iconUrl: "/assets/svg/platform-settings/ic-geofence.svg",
            iconAlt: "ic-geofence",
            desc: "Collector’s clock in /out Geo location boundary settings",
            buttonText: "Change Settings",
            navigateUrl: "/settings/geofence",
        },
        {
            title: "Calibration Reminder",
            iconUrl: "/assets/svg/platform-settings/ic-calibration-reminder.svg",
            iconAlt: "ic-calibration",
            desc: "Calibration Reminder Settings for the testing devices can be changed within Testing Devices.",
            buttonText: "Testing Devices",
            navigateUrl: "/collection-manager/testing-devices",
        },
        {
            title: "Communication",
            iconUrl: "/assets/svg/platform-settings/ic-communication.svg",
            iconAlt: "ic-communication",
            desc: "Communication Settings for Text, Email and In App Notifications",
            buttonText: "Change Settings",
            navigateUrl: "/settings/communication",
        },
        {
            title: "Label Settings",
            iconUrl: "/assets/svg/platform-settings/ic-communication.svg",
            iconAlt: "ic-communication",
            desc: "Select the terms that are most suitable for your organisation",
            buttonText: "Change Settings",
            navigateUrl: "/settings/label",
        },
    ];
    const handleRtoTimeChange = (event) => {
        setRtoTime(Number(event.target.value));
    };
    const handleUpdateTimezone = (collectionPointName, newTimezone) => {
        setSettingsForCollectionPoints((prevCollectionPoints) => prevCollectionPoints.map((point) => point.collectionPointName === collectionPointName
            ? {
                ...point,
                timezone: newTimezone,
            }
            : point));
    };
    const handleSaveChange = async () => {
        const body = {
            organizationSetting: {
                countryCode: getKeyByValue(countries, selectedCountry),
                timeFormat: timeFormat,
                dateFormat: dateFormat,
                timezone: getKeyByValue(timezones, timezoneGlobalSetting),
            },
            collectionPointSetting: settingsForCollectionPoints.map(({ id, collectionPointId, timezone }) => ({
                id,
                collectionPointId,
                timezone: getKeyByValue(timezones, timezone),
            })),
        };
        const result = await updateCollectionManagerSetting(authData.accessToken, body);
        if (result.status === "success") {
            // updateSettings(body);
            setReload(!reload);
            setIsCountryAndTimezoneEdited(!isCountryAndTimezoneEdited);
            openToast("Saved successfully");
        }
    };
    return (_jsx(PageLayoutWithNoTab, { location: location.pathname, heading: "Settings", children: _jsxs(ContentWrapper, { children: [_jsx(Card, { variant: "outlined", children: _jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                            gap: "10px",
                        }, children: [_jsx(Button, { variant: "contained", style: {
                                    textTransform: "none",
                                    alignSelf: "flex-end",
                                    color: "white",
                                    backgroundColor: isCountryAndTimezoneEdited ? "#1A8CFF" : "",
                                    borderRadius: "8px",
                                }, disabled: !isCountryAndTimezoneEdited, onClick: handleSaveChange, children: "Save Changes" }), _jsx(IconTitle, { title: "Country", icon: _jsx("img", { src: "/assets/svg/platform-settings/ic-country.svg", alt: "ic-country" }), isBold: true, hasDivider: true }), _jsxs("div", { style: {
                                    margin: "10px 45px",
                                    display: "flex",
                                    flexDirection: "column",
                                }, children: [_jsx(Typography, { children: "Calibration Reminder Settings for the testing devices can be changed within Testing Devices." }), _jsx(Autocomplete, { value: selectedCountry, renderInput: (params) => (_jsx(TextField, { ...params, InputProps: {
                                                ...params.InputProps,
                                                startAdornment: (_jsx(Flag, { countryCode: getKeyByValue(countries, selectedCountry) || "", style: { marginRight: "10px" } })),
                                            } })), options: ["", ...Object.values(countries)], onChange: (event, newInputValue) => {
                                            setIsCountryAndTimezoneEdited(true);
                                            setSelectedCountry(newInputValue);
                                        }, size: "small", style: { width: "35%", alignSelf: "flex-end" }, getOptionLabel: (option) => option, renderOption: (props, option) => (_jsxs("li", { ...props, children: [_jsx(Flag, { countryCode: getKeyByValue(countries, option) || "", style: { marginRight: "10px" } }), option] })) })] }), _jsx(IconTitle, { title: "Time zone", icon: _jsx("img", { src: "/assets/svg/platform-settings/ic-timezone.svg", alt: "ic-timezone" }), isBold: true, hasDivider: true }), _jsxs("div", { style: {
                                    margin: "10px 45px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "30px",
                                }, children: [_jsxs("div", { style: {
                                            display: "flex",
                                            gap: "15px",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }, children: [_jsx("img", { src: "/assets/svg/platform-settings/ic-country.svg", alt: "ic-country" }), _jsx("b", { children: "Global setting for the organisation" }), _jsx(Autocomplete, { fullWidth: true, size: "small", style: { maxWidth: "40%" }, value: timezoneGlobalSetting, onChange: (event, newValue) => {
                                                    settingsForCollectionPoints.map((cp) => {
                                                        // Add arrow function here
                                                        handleUpdateTimezone(cp.collectionPointName, newValue);
                                                    });
                                                    setIsCountryAndTimezoneEdited(true);
                                                    setTimezoneGlobalSetting(newValue);
                                                }, renderInput: (params) => _jsx(TextField, { ...params }), options: Object.values(timezones) })] }), _jsxs("div", { style: {
                                            display: "flex",
                                            gap: "30px",
                                            justifyContent: "space-between",
                                        }, children: [_jsx("b", { children: "Collection point(s):" }), _jsx("div", { style: {
                                                    backgroundColor: "#F5F6F8",
                                                    padding: "30px",
                                                    width: "80%",
                                                    borderRadius: "10px",
                                                    overflow: "auto",
                                                    maxHeight: "200px",
                                                    gap: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }, children: settingsForCollectionPoints.map((cp) => (_jsxs("div", { style: {
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }, children: [_jsxs("div", { style: {
                                                                display: "flex",
                                                                gap: "15px",
                                                                alignItems: "center",
                                                            }, children: [_jsx("img", { src: "/assets/svg/ic-location-dark.svg", alt: "ic-location" }), _jsx(Typography, { children: cp.collectionPointName })] }), _jsx(Autocomplete, { fullWidth: true, size: "small", style: { maxWidth: "60%" }, onChange: (event, newValue) => {
                                                                setIsCountryAndTimezoneEdited(true);
                                                                handleUpdateTimezone(cp.collectionPointName, newValue);
                                                            }, value: cp.timezone, renderInput: (params) => _jsx(TextField, { ...params }), options: Object.values(timezones) })] }, `${cp.collectionPointName}-timezone`))) })] }), _jsxs("div", { children: [_jsxs("div", { style: {
                                                    display: "flex",
                                                    gap: "30px",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }, children: [_jsx("b", { children: "Time display:" }), _jsx("div", { style: {
                                                            width: "80%",
                                                            gap: "10px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }, children: _jsx(FormControl, { children: _jsxs(RadioGroup, { value: timeFormat, onChange: (event) => {
                                                                    setTimeFormat(Number(event.target.value));
                                                                    setIsCountryAndTimezoneEdited(true);
                                                                }, row: true, children: [_jsx(FormControlLabel, { value: "12", control: _jsx(Radio, {}), label: "12-hour time" }), _jsx(FormControlLabel, { value: "24", control: _jsx(Radio, {}), label: "24-hour time" })] }) }) })] }), _jsx(Divider, {})] }), _jsxs("div", { children: [_jsxs("div", { style: {
                                                    display: "flex",
                                                    gap: "30px",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }, children: [_jsx("b", { children: "Date display:" }), _jsx("div", { style: {
                                                            width: "80%",
                                                            gap: "10px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }, children: _jsx(FormControl, { children: _jsxs(RadioGroup, { value: dateFormat, onChange: (event) => {
                                                                    setDateFormat(event.target.value);
                                                                    setIsCountryAndTimezoneEdited(true);
                                                                }, row: true, children: [_jsx(FormControlLabel, { value: "DD/MM/YYYY", control: _jsx(Radio, {}), label: "Day / Month / Year" }), _jsx(FormControlLabel, { value: "MM/DD/YYYY", control: _jsx(Radio, {}), label: "Month / Day / Year" }), _jsx(FormControlLabel, { value: "YYYY/MM/DD", control: _jsx(Radio, {}), label: "Year / Month / Day" })] }) }) })] }), _jsx(Divider, {})] })] })] }) }), cmSettingItems.map((item) => (_jsx(Card, { variant: "outlined", children: _jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                            gap: "10px",
                        }, children: [_jsx(IconTitle, { title: item.title, icon: _jsx("img", { src: item.iconUrl, alt: item.iconAlt }), isBold: true }), _jsxs("div", { style: {
                                    margin: "10px 45px",
                                    display: "flex",
                                    flexDirection: "column",
                                }, children: [_jsx(Typography, { children: item.desc }), item.buttonText !== "" && (_jsx(Button, { variant: "contained", onClick: () => navigate(item.navigateUrl), style: {
                                            textTransform: "none",
                                            alignSelf: "flex-end",
                                            color: "white",
                                            backgroundColor: "#1A8CFF",
                                            borderRadius: "8px",
                                        }, children: item.buttonText })), item.title === "RTO Student Time Allocation" && (_jsxs(_Fragment, { children: [_jsx(Typography, { children: "Please select the allocated time:" }), _jsxs("div", { style: {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignSelf: "end",
                                                    gap: "10px",
                                                }, children: [_jsxs(Select, { size: "small", labelId: "demo-simple-select-label", id: "demo-simple-select", value: rtoTime.toString(), onChange: handleRtoTimeChange, children: [_jsx(MenuItem, { value: 10, children: "10" }), _jsx(MenuItem, { value: 15, children: "15" }), _jsx(MenuItem, { value: 20, children: "20" }), _jsx(MenuItem, { value: 25, children: "25" }), _jsx(MenuItem, { value: 30, children: "30" })] }), _jsx(Typography, { children: "Mins" }), _jsx(Button, { variant: "contained", style: {
                                                            textTransform: "none",
                                                            alignSelf: "flex-end",
                                                            color: "white",
                                                            borderRadius: "8px",
                                                        }, children: "Save Changes" })] })] }))] })] }) }, item.title)))] }) }));
};
export default ManagerSetting;
const ContentWrapper = styled.div `
  background-color: #fafafa;
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
