import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button as MuiButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Button as AntButton, Col, Dropdown, Menu, Row, Typography } from "antd";
import arrayMove from "array-move";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { checkAuth } from "src/api/auth/login";
import { getCollectors, getTestType } from "src/api/dashboard/dashboardService";
import { getCollectionPoints } from "src/api/testingDevices/testingDeviceService";
import BarChartBlueIcon from "src/assets/svg/dashboard/ic-barchart-blue.svg";
import BarChartIcon from "src/assets/svg/dashboard/ic-barchart-white.svg";
import ColorSchemeIcon from "src/assets/svg/dashboard/ic-color-scheme.svg";
import FilterIcon from "src/assets/svg/dashboard/ic-filter.svg";
import MainLayout from "src/components/layouts/MainLayout";
import { SAColourScheme } from "src/constants";
import TestsProcessedChart from "src/pages/DashboardPage/TestProcessedChart";
import { BreathAlcoholTestResults } from "./components/BreathAlcoholTestResults";
import { CollectorPerformance } from "./components/CollectorPerformance";
import { CollectorRankings } from "./components/CollectorRankings";
import { ColorScheme } from "./components/ColorScheme";
import { Devices } from "./components/Devices";
import { DeviceByLocation } from "./components/Devices/DeviceByLocation";
import { GlobalView } from "./components/Devices/GlobalView";
import { InUseDevices } from "./components/Devices/InUseDevices";
import { DrugClassesInvalid } from "./components/DrugClassesInvalid";
import { DrugClassesNegative } from "./components/DrugClassesNegative";
import { DrugClassesNonNegative } from "./components/DrugClassesNonNegative";
import { HairDrugTestResults } from "./components/HairDrugTestResults";
import { OralFluidDrugTest } from "./components/OralFluidDrugTest";
import { Patient } from "./components/Patient";
import { PatientCommunication } from "./components/PatientCommunication";
import { PatientTime } from "./components/PatientTime";
import { RapidOralDrugScreenResults } from "./components/RapidOralDrugScreenResults";
import { RapidUrineDrugScreenResults } from "./components/RapidUrineDrugScreenResults";
import { DashboardSatchels } from "./components/Satchels";
import { TestCategories } from "./components/TestCategories";
import TestResultsChart from "./components/TestResultsChart";
import { TestsProcessed } from "./components/TestsProcessed";
import { TestTime } from "./components/TestTime";
import { UrineDrugTestResults } from "./components/UrineDrugTestResults";
import DailySummary from "./DailySummary";
import { DashboardPageWrapper } from "./index.style";
import ICSubmenuItem from "/public/assets/svg/inventory/ic-item-submenu.svg";
const { Text } = Typography;
const SortableItem = SortableElement(({ value }) => (_jsxs("div", { style: {
        display: "flex",
        cursor: "grab",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "3px 5px",
        border: "1px solid #ADB3BC",
        marginBottom: "10px",
        borderRadius: "5px",
        background: "white",
        zIndex: 10,
    }, children: [_jsx("div", { style: { display: "flex", alignItems: "center" }, children: _jsx("div", { style: { fontSize: "12px" }, children: value }) }), _jsx(ICSubmenuItem, {})] })));
const SortableList = SortableContainer(({ items }) => (_jsx("div", { style: { padding: "5px 0px", zIndex: 1 }, children: items.map((value, index) => (_jsx(SortableItem, { index: index, value: value }, `item-${index}`))) })));
const DropDown = ({ components, defaultOrder, onOrderChange, selectedCategory }) => {
    const [visible, setVisible] = useState(false);
    const [orderedComponents, setOrderedComponents] = useState(components);
    useEffect(() => {
        setOrderedComponents(components);
    }, [selectedCategory, components]);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setOrderedComponents(arrayMove(orderedComponents, oldIndex, newIndex));
    };
    const handleOk = () => {
        onOrderChange(orderedComponents);
        setVisible(false);
    };
    const handleDefault = () => {
        setOrderedComponents(defaultOrder);
    };
    const menu = (_jsxs(Menu, { style: { border: "8px solid black", padding: "15px" }, children: [_jsxs(_Fragment, { children: [_jsxs(Row, { style: {
                            border: "5px solid #EBEDF0",
                            marginBottom: "10px",
                        }, children: [_jsx(Col, { span: 7, style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    borderRight: "5px solid #EBEDF0",
                                    padding: "5px",
                                    background: "#F7F8F8",
                                }, children: _jsx("img", { src: "/assets/svg/ic-pie.svg", alt: "" }) }), _jsx(Col, { span: 17, style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                    background: "#F7F8F8",
                                }, children: _jsx("img", { src: "/assets/svg/ic-bar.svg", alt: "" }) })] }), _jsx("div", { style: { borderTop: "2px solid #EDEEF0", marginBottom: "10px" } }), _jsxs("div", { style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                        }, children: [_jsx(Text, { style: { fontSize: "10px", color: "#7F8E9D", marginBottom: "5px" }, children: "Show/Hide or Drag&Drop to Reorder" }), _jsx(AntButton, { onClick: handleDefault, style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#EBEDF0",
                                    color: "#2F3337",
                                    borderRadius: "13px",
                                    border: "none",
                                    padding: "5px",
                                    fontSize: "10px",
                                    width: "50px",
                                    height: "20px",
                                }, children: "Default" })] })] }), _jsx(SortableList, { items: orderedComponents.map((item) => item.name), onSortEnd: onSortEnd, helperClass: "dragged-item" }), _jsx("div", { style: { borderTop: "2px solid #EDEEF0", marginBottom: "10px" } }), _jsx(AntButton, { type: "primary", onClick: handleOk, style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#1A8CFF",
                    border: "none",
                    borderRadius: "5px",
                    width: "70%",
                    margin: "auto",
                    height: "25px",
                }, children: "OK" })] }));
    return (_jsx(Col, { children: _jsx(Dropdown, { overlay: menu, trigger: ["click"], visible: visible, onVisibleChange: setVisible, overlayStyle: { minWidth: "400px", zIndex: 2 }, children: _jsx(MuiButton, { endIcon: _jsx(FilterIcon, {}), variant: "outlined", children: "Show/Hide or Reorder" }) }) }));
};
export const DashboardPage = () => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [visibleColorScheme, setVisibleColorScheme] = useState(false);
    const [selectedColorScheme, setSelectedColorScheme] = useState("default");
    const colorSchemeRef = useRef(null);
    const handleSchemeChange = (event) => {
        setSelectedColorScheme(event.target.value);
        setVisibleColorScheme(false);
    };
    const [selectedCategory, setSelectedCategory] = useState("Collection");
    const selectedStyle = {
        color: "white",
        backgroundColor: SAColourScheme.NAVY,
    };
    const [collectionPoints, setCollectionPoints] = useState([]);
    const [testTypeData, setTestTypeData] = useState([]);
    const [collectorsData, setCollectorsData] = useState([]);
    useEffect(() => {
        getCP();
        getTestTypeNames();
        getCollectorNames();
    }, []);
    const collectionComponents = [
        {
            name: "Test Processed",
            component: _jsx(TestsProcessed, { collectionPoints: collectionPoints, testTypeData: testTypeData }),
        },
        {
            name: "Patient Time",
            component: (_jsx(PatientTime, { collectionPoints: collectionPoints, collectorsData: collectorsData })),
        },
        {
            name: "Test Time",
            component: _jsx(TestTime, { collectionPoints: collectionPoints, collectorsData: collectorsData }),
        },
        {
            name: "Test Categories",
            component: _jsx(TestCategories, { collectionPoints: collectionPoints }),
        },
        {
            name: "Rapid Urine Drug Screen Results",
            component: _jsx(RapidUrineDrugScreenResults, { collectionPoints: collectionPoints }),
        },
        {
            name: "Urine Drug Test Results",
            component: _jsx(UrineDrugTestResults, { collectionPoints: collectionPoints }),
        },
        {
            name: "Rapid Oral Fluid Drug Screen Results",
            component: _jsx(RapidOralDrugScreenResults, { collectionPoints: collectionPoints }),
        },
        {
            name: "Oral Fluid Drug Test Results",
            component: _jsx(OralFluidDrugTest, { collectionPoints: collectionPoints }),
        },
        {
            name: "Hair Drug Test",
            component: _jsx(HairDrugTestResults, { collectionPoints: collectionPoints }),
        },
        {
            name: "Breath Alcohol Test Results",
            component: _jsx(BreathAlcoholTestResults, { collectionPoints: collectionPoints }),
        },
        {
            name: "Drug Classes - Negative Results",
            component: (_jsx(DrugClassesNegative, { collectionPoints: collectionPoints, resultType: "Negative" })),
        },
        {
            name: "Drug Classes - Non Negative Results",
            component: (_jsx(DrugClassesNonNegative, { collectionPoints: collectionPoints, resultType: "Non Negative" })),
        },
        {
            name: "Drug Classes - Invalid Results",
            component: _jsx(DrugClassesInvalid, { collectionPoints: collectionPoints, resultType: "Invalid" }),
        },
        {
            name: "Collector Performance",
            component: (_jsx(CollectorPerformance, { collectionPoints: collectionPoints, collectorsData: collectorsData })),
        },
        {
            name: "Collector Rankings",
            component: (_jsx(CollectorRankings, { collectionPoints: collectionPoints, collectorsData: collectorsData })),
        },
        {
            name: "Patient",
            component: _jsx(Patient, { collectorsData: collectorsData }),
        },
        {
            name: "Patient Communication",
            component: _jsx(PatientCommunication, { collectionPoints: collectionPoints }),
        },
    ];
    const devicesComponents = [
        {
            name: "InUse Devices",
            component: _jsx(InUseDevices, {}),
        },
        {
            name: "Global View",
            component: _jsx(GlobalView, {}),
        },
        {
            name: "Devices by Location",
            component: _jsx(DeviceByLocation, {}),
        },
        // {
        //   name: "Forecast",
        //   component: <Forecast />,
        // },
    ];
    const defaultCollectionsOrder = collectionComponents.map((component) => ({
        ...component,
    }));
    const [orderedCollectionComponents, setOrderedCollectionComponents] = useState(collectionComponents);
    const defaultDevicesOrder = collectionComponents.map((component) => ({
        ...component,
    }));
    const [orderedDevicesComponents, setOrderedDevicesComponents] = useState(devicesComponents);
    const handleOrderChange = (newOrder) => {
        if (selectedCategory === "Collection") {
            setOrderedCollectionComponents(newOrder);
        }
        else if (selectedCategory === "Devices") {
            setOrderedDevicesComponents(newOrder);
        }
    };
    const getCP = async () => {
        try {
            const response = await getCollectionPoints(userInfo.accessToken);
            if (response.status === "success") {
                setCollectionPoints(response.data);
            }
            else {
                setCollectionPoints([]);
            }
        }
        catch (error) {
            throwHttpRequestError("Error in getCP:", error);
        }
    };
    const getTestTypeNames = async () => {
        const result = await getTestType(userInfo.accessToken);
        if (result.status === "success") {
            setTestTypeData(result.data);
        }
        else {
            setTestTypeData([]);
        }
    };
    const getCollectorNames = async () => {
        const result = await getCollectors(userInfo.accessToken);
        if (result.status === "success") {
            setCollectorsData(result.data);
        }
        else {
            setCollectorsData([]);
        }
    };
    return (_jsx(MainLayout, { children: _jsxs(DashboardPageWrapper, { children: [_jsxs("div", { className: "filter-header", children: [_jsxs("div", { className: "category-btn-group", children: [_jsx(MuiButton, { style: selectedCategory === "Collection" ? selectedStyle : {}, startIcon: selectedCategory === "Collection" ? _jsx(BarChartIcon, {}) : _jsx(BarChartBlueIcon, {}), variant: "outlined", onClick: () => setSelectedCategory("Collection"), children: "Collections" }), _jsx(MuiButton, { style: selectedCategory === "Devices" ? selectedStyle : {}, startIcon: selectedCategory === "Devices" ? _jsx(BarChartIcon, {}) : _jsx(BarChartBlueIcon, {}), variant: "outlined", onClick: () => setSelectedCategory("Devices"), children: "Devices" }), _jsx(MuiButton, { style: selectedCategory === "Satchels" ? selectedStyle : {}, startIcon: selectedCategory === "Satchels" ? _jsx(BarChartIcon, {}) : _jsx(BarChartBlueIcon, {}), variant: "outlined", onClick: () => setSelectedCategory("Satchels"), children: "Satchels" })] }), _jsxs("div", { className: "action-btn-group", children: [_jsx(MuiButton, { endIcon: _jsx(ColorSchemeIcon, {}), variant: "outlined", onClick: () => setVisibleColorScheme(!visibleColorScheme), children: "Colour Scheme" }), _jsx("div", { style: { position: "relative" }, children: visibleColorScheme && (_jsx("div", { ref: colorSchemeRef, children: _jsx(ColorScheme, { handleSchemeChange: handleSchemeChange, selectedColorScheme: selectedColorScheme }) })) }), selectedCategory !== "Satchels" && (_jsx(DropDown, { components: selectedCategory === "Collection"
                                        ? orderedCollectionComponents
                                        : orderedDevicesComponents, defaultOrder: selectedCategory === "Collection" ? defaultCollectionsOrder : defaultDevicesOrder, onOrderChange: handleOrderChange }))] })] }), _jsx(Divider, { color: SAColourScheme.PLASMA, sx: { borderBottomWidth: 2, marginTop: "10px" } }), selectedCategory === "Collection" ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "chart-section", children: [_jsx(DailySummary, { selectedColorScheme: selectedColorScheme }), _jsxs("div", { className: "test-charts-section", children: [_jsx(TestsProcessedChart, { selectedColorScheme: selectedColorScheme, collectionPoints: collectionPoints, testTypeData: testTypeData }), _jsx(TestResultsChart, { collectionPoints: collectionPoints, selectedColorScheme: selectedColorScheme })] })] }), _jsx(Divider, { color: SAColourScheme.PLASMA, sx: { borderBottomWidth: 2, marginTop: "30px" } }), orderedCollectionComponents.map((item, index) => React.cloneElement(item.component, {
                            key: index,
                            collectionPoints,
                            testTypeData,
                            collectorsData,
                        }))] })) : selectedCategory === "Devices" ? (_jsx(Devices, { orderedDevicesComponents: orderedDevicesComponents })) : selectedCategory === "Satchels" ? (_jsx(DashboardSatchels, {})) : null] }) }));
};
