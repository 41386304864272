import { Domain } from "src/types";
import { $post, throwHttpRequestError } from "../http";
import { UploadPrefix } from "./types";
const uploadFile = async (file, prefix = UploadPrefix.Image) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await $post(`cm/${prefix}`, formData, Domain.Upload, {
            transformRequest: [(data) => data],
        });
        return response;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export { uploadFile };
