import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { render } from "react-dom";
import { env } from "src/constants";
const MapView = ({ collectionPointsSetting, selectedCollectionPoint, onUpdateRadius, onSaveChanges, zoom = 14, }) => {
    const API_GG_MAP_KEY = env.API_GG_MAP_KEY || "";
    let circle;
    let centerMarker;
    const lat = collectionPointsSetting.find((item) => item.collectionPointId === selectedCollectionPoint.collectionPointId).latitude;
    const lng = collectionPointsSetting.find((item) => item.collectionPointId === selectedCollectionPoint.collectionPointId).longitude;
    const point = {
        lat: lat,
        lng: lng,
    };
    const mapRef = useRef(null);
    useEffect(() => {
        if (!window.google) {
            // Load Google Maps API script
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_GG_MAP_KEY}&libraries=geometry`;
            script.async = true;
            document.body.appendChild(script);
            script.onload = initializeMap;
        }
        else {
            initializeMap();
        }
    }, [selectedCollectionPoint, collectionPointsSetting]);
    const initializeMap = () => {
        if (!mapRef.current)
            return;
        const { latitude, longitude, geofencingMeter } = collectionPointsSetting.find((item) => item.collectionPointId === selectedCollectionPoint.collectionPointId) || {};
        const latLng = { lat: latitude, lng: longitude };
        const radius = Number(geofencingMeter);
        const map = new window.google.maps.Map(mapRef.current, {
            center: latLng,
            zoom: zoom,
        });
        centerMarker = new window.google.maps.Marker({
            position: point,
            map: map,
        });
        circle = new window.google.maps.Circle({
            map: map,
            center: point,
            radius: radius,
            fillColor: "#FFFFFF",
            fillOpacity: 0.7,
            strokeColor: "#35B700",
            strokeOpacity: 0.8,
            strokeWeight: 2,
        });
        const customMarkerIcon = {
            url: "/assets/svg/ic-location-pin-blue.svg",
            scaledSize: new google.maps.Size(36, 48),
        };
        centerMarker?.setIcon(customMarkerIcon);
        const mapText = document.createElement("div");
        render(_jsxs("div", { style: {
                position: "absolute",
                right: "60px",
                bottom: "20px",
                display: "flex",
                gap: "20px",
            }, children: [_jsxs("div", { style: {
                        display: "flex",
                        backgroundColor: "white",
                        gap: "20px",
                        border: "#1A8CFF 3px solid",
                        borderRadius: "5px",
                        padding: "3px 15px",
                        alignItems: "center",
                    }, children: [_jsx(Typography, { variant: "caption", children: "Geo Coordinates" }), _jsxs(Typography, { children: [lat, ", ", lng] })] }), _jsxs("div", { style: {
                        display: "flex",
                        backgroundColor: "white",
                        gap: "20px",
                        border: "#1A8CFF 3px solid",
                        borderRadius: "5px",
                        padding: "3px 15px",
                        alignItems: "center",
                    }, children: [_jsx(Typography, { variant: "caption", children: "Radius (approx.)" }), _jsx("input", { style: { width: "40px", border: "none", outline: "none" }, onChange: (event) => onUpdateRadius(selectedCollectionPoint.collectionPointId, event.target.value), value: radius, type: "text" }), _jsx(Typography, { variant: "caption", children: "meters" })] }), _jsx(Button, { variant: "contained", style: {
                        textTransform: "none",
                        color: "white",
                        backgroundColor: "#1A8CFF",
                        borderRadius: "5px",
                        width: "150px",
                    }, onClick: onSaveChanges, children: "Save changes" })] }), mapText);
        document.getElementById("map").appendChild(mapText);
        const handleClick = (event) => {
            const clickedLocation = event.latLng;
            const distance = Math.round(google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(point), clickedLocation) * 100) / 100;
            circle?.setRadius(distance);
            onUpdateRadius(selectedCollectionPoint.collectionPointId, distance.toString());
        };
        map.addListener("click", handleClick);
        circle.addListener("click", handleClick);
    };
    return _jsx("div", { ref: mapRef, id: "map", style: { width: "100%", height: "400px" } });
};
export default MapView;
