import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getPatientRecords = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/patient-records/get-records`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
