import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { faqDataListCA, faqDataListCM, faqDataListPA, faqDataListRTOMedical, getFAQList, getQuestionList, } from "src/api/faqs/faqsService";
import MainLayout from "src/components/layouts/MainLayout";
import { ContactUs } from "./ContactUs";
import { FAQs } from "./FAQs";
import { MainPage } from "./MainPage";
import { PageWrapper } from "./index.style";
export const SupportPage = () => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [isMainPage, setIsMainPage] = useState(true);
    const [isFAQsPage, setIsFAQsPage] = useState(false);
    const [isContactPage, setIsContactPage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const getFAQsData = async () => {
        // Get faqList for patient
        const faqTypeList = await getFAQList(userInfo.accessToken);
        if (faqTypeList.data && faqTypeList.data.length) {
            const groupList = groupBy(faqTypeList.data, "app");
            const questionList = await getQuestionList(userInfo.accessToken);
            const listCM = getMerge(questionList.data, groupList["collectionManager"]);
            faqDataListCM.actionCreator(listCM);
            const listPA = getMerge(questionList.data, groupList["patient"]);
            faqDataListPA.actionCreator(listPA);
            const listCA = getMerge(questionList.data, groupList["collect"]);
            faqDataListCA.actionCreator(listCA);
            const listRTOMedical = getMerge(questionList.data, groupList["RTOMedical"]);
            faqDataListRTOMedical.actionCreator(listRTOMedical);
        }
    };
    const getMerge = (questionList, faqTypeApp) => faqTypeApp.map((faq) => {
        const questionData = questionList.filter((question) => question.faqTypeId == faq.id);
        const faqList = questionData.map((data) => ({
            title: data?.question,
            htmlContent: data?.answer?.htmlContent,
            index: data?.index,
        }));
        return {
            category: faq.name,
            faqList: faqList,
            index: faq.index,
            type: faq.type,
        };
    });
    useEffect(() => {
        getFAQsData();
    }, []);
    const onSearchText = (text) => {
        setSearchText(text);
    };
    const onClickItem = (item) => {
        if (item == "faq") {
            setIsMainPage(false);
            setIsFAQsPage(true);
        }
        else if (item == "contact") {
            setIsMainPage(false);
            setIsContactPage(true);
        }
    };
    const onBackMainPage = () => {
        setIsMainPage(true);
        setIsFAQsPage(false);
        setIsContactPage(false);
    };
    const onEnterSearchText = () => {
        setIsMainPage(false);
        setIsFAQsPage(true);
    };
    return (_jsx(MainLayout, { children: _jsxs(PageWrapper, { children: [isMainPage && (_jsx(MainPage, { onClickItem: onClickItem, onSearchText: onSearchText, onEnterSearchText: onEnterSearchText })), isFAQsPage && _jsx(FAQs, { onBack: onBackMainPage, searchText: searchText }), isContactPage && _jsx(ContactUs, { onBack: onBackMainPage }), _jsx("div", { style: { marginBottom: 50 } })] }) }));
};
