import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, where, } from "firebase/firestore";
import { env } from "src/constants";
import { app } from "src/firebase";
const db = getFirestore(app);
const appointmentDatabase = `${env.DATA_COLLECTION}/appointment`;
const appointmentTypeDatabase = `${env.DATA_COLLECTION}/testType`;
const requestTestTypeDatabase = `${env.DATA_COLLECTION}/testCategory`;
export const getRequestTestParents = async () => {
    const querySnapshot = await getDocs(collection(db, appointmentTypeDatabase));
    return querySnapshot.docs.map((snapDoc) => snapDoc.data()).sort((a, b) => a.index - b.index); // Sort by index ASC
};
export const getRequestTestCategories = async () => {
    const queryCondition = query(collection(db, requestTestTypeDatabase), orderBy("index", "asc"));
    const querySnapshot = await getDocs(queryCondition);
    return querySnapshot.docs.map((snapDoc) => snapDoc.data());
};
export const addRequestTestType = async (testName) => {
    const queryCondition = query(collection(db, requestTestTypeDatabase), orderBy("index", "desc"), limit(1));
    const querySnapshot = await getDocs(queryCondition);
    let newIndex = 0;
    querySnapshot.forEach((snapDoc) => {
        newIndex = snapDoc.data().index + 1;
    });
    const { id } = doc(collection(db, requestTestTypeDatabase));
    const requestTestTypeRef = doc(db, requestTestTypeDatabase, id);
    const createdData = {
        uuid: id,
        testName: testName,
        index: newIndex,
        isCustom: true,
    };
    await setDoc(requestTestTypeRef, createdData);
    return createdData;
};
export const getAppointments = async (collectionOrganizationId, fromDate, toDate) => {
    const collectionRef = collection(db, appointmentDatabase);
    let queryCondition = query(collectionRef);
    if (collectionOrganizationId) {
        queryCondition = query(queryCondition, where("collectionOrganizationId", "==", collectionOrganizationId));
    }
    if (fromDate && toDate) {
        queryCondition = query(queryCondition, where("date", ">=", fromDate), where("date", "<=", toDate), orderBy("date", "desc"));
    }
    const querySnapshot = await getDocs(queryCondition);
    return querySnapshot.docs.map((snapDoc) => snapDoc.data());
};
export const getAppointmentByUid = async (uid) => {
    let appointment = {};
    const docRef = doc(db, `${env.DATA_COLLECTION}/appointments`, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        appointment = docSnap.data();
    }
    return appointment;
};
