import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, DatePicker, Dropdown, Form, Image, Input, InputNumber, Menu, Modal, Popover, Row, Space, Switch, TimePicker, } from "antd";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Barcode from "react-barcode";
import { ChromePicker } from "react-color";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { createTransportSatchel, getCollectorsData, restoreTransportSatchel, updateTransportSatchel, } from "src/api/inventory/inventoryService";
import { InputWithLabel } from "src/components/base";
import { MESSAGE_BOX_TYPE, SAColourScheme, env, mainAppColours } from "src/constants";
import { FileUpload } from "src/pages/TestingDevicePage/components/NewDevice/Forms/FileUpload";
import ContentContext from "src/providers/content";
import { Help } from "./Help";
import { NewSatchelWrapper } from "./index.style";
import { GenerateQRCode } from "./QRCode";
import { TagGroup } from "./TagGroup";
const db = getFirestore();
export const SatchelForm = ({ record }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const userInfo = useSelector(checkAuth.dataSelector);
    const [initialData, setInitialData] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [activeSwitch, setActiveSwitch] = useState("");
    const [componentSize, setComponentSize] = useState("default");
    const [connectedRecordId, setConnectedRecordId] = useState(null);
    const [receivedBarcode, setReceivedBarcode] = useState("");
    const [showGenerateQRCode, setShowGenerateQRCode] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [newRecord, setNewRecord] = useState(false);
    const [clickedFieldName, setClickedFieldName] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState("#F4DB24");
    const getBarcodeFormat = (barcode) => {
        if (typeof barcode === "number") {
            return "CODE128";
        }
        else if (typeof barcode === "string") {
            const barcodeLength = barcode.length;
            // Check for each possible format
            if (barcodeLength === 12 && !/^(\d{12})$/.test(barcode)) {
                return "EAN13";
            }
            else if (barcodeLength === 8) {
                return "UPC";
            }
            else if (barcodeLength === 20) {
                return "CODE128";
            }
            else if (barcodeLength === 10) {
                return "CODE39";
            }
            else if (barcodeLength === 14 && /^(\d{14})$/.test(barcode)) {
                return "ITF14";
            }
            else if (barcodeLength === 12 && /^(\d{12})$/.test(barcode)) {
                return "MSI";
            }
            else if (barcodeLength > 0 && /^[0-9]+$/.test(barcode)) {
                return "CODE39";
                // eslint-disable-next-line no-useless-escape
            }
            else if (barcodeLength > 0 && /^[A-D][0-9\*\$\/\+\.\-:]+[A-D]$/.test(barcode)) {
                return "codabar";
            }
            // Default to CODE128 if the length doesn't match any specific format
            return "CODE128";
        }
        else {
            // Default to CODE128 if the type is not recognized
            return "CODE128";
        }
    };
    const handleOpenGenerateQRCode = () => {
        setShowGenerateQRCode(true);
    };
    const handleShowHelp = () => {
        setShowHelp(true);
    };
    const closeHelp = () => {
        setShowHelp(false);
    };
    const getCollectors = async () => {
        const result = await getCollectorsData(userInfo.accessToken);
        if (result.status === "success") {
            setCollectors(result.data);
        }
        else {
            setCollectors([]);
        }
    };
    const handleUserConnected = (connectedUserId) => {
        setConnectedRecordId(connectedUserId);
        setShowGenerateQRCode(false);
    };
    useEffect(() => {
        if (connectedRecordId) {
            const unsub = onSnapshot(doc(db, `${env.DATA_COLLECTION}/inventoryQrData`, `${connectedRecordId}`), (curDoc) => {
                try {
                    const data = curDoc.data();
                    if (data) {
                        const scannedBarcode = data.scannedBarcode || "";
                        form.setFieldsValue({ barcodeNumber: scannedBarcode });
                        setReceivedBarcode(scannedBarcode);
                    }
                }
                catch (error) {
                    console.error("Error processing snapshot:", error);
                    console.error("Full error details:", error);
                }
            });
            return () => unsub();
        }
    }, [connectedRecordId]);
    const handleTagsChange = (tags) => {
        form.setFieldsValue({ tags });
    };
    const handleChangeComplete = (color) => {
        setSelectedColor(color.hex);
        form.setFieldsValue({ bagColor: color.hex });
    };
    const handleViewClick = (fieldName) => {
        setClickedFieldName(fieldName);
        setPreviewVisible(true);
    };
    const handleCancel = () => {
        setPreviewVisible(false);
        setClickedFieldName(null);
    };
    const restoreSatchel = async (id) => {
        openLoadingScreen();
        const response = await restoreTransportSatchel(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Restored successfully");
            setFormDisabled(false);
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const showDrawer = () => {
        setDrawerVisible(true);
    };
    const onCloseDrawer = () => {
        setDrawerVisible(false);
    };
    const handleClick = (fieldName) => {
        setClickedFieldName(fieldName);
    };
    const getMenu = () => (_jsxs(Menu, { style: { padding: "10px 10px" }, children: [_jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, disabled: !!(clickedFieldName && form.getFieldValue(clickedFieldName)), onClick: () => {
                    showDrawer();
                }, children: "Upload File" }, "upload"), _jsx(FileUpload, { onCloseDrawer: onCloseDrawer, onFileUploaded: (url) => {
                    if (clickedFieldName) {
                        form.setFieldsValue({ [clickedFieldName]: url });
                    }
                }, visible: drawerVisible }), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => clickedFieldName && handleViewClick(clickedFieldName), children: "View File" }, "view"), _jsx(Modal, { visible: previewVisible, onCancel: handleCancel, footer: null, width: 400, children: clickedFieldName && (_jsx("iframe", { title: "File Preview", src: form.getFieldValue(clickedFieldName), style: { width: "100%", height: "100%", padding: "10px" } })) }), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => {
                    onDelete();
                }, children: "Delete File" }, "delete")] }));
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };
    const handleSwitchChange = (fieldName) => {
        const switchesToSetFalse = ["outOfService", "inStock", "inService"];
        switchesToSetFalse.forEach((field) => {
            if (field !== fieldName) {
                setActiveSwitch(fieldName);
                form.setFieldsValue({ [field]: false });
            }
        });
    };
    const onDelete = async () => {
        openLoadingScreen();
        if (clickedFieldName) {
            const fieldValue = form.getFieldValue(clickedFieldName);
            if (fieldValue && typeof fieldValue === "string" && fieldValue.startsWith("http")) {
                // await deleteImage(fieldValue);
                form.setFieldsValue({ [clickedFieldName]: "" });
            }
        }
        closeLoadingScreen();
    };
    const saveTD = async (finish = false) => {
        form.validateFields().then(async (values) => {
            // Remove the dollar sign and convert to a numeric value
            if (newRecord) {
                values = {
                    ...values,
                    qrId: connectedRecordId || "",
                    isArchived: false,
                };
            }
            else {
                values = {
                    ...values,
                    qrId: initialData.qrId || "",
                };
            }
            const isFormDirty = Object.keys(values).some((field) => {
                // If the field is an array, perform a granular comparison
                if (Array.isArray(values[field]) && Array.isArray(initialData[field])) {
                    return !arraysEqual(values[field], initialData[field]);
                }
                else {
                    return values[field] !== initialData[field];
                }
            });
            if (isFormDirty) {
                const newData = {
                    ...values,
                    id: record?.id || undefined,
                };
                if (newData.id === undefined) {
                    openLoadingScreen();
                    const response = await createTransportSatchel(userInfo.accessToken, newData);
                    if (response.status === "success") {
                        openToast("Created successfully");
                        if (finish) {
                            navigate(-1);
                        }
                        else {
                            form.setFieldsValue({ barcodeNumber: "" });
                            setReceivedBarcode("");
                            closeLoadingScreen();
                        }
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        closeLoadingScreen();
                    }
                }
                else {
                    // newData;
                    openLoadingScreen();
                    const response = await updateTransportSatchel(userInfo.accessToken, newData.id, newData);
                    if (response.status === "success") {
                        openToast("Updated successfully");
                        closeLoadingScreen();
                        navigate(-1);
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        closeLoadingScreen();
                    }
                }
            }
            else {
                navigate(-1);
            }
        });
    };
    function arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (!objectsEqual(arr1[i], arr2[i])) {
                return false;
            }
        }
        return true;
    }
    function objectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }
    useEffect(() => {
        getCollectors();
        if (record !== null && typeof record !== "string") {
            ("existing record");
            setNewRecord(false);
            const mappedRecord = {
                barcodeNumber: record.barcodeNumber,
                commissionedBy: record.commissionedBy,
                id: record.id,
                commisionTime: record.commisionTime ? moment(record.commisionTime) : "",
                commissionDate: record.commissionDate ? moment(record.commissionDate) : "",
                unitCost: record.unitCost,
                supplier: record.supplier,
                securityIssues: record.securityIssues,
                daysInService: record.commissionDate
                    ? moment().diff(moment(record.commissionDate), "days")
                    : 0,
                imgUrl: record.imgUrl,
                tags: record.tags,
                region: record.region,
                collector: record.collector,
                bagColor: record.bagColor,
                outOfService: record.outOfService,
                inService: record.inService,
                inStock: record.inStock,
                isArchived: record.isArchived,
                qrId: record.qrId,
            };
            form.setFieldsValue(mappedRecord);
            setInitialData(mappedRecord);
            setReceivedBarcode(mappedRecord.barcodeNumber);
            const actSwitch = record.outOfService === true
                ? "outOfService"
                : record.inStock === true
                    ? "inStock"
                    : record.inService === true
                        ? "inService"
                        : "";
            setActiveSwitch(actSwitch);
            handleSwitchChange(actSwitch);
            setFormDisabled(mappedRecord.isArchived);
        }
        else {
            setNewRecord(true);
            const actSwitch = "inStock";
            setActiveSwitch(actSwitch);
            form.setFieldsValue({ inStock: true });
            form.setFieldsValue({ bagColor: "F4DB24" });
        }
    }, [record, form]);
    return (_jsxs("div", { style: { background: "white", padding: "2rem" }, children: [!showGenerateQRCode && (_jsx(NewSatchelWrapper, { children: _jsxs(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 54 }, initialValues: { size: componentSize }, onValuesChange: onFormLayoutChange, size: componentSize, children: [_jsxs(Row, { style: { rowGap: "10px" }, children: [_jsxs(Col, { xs: 24, xl: 8, style: { display: "flex" }, children: [_jsx(Image, { src: "/assets/svg/inventory/ic-lock.svg", preview: false }), _jsx("span", { style: {
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                marginLeft: "30px",
                                                alignSelf: "center",
                                            }, children: "Transport Satchel Details" })] }), _jsx(Col, { xs: 24, xl: 16, children: _jsxs(Row, { gutter: [20, 10], justify: "end", children: [_jsxs(Col, { xs: 24, md: 10, style: { display: "flex", justifyContent: "flex-end" }, children: [_jsx("div", { style: {
                                                            background: "#61B1F8",
                                                            height: "35px",
                                                            minWidth: "155px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }, children: _jsx("span", { style: {
                                                                color: `${SAColourScheme.WHITE}`,
                                                                fontSize: "14px",
                                                            }, children: "Security Issues" }) }), _jsx("div", { className: "triangle-right" }), _jsx(Form.Item, { name: "securityIssues", children: _jsx(InputNumber, { style: {
                                                                width: "70px",
                                                                border: "1px solid #01B4D2",
                                                                height: "35px",
                                                            }, min: 0, max: 999, disabled: newRecord || formDisabled ? true : false }) })] }), _jsxs(Col, { xs: 24, md: 10, style: { display: "flex", justifyContent: "flex-end" }, children: [_jsx("div", { style: {
                                                            background: "#61B1F8",
                                                            height: "35px",
                                                            minWidth: "155px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }, children: _jsx("span", { style: {
                                                                color: `${SAColourScheme.WHITE}`,
                                                                fontSize: "14px",
                                                            }, children: "Days in Service" }) }), _jsx("div", { className: "triangle-right" }), _jsx(Form.Item, { name: "daysInService", children: _jsx(InputNumber, { style: {
                                                                width: "70px",
                                                                border: "1px solid #01B4D2",
                                                                height: "35px",
                                                            }, disabled: true }) })] }), _jsxs(Col, { xs: 24, md: 4, style: {
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }, onClick: handleShowHelp, children: [_jsx(Image, { src: "/assets/svg/inventory/ic-help.svg", preview: false, height: 20, width: 20 }), _jsx("span", { style: {
                                                            fontSize: "14px",
                                                            marginLeft: "6px",
                                                            color: "#00000080",
                                                        }, children: "Help" })] })] }) })] }), _jsxs(Row, { gutter: [25, 10], children: [_jsxs(Col, { xs: 24, xl: 8, style: { marginTop: "10px" }, children: [_jsx("div", { style: {
                                                border: "1.5px solid #E1E2E8",
                                                height: "80px",
                                            }, children: receivedBarcode !== "" && (_jsx("div", { style: { display: "flex", justifyContent: "center" }, children: _jsx(Barcode, { value: receivedBarcode, height: 45, fontSize: 12, width: 3, margin: 6, format: getBarcodeFormat(receivedBarcode) }) })) }), _jsx(Form.Item, { label: "Barcode Number", name: "barcodeNumber", rules: [{ required: true, message: "Please scan the Barcode" }], children: _jsx(Input, { disabled: formDisabled, onChange: (e) => setReceivedBarcode(e.target.value) }) }), _jsx(Form.Item, { label: "Commissioned by", name: "commissionedBy", children: _jsx(Input, { disabled: formDisabled }) }), _jsx(Form.Item, { label: "Commission Time", name: "commisionTime", children: _jsx(TimePicker, { style: { width: "100%" }, format: "h:mm a", showNow: false, placeholder: "", minuteStep: 5, disabled: formDisabled }) }), _jsx(Form.Item, { label: "Commission Date", name: "commissionDate", children: _jsx(DatePicker, { style: { width: "100%" }, format: "DD MMM YYYY", disabled: formDisabled }) }), _jsx(Form.Item, { label: "Unit Cost", name: "unitCost", children: _jsx(InputNumber, { step: 0.1, style: { width: "100%" }, disabled: formDisabled, formatter: (value) => `$ ${value ?? ""}`, parser: (value) => (value ? value.replace(/\$\s?|(,*)/g, "") : "") }) }), _jsx(Form.Item, { label: "Supplier", name: "supplier", children: _jsx(Input, { disabled: formDisabled }) })] }), _jsxs(Col, { xs: 24, xl: 16, style: { marginTop: "10px" }, children: [_jsxs("div", { style: {
                                                border: "1px solid #D9D9D9",
                                                padding: "18px",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }, children: [_jsx("span", { style: {
                                                        fontSize: "17px",
                                                        color: "#1A576F",
                                                    }, children: "Please scan the security satchel barcode with your mobile device" }), _jsxs(Button, { onClick: handleOpenGenerateQRCode, style: {
                                                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                                                        borderRadius: "4px",
                                                    }, disabled: formDisabled, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#1A8CFF" }, children: "Scan" })] })] }), _jsxs(Row, { gutter: [10, 10], style: { marginTop: "10px" }, children: [_jsx(Col, { xs: 24, md: 8, children: _jsx(Form.Item, { label: "Picture of Satchel", name: "imgUrl", style: { alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                                            }, children: _jsx("div", { onClick: () => handleClick("imgUrl"), style: { width: "30%", border: "1px" }, children: _jsx(Image, { src: form.getFieldValue("imgUrl") ||
                                                                        "/assets/svg/inventory/ic-blank-satchel-img.svg", alt: "No File", preview: false, height: 260, width: 200, style: {
                                                                        cursor: "pointer",
                                                                        background: "white",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding: "8px 15px",
                                                                    } }) }) }) }) }), _jsxs(Col, { xs: 24, md: 16, children: [_jsx(Form.Item, { name: "tags", children: _jsx(TagGroup, { onTagsChange: handleTagsChange, initialTags: record !== null ? record.tags : [], formDisabled: formDisabled }) }), _jsx(Form.Item, { label: "Region", name: "region", children: _jsx(Input, { disabled: formDisabled }) }), _jsx(Form.Item, { label: "Assign Collector", name: "collector", children: _jsx(InputWithLabel, { theme: "3", field: "collector", inputProps: {
                                                                    type: "select",
                                                                    disabled: formDisabled,
                                                                    placeholder: "Choose collector from drop down",
                                                                    options: collectors.map((collector) => ({
                                                                        label: `${collector.firstName} ${collector.lastName}`,
                                                                        value: collector.id,
                                                                    })),
                                                                } }) })] })] }), _jsxs(Row, { gutter: [10, 10], children: [_jsx(Col, { xs: 24, md: 8, children: _jsx(Form.Item, { label: "Bag Color", name: "bagColor", style: { alignSelf: "center" }, children: _jsx(Popover, { content: _jsx(ChromePicker, { color: selectedColor, onChangeComplete: handleChangeComplete }), placement: "right", trigger: "click", children: _jsx(Space, { direction: "horizontal", size: 8, children: _jsx("div", { style: {
                                                                        width: "200px",
                                                                        height: "30px",
                                                                        backgroundColor: selectedColor,
                                                                    } }) }) }) }) }), _jsxs(Col, { xs: 24, md: 16, style: {
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                    }, children: [_jsx(Form.Item, { label: "Out of Service", name: "outOfService", valuePropName: "checked", style: { display: "flex", alignItems: "center" }, children: _jsx(Switch, { style: {
                                                                    backgroundColor: activeSwitch === "outOfService" ? "#1A8CFF" : "#C5C7D1",
                                                                }, disabled: formDisabled, onChange: () => {
                                                                    handleSwitchChange("outOfService");
                                                                }, checked: activeSwitch === "outOfService" }) }), _jsx(Form.Item, { label: "In Stock", name: "inStock", valuePropName: "checked", style: { display: "flex", alignItems: "center" }, children: _jsx(Switch, { style: {
                                                                    backgroundColor: activeSwitch === "inStock" ? "#1A8CFF" : "#C5C7D1",
                                                                }, disabled: formDisabled, onChange: () => {
                                                                    handleSwitchChange("inStock");
                                                                }, checked: activeSwitch === "inStock" }) }), _jsx(Form.Item, { label: "In Service", name: "inService", valuePropName: "checked", style: { display: "flex", alignItems: "center" }, children: _jsx(Switch, { style: {
                                                                    backgroundColor: activeSwitch === "inService" ? "#1A8CFF" : "#C5C7D1",
                                                                }, disabled: formDisabled, onChange: () => {
                                                                    handleSwitchChange("inService");
                                                                }, checked: activeSwitch === "inService" }) })] })] }), _jsx(Row, { gutter: [20, 10], justify: "end", style: { marginTop: "20px" }, children: formDisabled ? (_jsx(Col, { xs: 24, md: 6, style: { display: "flex", justifyContent: "flex-end" }, children: _jsx(Button, { style: {
                                                        background: "#1A8CFF",
                                                        color: SAColourScheme.WHITE,
                                                        border: "1px solid white",
                                                        borderRadius: "5px",
                                                        marginLeft: "10px",
                                                        width: "100%",
                                                    }, onClick: () => {
                                                        restoreSatchel(initialData.id);
                                                    }, children: "Restore" }) })) : (_jsxs(_Fragment, { children: [_jsx(Col, { xs: 24, md: 6, style: {
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }, children: _jsx(Button, { style: {
                                                                background: SAColourScheme.WHITE,
                                                                color: "#FD0000",
                                                                border: "1px solid #ADB3BC",
                                                                borderRadius: "5px",
                                                                width: "100%",
                                                            }, onClick: () => navigate(-1), children: _jsx("span", { children: "Cancel" }) }) }), newRecord ? (_jsxs(_Fragment, { children: [_jsx(Col, { xs: 24, md: 6, style: {
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                }, children: _jsx(Button, { style: {
                                                                        background: "#1A8CFF",
                                                                        color: SAColourScheme.WHITE,
                                                                        border: "1px solid #1A8CFF",
                                                                        borderRadius: "5px",
                                                                        width: "100%",
                                                                    }, onClick: () => {
                                                                        form.validateFields().then(() => {
                                                                            saveTD();
                                                                        });
                                                                    }, children: "Save and Next" }) }), _jsx(Col, { xs: 24, md: 6, style: {
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                }, children: _jsx(Button, { style: {
                                                                        background: "#1A8CFF",
                                                                        color: SAColourScheme.WHITE,
                                                                        border: "1px solid #1A8CFF",
                                                                        borderRadius: "5px",
                                                                        width: "100%",
                                                                    }, onClick: () => {
                                                                        form.validateFields().then(() => {
                                                                            saveTD(true);
                                                                        });
                                                                    }, children: "Save and Finish" }) })] })) : (_jsx(Col, { xs: 24, md: 6, style: {
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }, children: _jsx(Button, { style: {
                                                                background: "#1A8CFF",
                                                                color: SAColourScheme.WHITE,
                                                                border: "1px solid #1A8CFF",
                                                                borderRadius: "5px",
                                                                width: "100%",
                                                            }, onClick: () => {
                                                                form.validateFields().then(() => {
                                                                    saveTD(true);
                                                                });
                                                            }, children: "Update" }) }))] })) })] })] })] }) })), showGenerateQRCode && _jsx(GenerateQRCode, { onUserConnected: handleUserConnected }), showHelp && _jsx(Help, { onClose: closeHelp })] }));
};
