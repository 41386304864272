/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getInventoryDetails = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getSupplierContactDetails = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/suppliers`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getArchivedSupplier = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/archived-suppliers`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const deleteSupplier = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/supplier/delete/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const archiveSupplier = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/supplier/archive/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const restoreSupplier = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/supplier/restore/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateInventory = async (idToken, id, newData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/inventory/update/${id}`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createInventory = async (idToken, newData) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/inventory/create/`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateSupplier = async (idToken, newData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/inventory/supplier/update/`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createQREntry = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/qr/create/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const verifyQrPin = async (pin, encodedUrl) => {
    try {
        const response = await axios.put(`${API_URL}/ScanSatchelCodes/verify/${encodedUrl}`, { pin });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const setBarcodeData = async (id, scannedBarcode) => {
    try {
        const response = await axios.put(`${API_URL}/ScanSatchelCodes/update/${id}`, {
            scannedBarcode,
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createTransportSatchel = async (idToken, newData) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/inventory/satchel/create`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateTransportSatchel = async (idToken, id, newData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/inventory/satchel/update/${id}`, newData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTransportSatchels = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/satchels/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getArchivedTransportSatchels = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/satchels/archived`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorsData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/satchels/collectors/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const archiveTransportSatchel = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/satchel/archive/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const restoreTransportSatchel = async (idToken, id) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/inventory/satchel/restore/${id}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
