import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import ComingSoonPage from "src/pages/PageErrors/ComingSoonPage";
import CollectorsPage from "src/pages/PlatformManagementMenuPage/CollectAssistPage/CollectorsPage";
import CollectorInformationPageRoutes from "./CollectorInformation";
import ClockInClockOutPage from "./ClockOnClockOffPage";
import AppConfiguration from "./AppConfiguration";
import WorkRecord from "./ClockOnClockOffPage/WorkRecord";
const CollectorAssistPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routes = [
        {
            path: "/platform-management",
            name: "Platform Management",
            level: 1,
        },
        {
            path: "/collect-assist",
            name: "Collector Assist",
            level: 2,
        },
        {
            path: "/collectors",
            name: "Collectors",
            level: 3,
        },
        {
            path: "/credentials",
            name: "Collector Credentials",
            level: 3,
        },
        {
            path: "/calendar",
            name: "Collector's Calendar",
            level: 3,
        },
        {
            path: "/clock-on-clock-off",
            name: "Clock On - Clock Off",
            level: 3,
        },
        {
            path: "/app-configuration",
            name: "App Configuration",
            level: 3,
        },
    ];
    useEffect(() => {
        if (location.pathname === "/platform-management/collect-assist") {
            navigate("/platform-management/collect-assist/collectors");
        }
    }, [location]);
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/collectors", element: _jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(CollectorsPage, {}) }) }), _jsx(Route, { path: "/collectors/*", element: _jsx(CollectorInformationPageRoutes, {}) }), _jsx(Route, { path: "/credentials", element: _jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(ComingSoonPage, {}) }) }), _jsx(Route, { path: "/calendar", element: _jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(ComingSoonPage, {}) }) }), _jsx(Route, { path: "/clock-on-clock-off", element: _jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(ClockInClockOutPage, {}) }) }), _jsx(Route, { path: "/app-configuration", element: _jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(AppConfiguration, {}) }) }), _jsx(Route, { path: "/clock-on-clock-off/work-records", element: _jsx(WorkRecord, { selectedCollector: null }) })] }));
};
export default CollectorAssistPage;
