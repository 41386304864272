import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getGlobalDevicesData } from "src/api/dashboard/dashboardService";
import { HeaderFile } from "./HeaderFile";
import { GlobalViewTable } from "./Tables/GlobalViewTable";
export const GlobalView = () => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [globalData, setGlobalData] = useState([]);
    const [organisationName, setOrganisationName] = useState([]);
    const getGlobalViewData = async () => {
        setIsLoading(true);
        try {
            const result = await getGlobalDevicesData(userInfo.accessToken);
            if (result.status === "success") {
                setGlobalData(result?.data[0]?.results);
                setOrganisationName(result?.data[0]?.organisationName);
            }
            else {
                setGlobalData([]);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.error("Error in getDeviceTypeData:", error);
        }
    };
    useEffect(() => {
        if (userInfo) {
            getGlobalViewData();
        }
    }, [userInfo]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderFile, { name: "Device Inventory Global View", icon: "ic-test.svg" }), _jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                        position: "absolute",
                        top: "100px",
                        left: "40vw",
                    } })) }), _jsx("div", { style: { opacity: isLoading ? 0.3 : 1 }, children: _jsx(GlobalViewTable, { organisationName: organisationName, globalData: globalData }) })] }));
};
