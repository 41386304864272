import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Table from "antd/es/table";
import styled from "styled-components";
import { Select } from "antd";
import { checkAuth } from "src/api/auth/login";
import { getCommunication } from "src/api/communication/service";
import IcPrint from "src/assets/svg/ic-print.svg";
import { Col, Divider, Form, Image, InputWithLabel, Loading, Row, SearchInput, Text, } from "src/components/base";
import { supportingColours } from "src/constants";
import ContentContext from "src/providers/content";
import { SAColourScheme, mainAppColours } from "src/styles";
import { columnConfig } from "../config/column";
export const CommunicationArchive = () => {
    const [form] = Form.useForm();
    const contentRef = useRef(null);
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchString, setSearchString] = useState("");
    const { openPopupBox } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const [communication, setCommunication] = useState([]);
    const [filteredCommunication, setFilteredCommunication] = useState([]);
    const onSearchString = (value) => {
        setSearchString(value);
    };
    const getData = async () => {
        const result = await getCommunication(userInfo.accessToken);
        if (result.status === "error") {
            setCommunication([]);
        }
        if (result.status === "success") {
            setFilteredCommunication(result.data);
            setCommunication(result.data);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (userInfo) {
            getData();
        }
    }, [userInfo]);
    useEffect(() => {
        filterChange();
    }, [searchString]);
    const filterChange = () => {
        const value = form.getFieldsValue();
        const fromDate = value.fromDate ? moment(value.fromDate).startOf("day") : null;
        const toDate = value.toDate ? moment(value.toDate).endOf("day") : null;
        // If both fromDate and toDate are null, and there's no searchString, reset the filtered communication
        if (fromDate === null && toDate === null && !searchString) {
            setFilteredCommunication(communication);
            return;
        }
        const filteredData = communication.filter((item) => {
            const time = item.timeSent && moment(item.timeSent._seconds * 1000 + item.timeSent._nanoseconds / 1e6);
            const isWithinDateRange = (!fromDate || moment(time).isSameOrAfter(fromDate)) &&
                (!toDate || moment(time).isSameOrBefore(toDate));
            const matchesSearchString = item.name.toLowerCase().includes(searchString);
            return isWithinDateRange && matchesSearchString;
        });
        setFilteredCommunication(filteredData);
    };
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx(PrevIcon, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, className: "prev-icon" }));
        }
        if (type === "next") {
            return (_jsx(NextIcon, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, className: "next-icon" }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 5,
        pageSize: 10,
        total: filteredCommunication.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const [pageSize, setPageSize] = useState(10);
    const select = (size) => {
        setPagination({ ...pagination, pageSize: size });
        setPageSize(size);
    };
    return (_jsx(CommunicationArchiveWrapper, { children: _jsxs("div", { className: isLoading ? "loading" : "", children: [_jsxs(Form, { form: form, children: [_jsx(StyledTitle, { children: _jsx(Text, { name: "Communication Archive" }) }), _jsxs(Row, { className: "filter", children: [_jsx(Text, { className: "filter-text", name: "Start Date" }), _jsx(InputWithLabel, { className: "filter-input", field: "fromDate", inputProps: {
                                        type: "date",
                                        placeholder: null,
                                    }, onInputChange: filterChange }), _jsx(Text, { className: "filter-text", name: "End Date" }), _jsx(InputWithLabel, { className: "filter-input", field: "toDate", inputProps: {
                                        type: "date",
                                        placeholder: null,
                                    }, onInputChange: filterChange })] }), _jsx(Row, { justify: "end", style: { margin: "15px 0" }, children: _jsx(SearchInput, { onChange: (e) => onSearchString(e.target.value) }) })] }), isLoading && (_jsxs("div", { className: "is-loading", children: [_jsx(Loading, { iconProps: { style: { fontSize: 48, color: "Blue" } } }), _jsx("p", { className: "is-loading-text ", children: "Fetching details..." })] })), _jsx(Table, { dataSource: filteredCommunication ?? [], columns: columnConfig, pagination: pagination, scroll: { x: 900, y: 650 }, onRow: (record, rowIndex) => ({
                        onClick: () => {
                            if (rowIndex !== undefined) {
                                setContent({
                                    index: rowIndex,
                                    content: filteredCommunication[rowIndex].htmlTemplate,
                                    audience: filteredCommunication[rowIndex].audience,
                                    name: filteredCommunication[rowIndex].name,
                                    email: filteredCommunication[rowIndex].email,
                                    time: filteredCommunication[rowIndex].timeSent,
                                    category: filteredCommunication[rowIndex].category,
                                    fileName: filteredCommunication[rowIndex].fileName,
                                    fileUrl: filteredCommunication[rowIndex].fileUrl,
                                });
                            }
                        },
                        onDoubleClick: () => {
                            if (rowIndex !== undefined) {
                                setContent(null);
                                openPopupBox(_jsxs("div", { style: {
                                        position: "relative",
                                        width: "100%",
                                        padding: "48px 16px 16px",
                                        backgroundColor: supportingColours.BOX_GRAY,
                                    }, children: [_jsx(ContentWrapper, { ref: contentRef, className: "content-popup", srcDoc: filteredCommunication[rowIndex].htmlTemplate }), _jsx(IcPrint, { style: {
                                                position: "absolute",
                                                top: 16,
                                                right: 16,
                                                color: "#367893",
                                                cursor: "pointer",
                                            }, onClick: () => {
                                                contentRef.current.contentWindow.focus();
                                                contentRef.current.contentWindow.print();
                                            } })] }), null, {
                                    closeIconStyle: {
                                        left: 16,
                                        top: 16,
                                        right: "unset",
                                    },
                                });
                            }
                        },
                    }) }), filteredCommunication.length > 0 && (_jsxs(StyledRow, { justify: "start", align: "middle", children: [_jsx(Text, { name: "Show" }), _jsxs(Select, { value: pageSize, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] })] })), content && (_jsx(_Fragment, { children: _jsxs(Row, { className: "summary", children: [_jsx(Col, { className: "col-center", span: 2, style: { marginTop: "10px" }, children: _jsx("div", { className: "audience", children: _jsx(Text, { name: content.audience }) }) }), _jsxs(Col, { style: { marginTop: 17 }, span: 22, children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(Text, { className: "name", name: content.name }) }) }), _jsxs(Row, { children: [_jsx(Col, { span: 12, children: _jsx(Text, { className: "email", name: content.email }) }), _jsx(Col, { span: 12, className: "col-end", children: _jsx(Text, { className: "time", 
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    name: moment(new Date(content.time._seconds * 1000 + content.time._nanoseconds / 1e6)).format("DD MMMM YYYY HH:mm") }) })] }), _jsx(Divider, {}), _jsx(Row, { children: _jsx(Text, { className: "category", name: content.category }) }), _jsx(Divider, { className: "splash" }), _jsx(ContentWrapper, { ref: contentRef, className: "content", srcDoc: content.content }), content.fileName && (_jsxs(React.Fragment, { children: [_jsx(Divider, { className: "splash" }), _jsx(Row, { className: "attachment cell-middle", children: _jsx("a", { className: "attachment-file", target: "blank", href: content.fileUrl, children: content.fileName }) })] }))] })] }) }))] }) }));
};
const ContentWrapper = styled.iframe `
  width: calc(100% - 1px);
  background-color: ${SAColourScheme.WHITE};
  border: none;

  &.content {
    height: 316px;
  }

  &.content-popup {
    height: 750px;
  }
`;
const CommunicationArchiveWrapper = styled.div `
  width: 100% !important;
  background: ${mainAppColours.BLUE};
  padding: 16px;

  .loading {
    opacity: 0.3;
    pointer-events: none;
  }
  .is-loading {
    position: absolute;
    margin: 100px 45%;
    z-index: 10;
  }

  .is-loading-text {
    font-size: 17px;
    color: blue;
  }

  .filter {
    width: 100%;
    justify-content: flex-end;
  }

  .filter-text {
    color: ${SAColourScheme.WHITE} !important;
    margin: auto;
    margin-left: 12px;
    margin-right: 7px;
  }

  .filter-input {
    margin-bottom: 0px;

    input {
      font-size: 14px !important;
    }

    .ant-picker {
      height: 30px !important;
      width: 100px !important;
    }

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-table table {
    width: 100% !important;
    background-color: #ffffff !important;

    .ant-table-header {
      display: none !important;
    }
    .ant-table-thead th {
      background-color: ${mainAppColours.BLUE} !important;
      border-top: 1px solid ${SAColourScheme.WHITE} !important;
      color: ${SAColourScheme.WHITE} !important;
      padding: 15px;
    }

    .ant-table-row .ant-table-cell {
      padding: 10px !important;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      background-color: #ffffff !important;
    }
  }

  .ant-table-container {
    width: 100% !important;
  }

  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-options {
    display: none !important;
  }
  .ant-pagination-item-ellipsis {
    color: white !important;
  }
  .summary {
    margin-top: 16px;
    background-color: ${SAColourScheme.WHITE};

    .col-center {
      display: flex;
      justify-content: center;
    }

    .col-end {
      display: flex;
      justify-content: flex-end;
      padding-right: 17px;
    }

    .ant-divider,
    .splash {
      margin: 10px 0;
      border-top: 1px solid ${supportingColours.BOX_GRAY};
    }

    .splash {
      border-top-style: dashed;
    }
  }

  .audience {
    background: #adb3bc;
    height: 40px;
    width: 40px;
    padding: 8px 8px 0px;
    border-radius: 20px;

    .ant-typography {
      color: white !important;
      font-weight: 700;
      font-size: 1rem;
    }
  }
`;
const PrevIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const NextIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const StyledRow = styled(Row) `
  margin-top: -50px;
  margin-left: 10px;
  display: flex !important;
  gap: 10px;

  .ant-typography {
    font-size: 1rem;
    color: ${SAColourScheme.WHITE}!important;
  }
  .ant-select {
    max-width: 4.5em;
  }
  .ant-image-img {
    height: 8px;
    width: 14px;
  }
`;
const StyledTitle = styled(Row) `
  position: absolute;
  top: 40px;
  left: 50px;
  .ant-typography {
    color: ${SAColourScheme.WHITE}!important;
    font-size: 2rem;
    font-weight: 700;
  }
`;
