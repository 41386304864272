import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input, Modal, Space, Table, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { createInventory, updateInventory } from "src/api/inventory/inventoryService";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import ICCancel from "/public/assets/svg/inventory/ic-cancel.svg";
import ICEdit from "/public/assets/svg/inventory/ic-edit.svg";
import ICSave from "/public/assets/svg/inventory/ic-save.svg";
const { Text } = Typography;
const MINIMUM_STOCK_URINE = "Urine";
const MINIMUM_STOCK_ORAL = "Oral";
export const StockTable = ({ inventoryData, index }) => {
    const [data, setData] = useState(inventoryData);
    const [transformedData, setTransformedData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const calculatePeriodicValues = (monthlyValue) => {
        const roundedMonthlyValue = Math.round(monthlyValue); // Round to the nearest integer
        const stockMonthly = roundedMonthlyValue.toString();
        const stockDaily = Math.round((roundedMonthlyValue * 12) / 365).toString();
        const stockWeekly = Math.round((roundedMonthlyValue * 12) / 52).toString();
        const stockYearly = (roundedMonthlyValue * 12).toString();
        return {
            stockMonthly: stockMonthly,
            stockDaily: stockDaily,
            stockWeekly: stockWeekly,
            stockYearly: stockYearly,
        };
    };
    const transformData = (curData) => curData.flatMap((item) => {
        const stockUrineMonthly = parseFloat(item.stockUrineMonthly);
        const stockOralMonthly = parseFloat(item.stockOralMonthly);
        return [
            {
                ...item,
                key: `${item.id}-${MINIMUM_STOCK_URINE}`,
                minimumStock: MINIMUM_STOCK_URINE,
                ...calculatePeriodicValues(stockUrineMonthly),
            },
            {
                ...item,
                key: `${item.id}-${MINIMUM_STOCK_ORAL}`,
                minimumStock: MINIMUM_STOCK_ORAL,
                ...calculatePeriodicValues(stockOralMonthly),
            },
        ];
    });
    const handleMonthlyInputChange = (value, dataIndex) => {
        const parsedValue = parseFloat(value);
        // Check if the input is a valid number
        if (!isNaN(parsedValue)) {
            const updatedTransformedData = transformedData.map((item) => {
                if (item.minimumStock === dataIndex) {
                    return {
                        ...item,
                        ...calculatePeriodicValues(parsedValue),
                    };
                }
                return item;
            });
            setTransformedData(updatedTransformedData);
        }
        else {
            // If the input is not a valid number, set it to 0
            const updatedTransformedData = transformedData.map((item) => {
                if (item.minimumStock === dataIndex) {
                    return {
                        ...item,
                        ...calculatePeriodicValues(0),
                    };
                }
                return item;
            });
            setTransformedData(updatedTransformedData);
        }
    };
    const handleSaveClick = async () => {
        const combinedData = transformedData.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex((existingItem) => existingItem.id === item.id);
            if (existingItemIndex !== -1) {
                if (item.minimumStock === MINIMUM_STOCK_URINE) {
                    acc[existingItemIndex].stockUrineMonthly = String(item.stockMonthly);
                }
                else if (item.minimumStock === MINIMUM_STOCK_ORAL) {
                    acc[existingItemIndex].stockOralMonthly = String(item.stockMonthly);
                }
            }
            else {
                const newItem = {
                    id: String(item.id),
                    collectionPointId: String(item.collectionPointId),
                    contactName: String(item.contactName),
                    contactMobile: String(item.contactMobile),
                    email: String(item.email),
                    address: String(item.address),
                    stockOralMonthly: item.minimumStock === MINIMUM_STOCK_ORAL ? String(item.stockMonthly) : "",
                    stockUrineMonthly: item.minimumStock === MINIMUM_STOCK_URINE ? String(item.stockMonthly) : "",
                    unitsOral: String(item.unitsOral),
                    unitsUrine: String(item.unitsUrine),
                    oralUnitPrice: String(item.oralUnitPrice),
                    urineUnitPrice: String(item.urineUnitPrice),
                    userDefinedName: String(item.userDefinedName),
                };
                acc.push(newItem);
            }
            return acc;
        }, []);
        openLoadingScreen();
        try {
            // Iterate over each item in combinedData
            for (const combinedItem of combinedData) {
                if (combinedItem.id === "") {
                    // If the item doesn't have an id, it's a new inventory item
                    const response = await createInventory(userInfo.accessToken, combinedItem);
                    if (response.status !== "success") {
                        throw new Error("Failed to create inventory");
                    }
                }
                else {
                    // If the item has an id, it's an existing inventory item
                    const response = await updateInventory(userInfo.accessToken, combinedItem.id, combinedItem);
                    if (response.status !== "success") {
                        throw new Error("Failed to update inventory");
                    }
                }
            }
            openToast("Saved successfully");
            setEditMode(false);
        }
        catch (error) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again.");
        }
        finally {
            closeLoadingScreen();
        }
    };
    const handleEditClick = () => {
        setEditMode(!editMode);
    };
    const handleCancelClick = () => {
        setEditMode(false);
        setData(inventoryData);
    };
    const columns = [
        {
            title: "Minimum Stock",
            dataIndex: "minimumStock",
            key: "minimumStock",
            sorter: (a, b) => a.minimumStock.localeCompare(b.minimumStock),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Daily",
            dataIndex: "stockDaily",
            key: "stockDaily",
            sorter: (a, b) => a.stockDaily.localeCompare(b.stockDaily),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Weekly",
            dataIndex: "stockWeekly",
            key: "stockWeekly",
            sorter: (a, b) => a.stockWeekly.localeCompare(b.stockWeekly),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Monthly",
            dataIndex: "stockMonthly",
            key: "stockMonthly",
            sorter: (a, b) => a.stockMonthly.localeCompare(b.stockMonthly),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleMonthlyInputChange(e.target.value, record.minimumStock) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Yearly",
            dataIndex: "stockYearly",
            key: "stockYearly",
            sorter: (a, b) => a.stockYearly.localeCompare(b.stockYearly),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
    ];
    useEffect(() => {
        setTransformedData(transformData(data));
    }, [data]);
    return (_jsxs("div", { children: [_jsxs("div", { style: {
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsxs(Text, { style: { fontSize: "16px" }, children: [index, " Location"] }), _jsx(Text, { style: { fontSize: "16px" }, children: inventoryData[0].collectionPointName })] }), _jsx(Text, { style: { fontSize: "16px" }, children: inventoryData[0].userDefinedName })] }), _jsxs("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                    justifyContent: "space-between",
                }, children: [_jsxs("span", { style: { fontWeight: "700", fontSize: "16px" }, children: [_jsx(Button, { style: {
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    color: "#00004C",
                                }, onClick: showModal, children: "SHOW SITE CONTACT" }), _jsxs(Modal, { title: `SITE CONTACT - ${transformedData[0]?.collectionPointName}`, visible: isModalOpen, onCancel: handleCancel, footer: null, children: [_jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "User defined location name:" }), " ", transformedData[0]?.userDefinedName] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Contact Name:" }), " ", transformedData[0]?.contactName] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Contact Mobile:" }), " ", transformedData[0]?.contactMobile] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Email:" }), " ", transformedData[0]?.email] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Address:" }), " ", transformedData[0]?.address] }) })] })] }), editMode ? (_jsxs("div", { children: [_jsx(ICCancel, { onClick: handleCancelClick, style: { cursor: "pointer", marginRight: "10px" } }), _jsx(ICSave, { onClick: handleSaveClick, style: { cursor: "pointer" } })] })) : (_jsx(ICEdit, { onClick: handleEditClick, style: { cursor: "pointer" } }))] }), _jsx(Table, { columns: columns, dataSource: transformedData
                    ? transformedData.map((item) => ({ ...item, key: item.minimumStock }))
                    : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                    border: "1px solid #D9D9D9",
                    overflow: "hidden",
                    marginTop: "10px",
                } })] }));
};
