import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkAuth } from "src/api/auth/login";
import { archiveTestingDevice, deleteTestingDevice, getArchivedTestingDevice, getCollectionPoints, getDeviceTypes, getTestingDevice, restoreTestingDevice, } from "src/api/testingDevices/testingDeviceService";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import MainLayout from "src/components/layouts/MainLayout";
import { Breathalysers } from "./Breathalysers";
import { TabsWrapper } from "./index.style";
import { OralFluidDevices } from "./OralFluidDevices";
import { OralFluidScreenDevices } from "./OralFluidScreenDevices";
import { UrineDrugDevices } from "./UrineDrugDevices";
const { TabPane } = Tabs;
export const DeviceTypes = () => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.pathname.split("/").pop() || "0");
    const [data, setData] = useState([]);
    const [archivedData, setArchivedData] = useState([]);
    const [testDeviceTabData, setTestDeviceTabData] = useState([]);
    const [collectionPoints, setCollectionPoints] = useState([]);
    const navigate = useNavigate();
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const breadcrumbs = [
        _jsx(Link, { underline: "hover", color: "#2F3337", onClick: () => {
                navigate("/testing-devices");
            }, children: "Device Management" }, "1"),
        activeKey === "0" ? (_jsx(Typography, { color: "#2F3337", style: { borderBottom: "3px solid #01B4D2", fontWeight: "bolder" }, children: "Urine Drug Screen Devices" }, "1")) : activeKey === "1" ? (_jsx(Typography, { color: "#2F3337", style: { borderBottom: "3px solid #01B4D2", fontWeight: "bolder" }, children: "Oral Fluid Drug Screen Devices" }, "2")) : activeKey === "2" ? (_jsx(Typography, { color: "#2F3337", style: { borderBottom: "3px solid #01B4D2", fontWeight: "bolder" }, children: "Oral Fluid Collection Devices" }, "2")) : (_jsx(Typography, { color: "#2F3337", style: { borderBottom: "3px solid #01B4D2", fontWeight: "bolder" }, children: "Breathalysers" }, "2")),
    ];
    let completedFunctions = 0;
    const checkAllCompleted = () => {
        completedFunctions++;
        if (completedFunctions === 4) {
            closeLoadingScreen();
        }
    };
    //Get "All" testing devices data
    const getData = async () => {
        openLoadingScreen();
        try {
            const result = await getTestingDevice(userInfo.accessToken);
            if (result.status === "success") {
                setData(result.data);
            }
            else {
                setData([]);
            }
        }
        catch (error) {
            console.error("Error in getData:", error);
        }
        finally {
            checkAllCompleted();
        }
    };
    //Get "All" archived testing devices data
    const getArchivedDevicesData = async () => {
        openLoadingScreen();
        try {
            const result = await getArchivedTestingDevice(userInfo.accessToken);
            if (result.status === "success") {
                setArchivedData(result.data);
            }
            else {
                setArchivedData([]);
            }
        }
        catch (error) {
            console.error("Error in getArchivedDevicesData:", error);
        }
        finally {
            checkAllCompleted();
        }
    };
    //Get "All" device type information
    const getDeviceTypeData = async () => {
        openLoadingScreen();
        try {
            const result = await getDeviceTypes(userInfo.accessToken);
            if (result.status === "success") {
                setTestDeviceTabData(result.data);
            }
            else {
                setTestDeviceTabData([]);
            }
        }
        catch (error) {
            console.error("Error in getDeviceTypeData:", error);
        }
        finally {
            checkAllCompleted();
        }
    };
    //Get "All" collection points
    const getCP = async () => {
        try {
            const response = await getCollectionPoints(userInfo.accessToken);
            if (response.status === "success") {
                setCollectionPoints(response.data);
            }
            else {
                setCollectionPoints([]);
            }
        }
        catch (error) {
            console.error("Error in getCP:", error);
        }
        finally {
            checkAllCompleted();
        }
    };
    // Delete functionality for each device - set isValid to false
    const deleteTD = async (id) => {
        openLoadingScreen();
        const response = await deleteTestingDevice(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Deleted successfully");
            getData();
            getArchivedDevicesData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    // Archive functionality for each device - set isArchived to true
    const archiveTD = async (id) => {
        openLoadingScreen();
        const response = await archiveTestingDevice(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Archived successfully");
            getData();
            getArchivedDevicesData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    // Restore functionality for each device - set isArchived to false
    const restoreTD = async (id) => {
        openLoadingScreen();
        const response = await restoreTestingDevice(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Restored successfully");
            getData();
            getArchivedDevicesData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    // Handle tab change and push the url to the new tab index
    const handleTabChange = (key) => {
        setActiveKey(key);
        window.history.pushState({}, "", `/testing-devices/${key}`);
    };
    useEffect(() => {
        const keyFromUrl = location.pathname.split("/").pop();
        setActiveKey(keyFromUrl);
    }, [location.pathname]);
    useEffect(() => {
        if (userInfo) {
            getCP();
            getData();
            getDeviceTypeData();
            getArchivedDevicesData();
        }
    }, [userInfo]);
    //Based on index in getDeviceTypeData - pass the individual device type components
    return (_jsx(MainLayout, { children: _jsx(React.Fragment, { children: _jsxs("div", { style: { position: "relative", minHeight: "79vh" }, children: [_jsx(Header, { children: _jsx(CustomBreadcrumb, { separator: _jsx(NavigateNextIcon, { fontSize: "small", style: { color: "#2F3337" } }), "aria-label": "breadcrumb", style: {
                                color: "white",
                                position: "absolute",
                                top: "0px",
                                left: "3vw",
                                marginTop: "15px",
                            }, children: breadcrumbs }) }), _jsx(TabsWrapper, { children: _jsx(Tabs, { defaultActiveKey: activeKey, activeKey: activeKey, onChange: handleTabChange, tabPosition: "top", type: "card", children: testDeviceTabData
                                ?.sort((a, b) => a.index - b.index)
                                .map((tab) => (_jsxs(TabPane, { tab: tab.name, children: [tab.index === "0" && (_jsx(UrineDrugDevices, { data: data && Array.isArray(data)
                                            ? data.filter((device) => device.deviceTypeId === tab.id)
                                            : [], deviceTypeId: tab.id, collectionPoints: collectionPoints, archivedData: archivedData && Array.isArray(archivedData)
                                            ? archivedData.filter((archiveDevice) => archiveDevice.deviceTypeId === tab.id)
                                            : [], deleteTD: deleteTD, archiveTD: archiveTD, restoreTD: restoreTD, getData: getData, getArchivedDevicesData: getArchivedDevicesData })), tab.index === "1" && (_jsx(OralFluidScreenDevices, { deviceTypeId: tab.id, data: data && Array.isArray(data)
                                            ? data.filter((device) => device.deviceTypeId === tab.id)
                                            : [], collectionPoints: collectionPoints, archivedData: archivedData && Array.isArray(archivedData)
                                            ? archivedData.filter((archiveDevice) => archiveDevice.deviceTypeId === tab.id)
                                            : [], deleteTD: deleteTD, archiveTD: archiveTD, restoreTD: restoreTD, getData: getData, getArchivedDevicesData: getArchivedDevicesData })), tab.index === "2" && (_jsx(OralFluidDevices, { deviceTypeId: tab.id, data: data && Array.isArray(data)
                                            ? data.filter((device) => device.deviceTypeId === tab.id)
                                            : [], collectionPoints: collectionPoints, archivedData: archivedData && Array.isArray(archivedData)
                                            ? archivedData.filter((archiveDevice) => archiveDevice.deviceTypeId === tab.id)
                                            : [], deleteTD: deleteTD, archiveTD: archiveTD, restoreTD: restoreTD, getData: getData, getArchivedDevicesData: getArchivedDevicesData })), tab.index === "3" && (_jsx(Breathalysers, { deviceTypeId: tab.id, data: data && Array.isArray(data)
                                            ? data.filter((device) => device.deviceTypeId === tab.id)
                                            : [], collectionPoints: collectionPoints, archivedData: archivedData && Array.isArray(archivedData)
                                            ? archivedData.filter((archiveDevice) => archiveDevice.deviceTypeId === tab.id)
                                            : [], deleteTD: deleteTD, archiveTD: archiveTD, restoreTD: restoreTD, getData: getData, getArchivedDevicesData: getArchivedDevicesData }))] }, tab.index))) }) })] }) }) }));
};
const CustomBreadcrumb = styled(Breadcrumbs) `
  padding-inline: 2.5rem;
  padding-top: 0.5rem;
  color: white;

  a {
    color: white !important;
  }

  .ant-breadcrumb-separator {
    color: white;
  }
`;
const Header = styled.div `
  height: 5rem;
  background-color: #2c7796;
  width: 100%;
  color: white;
  nav {
    margin-top: 0 !important;
    left: 0 !important;
  }
  a,
  span,
  p,
  svg {
    color: white !important;
  }
`;
