import { isArray, isEmpty, isObject } from "lodash";
import { ApiActionType } from "./apiRedux";
export const filterSuccessDataAdapter = (actionType, oldState, newState) => {
    if (actionType == ApiActionType.SUCCESS && !isEmpty(newState.data)) {
        return {
            ...newState,
            data: isArray(newState.data?.data)
                ? [...(newState.data ? newState.data.data : [])]
                : isObject(newState.data?.data)
                    ? { ...newState.data?.data }
                    : newState.data?.data,
        };
    }
    return newState;
};
