import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Col, Progress, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getProcessPatientsCount, getProcessTestsCount } from "src/api/dashboard/dashboardService";
import { DailyPieChart } from "src/pages/DashboardPage/DailyPieChart";
import { dashboard_getColorByName } from "src/utils/helper";
import ColInput from "./components/ColInput";
const DailySummary = ({ selectedColorScheme }) => {
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedLocation, setSelectedLocation] = useState("All");
    const [dailyTestData, setDailyTestData] = useState({});
    const [dailyPatientData, setDailyPatientData] = useState({});
    const authData = useSelector(checkAuth.dataSelector);
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };
    const handlePrevDay = () => {
        const newDate = moment(selectedDate).subtract(1, "day");
        handleDateChange(newDate);
    };
    const handleNextDay = () => {
        const newDate = moment(selectedDate).add(1, "day");
        handleDateChange(newDate);
    };
    useEffect(() => {
        getData();
        getDataBySex();
    }, [selectedDate]);
    const getData = async () => {
        const result = await getProcessTestsCount("daily", selectedDate, selectedDate, authData.accessToken);
        if (result.status === "success") {
            const dateString = moment(selectedDate).format("YYYY-MM-DD");
            setDailyTestData(result.data[dateString]);
        }
        else {
            setDailyTestData({});
        }
    };
    const getDataBySex = async () => {
        const result = await getProcessPatientsCount(selectedDate, selectedDate, authData.accessToken);
        if (result.status === "success") {
            setDailyPatientData(result.data);
        }
        else {
            setDailyPatientData({});
        }
    };
    return (_jsxs("div", { className: "daily-summary-section layout", children: [_jsxs("div", { children: [_jsx(Typography, { className: "header", children: "Daily Summary" }), _jsx(Divider, { color: "#E1E2E8", style: { marginBottom: "3px" } })] }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                }, children: [_jsx("label", { children: "Date" }), _jsxs("div", { style: {
                            width: "70%",
                            border: "1px solid #ADB3BC",
                            borderRadius: "5px",
                            display: "flex",
                            height: "28px",
                            justifyContent: "center",
                            alignItems: "center",
                        }, children: [_jsx(IconButton, { onClick: handlePrevDay, children: _jsx(KeyboardArrowLeftIcon, {}) }), _jsx("input", { type: "text", readOnly: true, style: { border: "none", textAlign: "center", width: "100%" }, value: selectedDate.format("ddd, D MMM YYYY") }), _jsx(IconButton, { onClick: handleNextDay, children: _jsx(KeyboardArrowRightIcon, {}) })] })] }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    // marginTop: "5px",
                    alignItems: "center",
                }, children: [_jsx("label", { children: "Location" }), _jsxs("select", { defaultValue: selectedLocation, onChange: (event) => setSelectedLocation(event.target.value), style: {
                            width: "70%",
                            height: "28px",
                            justifyContent: "center",
                            borderRadius: "5px",
                            border: "1px solid #ADB3BC",
                            textAlign: "center",
                        }, children: [_jsx("option", { value: "All", children: "All" }), Object.keys(dailyTestData)
                                .filter((cp) => cp !== "All")
                                .map((cp) => (_jsx("option", { value: cp, children: cp }, `option-${cp}`)))] })] }), _jsxs("div", { children: [_jsx(Typography, { className: "header", children: "Tests" }), _jsx(Divider, { color: "#E1E2E8" })] }), _jsx(DailyPieChart, { selectedColorScheme: selectedColorScheme, countTestType: dailyTestData[selectedLocation] }), Object.entries(dailyTestData).length > 0 &&
                Object.keys(dailyTestData[selectedLocation])?.map((testType, index) => (_jsxs("div", { style: {
                        display: "flex",
                        gap: "20px",
                        justifyContent: "space-around",
                    }, children: [_jsx("div", { style: {
                                width: "70%",
                                border: "1px solid white",
                                padding: "5px 10px",
                                backgroundColor: dashboard_getColorByName(selectedColorScheme, index + 1),
                                color: "white",
                                borderRadius: "5px",
                            }, children: testType }), _jsx("div", { style: {
                                width: "15%",
                                border: "1px solid #E1E2E8",
                                padding: "5px",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                textAlign: "center",
                            }, children: dailyTestData[selectedLocation][testType] })] }, testType))), _jsxs("div", { style: { marginTop: "30px" }, children: [_jsx(Typography, { className: "header", children: "Patients" }), _jsx(Divider, { color: "#E1E2E8" }), dailyPatientData && (_jsxs(_Fragment, { children: [_jsxs(Row, { gutter: 16, style: { marginTop: "15px", alignItems: "center" }, children: [_jsx(Col, { className: "gutter-row label", span: 4, children: "Male" }), _jsx(Col, { className: "gutter-row", span: 16, children: _jsx(Progress, { percent: dailyPatientData[selectedLocation]?.Male || 0, showInfo: false, strokeColor: dashboard_getColorByName(selectedColorScheme, 0) }) }), _jsx(ColInput, { span: 4, value: dailyPatientData[selectedLocation]?.Male || 0, size: "small", style: {
                                            textAlign: "center",
                                            height: "30px",
                                            borderRadius: "5px",
                                        } })] }), _jsxs(Row, { gutter: 16, style: { marginTop: "15px", alignItems: "center" }, children: [_jsx(Col, { className: "gutter-row label", span: 4, children: "Female" }), _jsx(Col, { className: "gutter-row", span: 16, children: _jsx(Progress, { percent: dailyPatientData[selectedLocation]?.Female || 0, status: "success", showInfo: false, strokeColor: dashboard_getColorByName(selectedColorScheme, 1) }) }), _jsx(ColInput, { span: 4, value: dailyPatientData[selectedLocation]?.Female || 0, size: "small", style: {
                                            textAlign: "center",
                                            height: "30px",
                                            borderRadius: "5px",
                                        } })] }), _jsxs(Row, { gutter: 16, style: { marginTop: "15px", alignItems: "center" }, children: [_jsx(Col, { className: "gutter-row label", span: 4, children: "X" }), _jsx(Col, { className: "gutter-row", span: 16, children: _jsx(Progress, { percent: dailyPatientData[selectedLocation]?.X || 0, status: "exception", showInfo: false, strokeColor: dashboard_getColorByName(selectedColorScheme, 2) }) }), _jsx(ColInput, { span: 4, value: dailyPatientData[selectedLocation]?.X || 0, size: "small", style: {
                                            textAlign: "center",
                                            height: "30px",
                                            borderRadius: "5px",
                                        } })] })] }))] })] }));
};
export default DailySummary;
