import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "src/api/auth/login";
import { Button, CheckBox, Form, Image, Input, message, Text } from "src/components/base";
import { checkAuthentication } from "src/store/app/action";
import { getAuthStatus } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { FormSignInWrapper } from "./index.style";
const FormSignIn = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(getAuthStatus);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const onClickEye = () => {
        setIsShowPassword(!isShowPassword);
    };
    useEffect(() => {
        const checkLogin = async () => {
            await dispatch(checkAuthentication());
        };
        checkLogin();
    }, []);
    useEffect(() => {
        if (isAuth && location.pathname !== "/" && location.state !== "/") {
            navigate("/");
        }
    }, [isAuth]);
    const onFinish = async (values) => {
        setLoading(true);
        const userInfo = await loginUser.actionCreator(values.email, values.password);
        if (userInfo.success) {
            message.success("Login Success");
            navigate("/");
        }
        else {
            if (userInfo?.message?.includes("auth/wrong-password")) {
                message.error("Password is wrong");
            }
            else if (userInfo?.message?.includes("Access to this account " +
                "has been temporarily disabled due to many failed login attempts")) {
                message.error("Access to this account has been temporarily " +
                    "disabled due to many failed login attempts");
            }
            else if (userInfo?.message?.includes("auth/user-not-found")) {
                message.error("Your account does not existed");
            }
            else {
                message.error("Something went wrong");
            }
        }
        setLoading(false);
    };
    return (_jsxs(FormSignInWrapper, { children: [_jsx("div", { style: {
                    textAlign: "center",
                    marginBottom: "40px",
                }, children: _jsxs("div", { style: {
                        textAlign: "center",
                    }, children: [_jsx(Image, { src: "/assets/images/collectors.png", alt: "collectors icon", width: "40%", height: "40%", preview: false }), _jsxs("div", { style: {
                                fontSize: 25,
                                marginTop: 20,
                                fontWeight: "bold",
                                color: "#1A1448",
                            }, children: ["Collection", _jsx("span", { style: {
                                        fontWeight: "bold",
                                        color: "#00B4D2",
                                    }, children: "Manager\u2122" })] })] }) }), _jsxs(Form, { name: "normal_login", className: "login-form", initialValues: { remember: true }, onFinish: onFinish, children: [_jsx(Form.Item, { name: "email", rules: [{ type: "email", message: "Please enter a valid email address" }], children: _jsxs("div", { className: "email-login", children: [_jsx("div", { className: "email-icon", children: _jsx(Image, { src: "/assets/images/user-icon-hightlight.png", alt: "user icon", height: 30, width: 30, preview: false }) }), _jsx("div", { className: "email-input", children: _jsx(Input, { id: "email-input", style: {
                                            padding: "8px",
                                            border: "2px solid #0047b3",
                                            borderRadius: "0px 5px 5px 0px",
                                            borderLeft: "none",
                                            color: "#0047b3",
                                            boxShadow: "none",
                                            backgroundColor: "transparent",
                                        }, size: "large", placeholder: "Enter email" }) })] }) }), _jsx(Form.Item, { name: "password", rules: [{ required: true, message: "Please input your password!" }], children: _jsxs("div", { className: "password-login", children: [_jsx("div", { className: "password-icon", children: _jsx(Image, { src: "/assets/images/lock-icon.png", alt: "lock icon", preview: false, height: "25px" }) }), _jsx("div", { className: "password-input", children: _jsx(Input, { type: !isShowPassword ? "password" : "text", id: "password-input", style: {
                                            padding: "8px",
                                            border: "2px solid #0047b3",
                                            borderLeft: "none",
                                            color: "#0047b3",
                                            boxShadow: "none",
                                            borderRight: "none",
                                            borderRadius: "0px",
                                            backgroundColor: "transparent",
                                        }, size: "large", placeholder: "Enter password" }) }), _jsx("div", { className: "look-icon", children: _jsx(Image, { src: "/assets/images/look-icon.png", alt: "look icon", preview: false, width: "25px", onClick: onClickEye }) })] }) }), _jsxs(Form.Item, { children: [_jsx(Form.Item, { name: "remember", valuePropName: "checked", noStyle: true, children: _jsx(CheckBox, { style: { color: "#0047b3" }, children: "Remember me" }) }), _jsxs("a", { className: "login-form-forgot", href: "/forgot-password", children: [_jsx(Image, { src: "/assets/images/mail-icon.png", alt: "email icon", height: "15px", width: "30px", style: { paddingRight: "10px" }, preview: false }), "Forgot password"] })] }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "login-form-button", loading: loading, children: "Sign In" }) })] }), _jsx("div", { className: "additional-info", children: _jsx(Text, { name: "ABN 35 631 923 937" }) }), _jsxs("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                }, children: [_jsx("div", { className: "additional-info", children: _jsx("a", { href: "https://www.sampleassist.com/sampleassist-terms-conditions", children: "Terms and Conditions" }) }), _jsx("div", { className: "additional-info", children: "|" }), _jsx("div", { className: "additional-info", children: _jsx("a", { href: "https://www.sampleassist.com/sampleassist-privacy-policy", children: "Privacy Policy" }) })] }), _jsx("div", { className: "additional-info", children: _jsx(Text, { name: `© Copyright ${moment().year()}` }) })] }));
};
export default FormSignIn;
