import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { checkAuth } from "src/api/auth/login";
import { createPin, updatePin, verifyPin } from "src/api/authority/authorityService";
import { Button, Image, Loading, Text } from "src/components/base";
import { PinInputWrapper } from "src/components/base/PinInput";
import { fontWeight, mainAppColours, MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { SAColourScheme } from "src/styles";
export const SetUpPin = (props) => {
    const [currentPin, setCurrentPin] = useState("");
    const [newPin, setNewPin] = useState();
    const [confirmNewPin, setConfirmNewPin] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowMessage, setIsShowMessage] = useState(false);
    const { isCreatedPin } = props;
    const { openMessageBox, closePopupBox } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const onSave = async () => {
        setIsLoading(true);
        if (isCreatedPin && !currentPin) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Please input Current PIN");
            return;
        }
        if (!newPin || !confirmNewPin) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Please input New PIN and Confirm New PIN");
            return;
        }
        if (newPin !== confirmNewPin) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "New PIN and Confirm New PIN don't match");
            return;
        }
        const processCreatePin = async () => {
            const createResult = await createPin(userInfo.accessToken, newPin);
            return createResult.status;
        };
        const processUpdatePin = async () => {
            const verifyResult = await verifyPin(userInfo.accessToken, currentPin);
            if (verifyResult.status == "fail") {
                return verifyResult;
            }
            const updateResult = await updatePin(userInfo.accessToken, currentPin, newPin);
            return updateResult;
        };
        if (!isCreatedPin) {
            const result = await processCreatePin();
            if (result == "success") {
                setIsShowMessage(true);
            }
            else {
                openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong.\nPlease try again");
            }
        }
        else {
            const result = await processUpdatePin();
            if (result.status == "success") {
                setIsShowMessage(true);
            }
            else {
                if (result.message == "pin wrong") {
                    openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "The current PIN you entered is incorrect.\nPlease try again.");
                }
                else {
                    openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong.\nPlease try again");
                }
            }
        }
    };
    return (_jsx(SetUpPinWrapper, { style: {
            padding: isShowMessage ? "60px 0" : "25px 15px 15px",
        }, children: !isShowMessage ? (_jsxs(_Fragment, { children: [isCreatedPin ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "vault-pin-title", style: { marginBottom: "20px" }, children: [_jsx(Image, { src: "/assets/svg/ic-vault-lock.svg", preview: false }), _jsx(Text, { className: "title", name: "Update Your Signature Vault PIN" })] }), _jsx(PinInputWrapper, { title: "Enter Your Current PIN", onComplete: (value) => {
                                setCurrentPin(value);
                            }, inputSize: 38, inputStyle: {
                                boxShadow: "none",
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                margin: "0 5px",
                            }, inputFocusStyle: { boxShadow: "none" }, titleStyle: {
                                color: mainAppColours.MID_BLUE,
                            }, style: {
                                borderBottom: `1px solid ${mainAppColours.MID_BLUE}`,
                                paddingBottom: 20,
                                marginBottom: 20,
                            } }), _jsx(PinInputWrapper, { title: "Enter Your New PIN", onComplete: (value) => {
                                setNewPin(value);
                            }, inputSize: 38, inputStyle: {
                                boxShadow: "none",
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                margin: "0 5px",
                            }, inputFocusStyle: { boxShadow: "none" }, titleStyle: {
                                color: mainAppColours.MID_BLUE,
                            }, style: {
                                marginBottom: 20,
                            } }), _jsx(PinInputWrapper, { title: "Confirm Your New PIN", onComplete: (value) => {
                                setConfirmNewPin(value);
                            }, inputSize: 38, inputStyle: {
                                boxShadow: "none",
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                margin: "0 5px",
                            }, inputFocusStyle: { boxShadow: "none" }, titleStyle: {
                                color: mainAppColours.MID_BLUE,
                            } })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "vault-pin-title", style: { marginBottom: "20px" }, children: [_jsx(Image, { src: "/assets/svg/ic-vault-lock.svg", preview: false }), _jsx(Text, { className: "title", name: "Create Your Signature Vault PIN" })] }), _jsx(PinInputWrapper, { title: "Enter New PIN", onComplete: (value) => {
                                setNewPin(value);
                            }, inputSize: 38, inputStyle: {
                                boxShadow: "none",
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                margin: "0 5px",
                            }, inputFocusStyle: { boxShadow: "none" }, titleStyle: {
                                color: mainAppColours.MID_BLUE,
                            }, style: {
                                marginBottom: 20,
                            } }), _jsx(PinInputWrapper, { title: "Confirm New PIN", onComplete: (value) => {
                                setConfirmNewPin(value);
                            }, inputSize: 38, inputStyle: {
                                boxShadow: "none",
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                margin: "0 5px",
                            }, inputFocusStyle: { boxShadow: "none" }, titleStyle: {
                                color: mainAppColours.MID_BLUE,
                            } })] })), _jsx(Button, { className: "save-button", onClick: () => onSave().finally(() => {
                        setIsLoading(false);
                    }), disabled: isLoading, children: isLoading ? _jsx(Loading, {}) : "Save" }), _jsx(Text, { className: "hint-text", name: `Forgot your PIN?` }), _jsx(Text, { className: "hint-text", name: `Please email at support@sampleassist.com` })] })) : (_jsxs(_Fragment, { children: [_jsx(Image, { src: "/assets/svg/ic-vault-lock-success.svg", preview: false }), _jsx(Text, { className: "title-success", name: "Success!" }), _jsx(Text, { className: "message-success", name: "Your Signature PIN  has been set successfully" }), _jsx(Button, { className: "save-button", onClick: () => closePopupBox(), children: "Close" })] })) }));
};
const SetUpPinWrapper = styled.div `
  width: 100%;
  padding: 25px 15px 15px;
  background-color: ${SAColourScheme.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .vault-pin-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-image {
      margin-right: 13px;
    }
  }

  .title.ant-typography,
  .title-success {
    font-size: 18px;
    font-weight: ${fontWeight.BOLD};
    color: ${mainAppColours.BLUE} !important;
  }

  .title-success {
    font-size: 28px;
    color: ${mainAppColours.MID_BLUE} !important;
    margin-top: 12px;
  }

  .alert.ant-typography {
    font-size: 14px;
    font-weight: ${fontWeight.REGULAR};
    color: #000000 !important;
    margin: 13px 0;
  }

  .message-success {
    font-size: 18px;
    color: #00004c !important;
    margin: 60px 0;
  }

  .ant-divider {
    max-width: 50%;
    color: ${mainAppColours.MID_BLUE};
  }
  .save-button {
    background-color: ${mainAppColours.MID_BLUE};
    border-radius: 10px;
    margin-top: 38px;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;

    span {
      color: #ffffff;
      font-size: 20px;
      font-weight: ${fontWeight.MEDIUM};
    }
  }
  .hint-text {
    color: ${mainAppColours.MID_BLUE} !important;
    font-size: 13px;
  }
`;
