import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Image } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { getCollectionOrganizationDetails } from "src/api/collection/collectionService";
import MainLayout from "src/components/layouts/MainLayout";
import { NewBreathalyser } from "./Forms/NewBreathalyser";
import { NewOral } from "./Forms/NewOral";
import { NewOralScreen } from "./Forms/NewOralScreen";
import { NewUrine } from "./Forms/NewUrine";
export const NewDevice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userInfo = useSelector(checkAuth.dataSelector);
    const name = searchParams.get("name");
    const record = location.state;
    const [collectionPointList, setCollectionPointList] = useState([]);
    const [collectionsPointOptions, setCollectionsPointOptions] = useState([]);
    const [collectionCentre, setCollectionCentre] = useState(null);
    const getCollectionCentre = async () => {
        const response = await getCollectionOrganizationDetails(userInfo.accessToken);
        if (response.status === "success") {
            setCollectionCentre(response.data.collectionOrganizationDetail[0]);
            setCollectionPointList(response.data.collectionPoints[0]);
        }
        else {
            setCollectionCentre(null);
            setCollectionPointList([]);
        }
    };
    useEffect(() => {
        getCollectionCentre();
    }, []);
    //Generating the tree view select for the checkboxes to select collection points in the form
    useEffect(() => {
        if (!isEmpty(collectionPointList) && collectionCentre) {
            const curVal = [
                {
                    title: collectionCentre.collectionName,
                    value: collectionCentre.id,
                    children: collectionPointList.map((point) => ({
                        title: point.collectionPointName,
                        value: point.id,
                    })),
                },
            ];
            setCollectionsPointOptions(curVal);
        }
    }, [collectionCentre, collectionPointList]);
    // Based on the type of device, open the new device form
    // Different for different device types
    return (_jsx(MainLayout, { children: _jsxs("div", { style: { minHeight: "79vh" }, children: [_jsxs("div", { style: {
                        width: "100%",
                        height: "50px",
                        background: "#E6F7FA",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }, children: [_jsxs(Button, { onClick: () => navigate(-1), style: {
                                border: "none",
                                background: "#E6F7FA",
                            }, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/back-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#4B5767", fontWeight: "700", fontSize: "16px" }, children: "Go Back" })] }), _jsx("span", { style: {
                                flex: 1,
                                textAlign: "center",
                                margin: "auto",
                                fontSize: "16px",
                                fontWeight: "700",
                            }, children: name })] }), name === "Urine Drug Screen Devices" && (_jsx(NewUrine, { record: record, collectionsPointOptions: collectionsPointOptions })), name === "Oral Fluid Drug Screen Devices" && (_jsx(NewOralScreen, { record: record, collectionsPointOptions: collectionsPointOptions })), name === "Oral Fluid Collection Devices" && (_jsx(NewOral, { record: record, collectionsPointOptions: collectionsPointOptions })), name === "Breathalysers" && (_jsx(NewBreathalyser, { record: record, collectionsPointOptions: collectionsPointOptions }))] }) }));
};
