import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Dropdown, List, Popover, Typography } from "antd";
import moment, { duration } from "moment";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CloseButtonIcon from "src/assets/svg/job-management/close-button-icon.svg";
import ViewCollectorIcon from "src/assets/svg/job-management/view-collector-icon.svg";
import { Text } from "src/components/base";
import ContentContext from "src/providers/content";
import { DataTable } from "src/components/commonElement/DataTable";
import { OnsiteJobType } from "../../store/types";
import { ApprovalsForm } from "../ApprovalsForm";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
export const ApprovalsDataTable = ({ onsiteApprovalsData }) => {
    const { openPopupBox } = useContext(ContentContext);
    const [open, setOpen] = useState(null);
    const closePopover = () => {
        setOpen(null);
    };
    const handleOpenPopoverChange = (openId) => {
        setOpen(openId);
    };
    const columns = [
        {
            title: "Organisation",
            dataIndex: "clientName",
            key: "clientName",
            sorter: (a, b) => a.clientName.localeCompare(b.clientName),
            defaultSortOrder: "ascend",
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: (a, b) => a.location.localeCompare(b.location),
        },
        {
            title: "Approver",
            dataIndex: "submittedSiteContactName",
            key: "submittedSiteContactName",
            sorter: (a, b) => a.submittedSiteContactName.localeCompare(b.submittedSiteContactName),
        },
        {
            title: "Collector",
            dataIndex: "collectors",
            key: "collectors",
            align: "center",
            render: (_, record) => (_jsx(CustomPopover, { content: _jsx(CardContent, { title: _jsxs(CardHeader, { children: [_jsx(Typography.Title, { level: 5, children: "Collectors" }), _jsx(Button, { type: "text", icon: _jsx(CloseButtonIcon, {}), onClick: closePopover })] }), children: _jsx(List, { itemLayout: "horizontal", dataSource: record.collectors, renderItem: (collector, index) => (_jsx(List.Item, { children: _jsx(List.Item.Meta, { title: _jsx(Text, { name: collector.name }) }) }, index)) }) }), trigger: "click", placement: "bottomRight", open: open === record.id, onOpenChange: () => handleOpenPopoverChange(record.id), children: _jsx(Button, { type: "text", icon: _jsx(ViewCollectorIcon, {}) }) })),
        },
        {
            title: "Start Time",
            dataIndex: "startAt",
            key: "startAt",
            sorter: (a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime(),
            render: (text) => _jsx("span", { children: moment(text).format("hh:mm a") }),
        },
        {
            title: "End Time",
            dataIndex: "endAt",
            key: "endAt",
            sorter: (a, b) => new Date(a.endAt).getTime() - new Date(b.endAt).getTime(),
            render: (text) => _jsx("span", { children: moment(text).format("hh:mm a") }),
        },
        {
            title: "Duration",
            key: "duration",
            sorter: (a, b) => {
                const startA = moment(a.startAt);
                const endA = moment(a.endAt);
                const durationA = duration(endA.diff(startA)).asMinutes();
                const startB = moment(b.startAt);
                const endB = moment(b.endAt);
                const durationB = duration(endB.diff(startB)).asMinutes();
                return durationA - durationB;
            },
            render: (text, record) => {
                const start = moment(record.startAt);
                const end = moment(record.endAt);
                const curDuration = duration(end.diff(start));
                const hours = Math.floor(curDuration.asHours());
                const minutes = curDuration.minutes();
                return _jsx("span", { children: `${hours}h ${minutes}m` });
            },
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => {
                const menuItems = [
                    {
                        key: "ManageJob",
                        label: (_jsx("a", { onClick: () => {
                                openPopupBox(_jsx(ApprovalsForm, { record: record }, record.id));
                            }, children: "Onsite Approval" }, `approvals-${record.id}`)),
                    },
                    {
                        key: "view-job",
                        label: (_jsx(Link, { to: `/job-management/${record.onsiteJobId}${record.jobType === OnsiteJobType.Normal ? "" : "/call-out"}`.replace("//", "/"), children: "View Job" }, `view-job-${record.id}`)),
                    },
                ];
                return (_jsx(Dropdown, { menu: { items: menuItems }, trigger: ["click"], children: _jsx(ICMenuOptions, {}) }));
            },
        },
    ];
    return _jsx(DataTable, { columns: columns, dataSource: onsiteApprovalsData });
};
const CustomPopover = styled(Popover) `
  div.ant-popover-inner-content {
    padding: 0 !important;
    border: 0.1rem solid #1a8cff !important;
  }
`;
const CardContent = styled(Card) `
  width: 20rem;

  .ant-card-head {
    border-bottom: 0.2rem solid #01b4d2;
  }

  .ant-card-body {
    padding: 0 1rem !important;

    .ant-typography {
      color: #000000 !important;
    }

    .ant-list-item-meta-title {
      padding: 0 !important;
    }
  }
`;
const CardHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    color: #00b4d1;
  }
`;
