/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from "antd";
import axios from "axios";
import { signOut } from "firebase/auth";
import moment from "moment";
import { auth } from "src/firebase";
import { Domain } from "src/types";
const handleFinalError = () => {
    signOut(auth);
    window.location.replace("/login");
};
const mainURL = process.env.DOMAIN;
const uploadURL = "/sa/upload";
const mainApiInstance = axios.create({ baseURL: mainURL });
const uploadApiInstance = axios.create({ baseURL: uploadURL });
const refreshAndRetryQueue = [];
let isRefreshing = false;
axios.interceptors.response.use((response) => response, async (error) => {
    const errorResponse = JSON.parse(JSON.stringify(error));
    let originalRequest = error.config;
    if (errorResponse && errorResponse.status === 401) {
        if (!isRefreshing && auth && auth.currentUser) {
            isRefreshing = true;
            try {
                const newAccessToken = await auth.currentUser.getIdToken(true);
                const headerConfig = {
                    headers: {
                        idToken: newAccessToken,
                        "Content-Type": "application/json",
                    },
                };
                originalRequest = { ...originalRequest, ...headerConfig };
                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                    mainApiInstance
                        .request(config)
                        .then((response) => resolve(response))
                        .catch((err) => reject(err));
                });
                refreshAndRetryQueue.length = 0;
                try {
                    const instance = mainApiInstance(originalRequest);
                    return instance;
                }
                catch {
                    return Promise.reject("refreshError");
                }
            }
            catch (refreshError) {
                return Promise.reject(refreshError);
            }
            finally {
                isRefreshing = false;
            }
        }
        return new Promise((resolve, reject) => {
            refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
    }
    return Promise.reject("error");
});
const getCurrentToken = async () => {
    if (!auth || !auth.currentUser) {
        handleFinalError();
        return;
    }
    let tokenResult = await auth.currentUser.getIdTokenResult();
    if (!tokenResult) {
        handleFinalError();
        return;
    }
    if (moment(tokenResult.expirationTime).isSameOrBefore(moment())) {
        tokenResult = await auth.currentUser.getIdTokenResult(true);
    }
    return tokenResult.token;
};
const getAxiosInstance = (domain) => {
    let axiosInstance = mainApiInstance;
    switch (domain) {
        case Domain.Main: {
            axiosInstance = mainApiInstance;
            break;
        }
        case Domain.Upload: {
            axiosInstance = uploadApiInstance;
            break;
        }
    }
    return axiosInstance;
};
export const $get = async (endpoint, domain, option) => {
    const accessToken = await getCurrentToken();
    const config = {
        headers: {
            idToken: accessToken,
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.get(endpoint, config);
        return response.data;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export const $post = async (endpoint, data, domain, option) => {
    const accessToken = await getCurrentToken();
    const config = {
        headers: {
            idToken: accessToken,
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.post(endpoint, data, config);
        return response.data;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export const $patch = async (endpoint, data, domain, option) => {
    const accessToken = await getCurrentToken();
    const config = {
        headers: {
            idToken: accessToken,
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.patch(endpoint, data, config);
        return response.data;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export const $put = async (endpoint, data, domain, option) => {
    const accessToken = await getCurrentToken();
    const config = {
        headers: {
            idToken: accessToken,
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.put(endpoint, data, config);
        return response.data;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export const $delete = async (endpoint, domain, option) => {
    const accessToken = await getCurrentToken();
    const config = {
        headers: {
            idToken: accessToken,
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.delete(endpoint, config);
        return response.data;
    }
    catch (err) {
        throwHttpRequestError(err);
    }
};
export const throwHttpRequestError = (err) => {
    const error = JSON.parse(JSON.stringify(err));
    // TODO: old store throw the same error called "error", after removing the old store, this condition will be fixed
    if (error === "error" || !error.message) {
        const currentErrorMessage = "Network error";
        if (!sessionStorage.getItem("errorMessage"))
            sessionStorage.setItem("errorMessage", "");
        const previousErrorMessage = sessionStorage.getItem("errorMessage");
        if (previousErrorMessage !== currentErrorMessage) {
            sessionStorage.setItem("errorMessage", currentErrorMessage);
            message.error(currentErrorMessage);
        }
        message.error(error.message, error.status);
    }
    message.error(error.message, error.status);
};
