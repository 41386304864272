import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { SAButton, IconButton, Spin } from "src/components/base";
import { Upload } from "src/components/commonElement";
import { deleteImage } from "src/api/uploadImage";
import { Wrapper } from "./index.style";
const storagePath = "support/images";
export const ContactForm = (props) => {
    const [subject, setSubject] = useState("");
    const { userName = "", email = "" } = props;
    const [fileName, setFileName] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [message, setMessage] = useState("");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const onUploadFile = (uploadedFileUrl, uploadedFileName) => {
        setFileUrl(uploadedFileUrl);
        setFileName(uploadedFileName);
    };
    const doDeleteFile = () => {
        deleteImage(fileUrl);
        setFileName("");
    };
    const onSend = (event) => {
        event.preventDefault();
        const obj = {
            subject: subject,
            userName: userName,
            email: email,
            message: message,
            fileUrl: fileUrl,
            fileName: fileName,
        };
        props.onSend(obj);
    };
    return (_jsx(Wrapper, { children: _jsx(Spin, { spinning: props.loading, children: _jsxs("form", { action: "", onSubmit: onSend, children: [_jsx("p", { className: "title", style: { marginTop: "0px" }, children: "What can we help you with?" }), _jsx("input", { type: "text", className: "ct-input", placeholder: "Subject", value: subject, onChange: ({ target: { value } }) => setSubject(value), required: true }), _jsx("p", { className: "title", children: "Name" }), _jsx("input", { type: "text", className: "ct-input", defaultValue: userName, disabled: true, style: { cursor: "not-allowed" } }), _jsx("p", { className: "title", children: "Email" }), _jsx("input", { type: "text", className: "ct-input", defaultValue: email, disabled: true, style: { cursor: "not-allowed" } }), _jsx("p", { className: "title", children: "Message" }), _jsx("textarea", { className: "ct-textarea", value: message, onChange: ({ target: { value } }) => setMessage(value), required: true }), _jsx(Spin, { spinning: loadingUpload, children: _jsxs("div", { className: "ct-upload-file", children: [fileName.length <= 0 && (_jsx(Upload, { storagePath: storagePath, onChange: onUploadFile, loading: setLoadingUpload, children: _jsx(IconButton, { icon: "/assets/svg/ic-upload2.svg" }) })), fileName.length > 0 && (_jsx(IconButton, { icon: "/assets/svg/ic-delete2.svg", onClick: doDeleteFile })), fileName.length <= 0 && (_jsx("div", { className: "placeholder-upload", children: "Upload a Supporting File" })), fileName.length > 0 && _jsx("div", { className: "ct-file-name", children: fileName })] }) }), _jsx("div", { style: { textAlign: "right", marginTop: "20px" }, children: _jsx(SAButton, { className: "ct-send-btn", name: "Send", htmlType: "submit" }) })] }) }) }));
};
