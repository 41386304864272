import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import LocationIcon from "/src/assets/svg/collection-reports/location.svg";
import { useParams } from "react-router-dom";
const StatusCard = (props) => {
    const { tagName, status, mainHeading, subHeading, location, tagBg, time, onClick, componentId } = props;
    const { selectedId } = useParams();
    return (_jsxs(CardContainer, { onClick: onClick, selectedId: selectedId, componentId: componentId, children: [_jsx(TagName, { tagBg: tagBg, children: tagName }), _jsxs(MainContent, { children: [_jsx(MainHeading, { children: mainHeading }), _jsx(Divider, {}), _jsxs(SubContentWrapper, { children: [_jsx(SubHeading, { children: subHeading }), _jsxs(Typography.Text, { children: [location, " ", _jsx(LocationIcon, {})] })] }), _jsxs(SubContentWrapper, { children: [status, time] })] })] }));
};
export default StatusCard;
const CardContainer = styled.div `
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-block: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ selectedId, componentId }) => selectedId && componentId && selectedId === componentId ? "#E6F7FA" : "#ffffff"};
  &:hover {
    background-color: #edf3f5;
    cursor: pointer;
  }
`;
const TagName = styled.div `
  background-color: ${({ tagBg }) => tagBg ?? "#ffffff"};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.5rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  width: 3rem;
  font-weight: 700;
`;
const MainContent = styled(Col) `
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  justify-content: space-between;
  align-items: stretch;
`;
const MainHeading = styled.div `
  color: #000000;
  font-size: 0.875rem;
`;
const Divider = styled.div `
  border-bottom: 0.0625rem solid #d9f4f8;
`;
const SubHeading = styled.div `
  display: flex;
  width: 40%;
`;
const SubContentWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
