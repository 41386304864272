import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { updateSupplier } from "src/api/inventory/inventoryService";
import { Button, Col, Image, Row, Typography } from "src/components/base";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import { DropDownWrapper } from "../../index.style";
import { ContactsDetailsTable } from "./ContactDetailsTable";
import ICCancel from "/public/assets/svg/inventory/ic-cancel.svg";
import ICEdit from "/public/assets/svg/inventory/ic-edit.svg";
import ICSave from "/public/assets/svg/inventory/ic-save.svg";
const { Text } = Typography;
export const ContactDetails = ({ tableData, setTableData, supplierDelete, supplierArchive, getSupplierData, }) => {
    const [editMode, setEditMode] = useState(false);
    const [newData, setNewData] = useState([]);
    const userInfo = useSelector(checkAuth.dataSelector);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleEditClick = () => {
        setEditMode(!editMode);
    };
    const handleCancelClick = () => {
        setEditMode(false);
        if (newData.length > 0) {
            setNewData([]);
            setTableData(tableData.slice(0, -1)); // Remove the last row (new row)
        }
        else {
            setTableData(tableData);
        }
    };
    const handleSaveClick = async () => {
        setEditMode(false);
        openLoadingScreen();
        try {
            // Iterate over each item in combinedData
            const response = await updateSupplier(userInfo.accessToken, newData);
            if (response.status !== "success") {
                throw new Error("Failed to create inventory");
            }
            openToast("Saved successfully");
            getSupplierData();
            setEditMode(false);
        }
        catch (error) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again.");
            getSupplierData();
        }
        finally {
            closeLoadingScreen();
        }
    };
    const handleAddNew = () => {
        const newRow = {
            id: "",
            name: "",
            accountManager: "",
            contactMobile: "",
            email: "",
            address: "",
            contractFileUrl: "",
        };
        setTableData([...tableData, newRow]);
        setNewData([...newData, newRow]);
        setEditMode(true);
    };
    return (_jsx(_Fragment, { children: _jsxs(DropDownWrapper, { children: [_jsx("div", { style: { marginBottom: "10px", marginTop: "25px", cursor: "pointer" }, onClick: handleToggleCollapse, children: _jsxs(Row, { gutter: [20, 10], style: {
                            background: `${SAColourScheme.PLASMA}`,
                            padding: "10px",
                            alignItems: "center",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                        }, children: [_jsx(Col, { span: 1, children: _jsx("div", { style: {
                                        marginRight: "8px",
                                        transition: "transform 0.3s ease",
                                        color: "#FFFFFF",
                                    }, children: isCollapsed ? _jsx(RightOutlined, {}) : _jsx(DownOutlined, {}) }) }), _jsx(Col, { span: 1, children: _jsx(Image, { src: "/assets/svg/inventory/ic-contact-details.svg", preview: false }) }), _jsx(Col, { xs: 18, md: 21, children: _jsx(Text, { style: { color: `${SAColourScheme.WHITE}`, fontSize: "16px" }, children: "Inventory Supplier Contact Details" }) }), _jsx(Col, { span: 1 })] }) }), _jsxs("div", { className: `collapsible-div ${isCollapsed ? "collapsed" : ""}`, children: [_jsxs(Col, { span: 24, style: {
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }, children: [_jsx("span", { style: { fontSize: "16px", color: "#000000" }, children: "Current Supplier" }), _jsxs("div", { style: { display: "flex", alignItems: "flex-end" }, children: [_jsx("div", { style: { marginRight: "10px" }, children: editMode ? (_jsxs(_Fragment, { children: [_jsx(ICCancel, { onClick: handleCancelClick, style: { cursor: "pointer", marginRight: "10px" } }), _jsx(ICSave, { onClick: handleSaveClick, style: { cursor: "pointer" } })] })) : (_jsx(ICEdit, { onClick: handleEditClick, style: { cursor: "pointer" } })) }), _jsxs(Button, { onClick: handleAddNew, style: {
                                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                                borderRadius: "4px",
                                            }, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#1A8CFF" }, children: "Add New" })] })] })] }), _jsx(ContactsDetailsTable, { tabledata: tableData, setTableData: setTableData, editMode: editMode, setNewData: setNewData, supplierDelete: supplierDelete, supplierArchive: supplierArchive })] })] }) }));
};
