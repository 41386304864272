import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post, $put } from "src/infra/http";
const getListClients = createAsyncThunk("getListClients", async ({ newPage = 1, size = 10 } = {}, thunkApi) => {
    const state = thunkApi.getState().clientManagementPage;
    const clientType = state.clientDetailsPage.selectedClientType;
    const searchString = state.clientDetailsPage.searchString;
    const queryString = stringify({
        clientType,
        page: newPage,
        limit: size,
        searchString,
    });
    return await $get(`/collection-manager/client-management/list?${queryString}`);
});
const getListArchivedClients = createAsyncThunk("getListArchivedClients", async ({ newPage = 1, size = 10 } = {}, thunkApi) => {
    const state = thunkApi.getState().clientManagementPage;
    const clientType = state.clientDetailsPage.selectedClientType;
    const searchString = state.clientDetailsPage.searchString;
    const queryString = stringify({
        isArchived: true,
        clientType,
        page: newPage,
        limit: size,
        searchString,
    });
    return await $get(`/collection-manager/client-management/list?${queryString}`);
});
const archiveClient = createAsyncThunk("archiveClient", async (id) => await $get(`/collection-manager/client-management/client/archive/${id}`));
const restoreClient = createAsyncThunk("restoreClient", async (id) => await $get(`/collection-manager/client-management/client/restore/${id}`));
const getClientDetail = createAsyncThunk("getClientDetail", async (id) => await $get(`/collection-manager/client-management/client/${id}`));
const createClientDetails = createAsyncThunk("createClientDetails", async (data) => await $post("/collection-manager/client-management/client/create", data));
const updateClientDetails = createAsyncThunk("updateClientDetails", async (data) => await $put("/collection-manager/client-management/client/update", data));
export { getListClients, getListArchivedClients, archiveClient, restoreClient, getClientDetail, createClientDetails, updateClientDetails, };
