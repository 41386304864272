import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Dropdown, List, Menu, Popover, Space, Typography } from "antd";
import moment from "moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { OnsiteJobAllocationStatus, OnsiteJobStatus, OnsiteJobType, } from "src/pages/JobManagement/store/types";
import SAStatusButton from "src/components/Form/SAStatusButton";
import { mainAppColours } from "src/styles";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { useAppDispatch } from "src/store/hooks";
import { archiveJob, cancelJob, getListActiveJobs, restoreJob, } from "src/pages/JobManagement/store/action";
import { DataTable } from "src/components/commonElement/DataTable";
import { DisplayOnsiteJobAllocationStatus, DisplayedJobStatuses, OnsiteJobServiceTypeName, } from "src/pages/JobManagement/typing";
import { setIsLoading } from "src/store/app/slice";
import CloseButtonIcon from "src/assets/svg/job-management/close-button-icon.svg";
import ViewCollectorIcon from "src/assets/svg/job-management/view-collector-icon.svg";
import { getDate } from "src/utils/helper";
export const JobHistoryTable = ({ filteredClientsData }) => {
    const [open, setOpen] = useState(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const closePopover = () => {
        setOpen(null);
    };
    const handleOpenPopoverChange = (openId) => {
        setOpen(openId);
    };
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 70,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "startAt",
            key: "startAt",
            sorter: (a, b) => moment(getDate(a.startAt)).diff(moment(getDate(b.startAt))),
            render: (text, record) => {
                const { startAt } = record;
                return _jsx(Space, { children: moment(startAt).format("DD/MM/YYYY hh:mm a") });
            },
        },
        {
            title: "Authorised Rep",
            dataIndex: "representativeName",
            key: "representativeName",
            sorter: (a, b) => a.representativeName.localeCompare(b.representativeName),
        },
        {
            title: "Location",
            dataIndex: "jobLocation",
            key: "jobLocation",
            sorter: (a, b) => a.jobLocation.localeCompare(b.jobLocation),
        },
        {
            title: "Service",
            dataIndex: "serviceType",
            key: "serviceType",
            sorter: (a, b) => OnsiteJobServiceTypeName[a.serviceType].localeCompare(OnsiteJobServiceTypeName[b.serviceType]),
            render: (_, record) => (_jsx(Space, { children: OnsiteJobServiceTypeName[record.serviceType] })),
        },
        {
            title: "Call Out Job",
            dataIndex: "type",
            key: "type",
            align: "center",
            render: (type) => _jsx(Space, { children: type === OnsiteJobType.Callout ? "Yes" : "No" }),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_, record) => {
                const status = DisplayedJobStatuses[record.status];
                return _jsx(SAStatusButton, { color: status?.color, children: status?.label });
            },
        },
        {
            title: "Collector",
            dataIndex: "collectors",
            key: "collectors",
            align: "center",
            render: (_, record) => (_jsx(CustomPopover, { content: _jsx(CardContent, { title: _jsxs(CardHeader, { children: [_jsx(Typography.Title, { level: 5, children: "Collectors" }), _jsx(Button, { type: "text", icon: _jsx(CloseButtonIcon, {}), onClick: closePopover })] }), children: _jsx(List, { itemLayout: "horizontal", dataSource: record.collectors, renderItem: (collector, index) => {
                            const status = DisplayOnsiteJobAllocationStatus[collector.status];
                            return (_jsxs(List.Item, { children: [_jsx(List.Item.Meta, { title: _jsxs(CollectorNameText, { status: collector.status, children: [collector.firstName, " ", collector.lastName] }) }), _jsx(SAStatusButton, { color: status?.color, children: status?.label })] }, index));
                        } }) }), trigger: "click", placement: "bottomRight", visible: open === record.id, onVisibleChange: () => handleOpenPopoverChange(record.id), children: _jsx(Button, { type: "text", icon: _jsx(ViewCollectorIcon, {}) }) })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(StyledMenu, { children: [_jsx(StyledMenuItem, { onClick: () => {
                                navigate(`/job-management/onsite-jobs/${record.id}/${record.type === OnsiteJobType.Normal ? "normal" : "call-out"}`);
                            }, children: record.status === OnsiteJobStatus.Planned ||
                                record.status === OnsiteJobStatus.Draft
                                ? "Manage Job"
                                : "View Details" }, "ManageJob"), record.status === OnsiteJobStatus.Cancelled && (_jsx(StyledMenuItem, { onClick: async () => {
                                if (record.status === OnsiteJobStatus.Cancelled) {
                                    await dispatch(restoreJob(record.onsiteJobId));
                                    dispatch(setIsLoading(true));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsLoading(false));
                                }
                            }, children: _jsx(ArchiveSpan, { children: "Restore" }) }, "Restore")), record.status === OnsiteJobStatus.Cancelled ||
                            (record.status === OnsiteJobStatus.Draft && (_jsx(StyledMenuItem, { onClick: () => {
                                    record.status !== OnsiteJobStatus.Archived &&
                                        dispatch(archiveJob(record.onsiteJobId));
                                }, children: _jsx(ArchiveSpan, { children: "Delete" }) }, "Delete"))), record.status === OnsiteJobStatus.Planned && (_jsx(StyledMenuItem, { onClick: async () => {
                                if (record.status === OnsiteJobStatus.Planned) {
                                    await dispatch(cancelJob(record.onsiteJobId));
                                    dispatch(setIsLoading(true));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsLoading(false));
                                }
                            }, children: _jsx(ArchiveSpan, { children: "Cancel" }) }, "Cancel"))] }), trigger: ["click"], children: _jsx(ICMenuOptions, {}) })),
        },
    ];
    return (_jsx(React.Fragment, { children: _jsx(DataTable, { columns: columns, dataSource: filteredClientsData }) }));
};
const StyledMenu = styled(Menu) `
  border: 1px solid ${mainAppColours.MID_BLUE};
  width: 150px;
`;
const StyledMenuItem = styled(Menu.Item) `
  &:not(:first-child) {
    border-top: 1px solid #adb3bc;
    width: 80%;
    margin: 0 auto;
  }
`;
const ArchiveSpan = styled.span `
  display: flex;
  justify-content: flex-end;
`;
const CustomPopover = styled(Popover) `
  div.ant-popover-inner-content {
    padding: 0 !important;
    border: 0.1rem solid #1a8cff !important;
  }
`;
const CardContent = styled(Card) `
  width: 20rem;
  .ant-card-head {
    border-bottom: 0.2rem solid #01b4d2;
  }
`;
const CardHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    color: #00b4d1;
  }
`;
const CollectorNameText = styled(Typography.Text) `
  color: ${(props) => props.status === OnsiteJobAllocationStatus.Rejected ? "#FD0000" : "#000000"};
`;
