/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty, startsWith } from "lodash";
import { combineReducers } from "@reduxjs/toolkit";
import { filterSuccessDataAdapter } from "src/utils/api";
import { ApiActionType, } from "src/utils/apiRedux";
const REDUX_ACTION_TYPE_PREFIX = "API_REDUX_CALL";
const REDUCER_KEY = "API_REDUX_CALL";
const applyDispatchType = (dispatchType, state, newData, errorData, overrideStateFuncs = []) => {
    let newState = { ...state };
    switch (dispatchType) {
        case ApiActionType.FETCHING:
            newState = {
                ...state,
                fetching: true,
                error: false,
                data: newData,
            };
            break;
        case ApiActionType.SUCCESS:
            newState = {
                ...state,
                fetching: false,
                error: false,
                data: newData,
            };
            break;
        case ApiActionType.ERROR:
            newState = {
                ...state,
                fetching: false,
                error: errorData || true,
                data: newData,
            };
            break;
        case ApiActionType.RESET:
            newState = {
                ...state,
                fetching: false,
                error: false,
                data: newData,
            };
            break;
    }
    if (!isEmpty(overrideStateFuncs)) {
        overrideStateFuncs.forEach((callback) => {
            if (callback instanceof Function) {
                newState = callback(dispatchType, state, newState);
            }
        });
    }
    return newState;
};
const initState = {
    fetching: false,
    error: false,
    data: null,
};
const createApiReducer = ({ overrideStateFuncs = [] }) => (state = {}, action) => {
    if (!startsWith(action.type, REDUX_ACTION_TYPE_PREFIX)) {
        return { ...state };
    }
    const meta = action?.meta || {};
    const apiName = meta?.apiName;
    const dispatchType = meta?.type;
    const data = meta?.data;
    const errorData = meta?.errorData;
    if (isEmpty(apiName) || isEmpty(dispatchType))
        return { ...state };
    return {
        ...state,
        [apiName]: applyDispatchType(dispatchType, state?.[apiName] || initState, data, errorData, overrideStateFuncs),
    };
};
const getReducerObject = ({ overrideStateFuncs = [] }) => ({
    [REDUCER_KEY]: createApiReducer({
        overrideStateFuncs,
    }),
});
const additionalReducers = getReducerObject({ overrideStateFuncs: [filterSuccessDataAdapter] });
const reducers = {
    ...additionalReducers,
};
const oldReducers = combineReducers(reducers);
export default oldReducers;
