import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Divider, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { intersection, some } from "lodash";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import { calculateLastPeriod } from "src/utils/helper";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { InputWithLabel, TreeSelect } from "src/components/base";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { setIsLoading } from "src/store/app/slice";
import { getListCollectors, getListCurrentCollectors } from "../store/selector";
import { deleteCollector, getAllCollector, inviteCollector, resendInviteCollector, restoreCollector, suspendCollector, } from "../store/action";
import { setCurrentListCollector } from "../store/slice";
import InviteCollectorPage from "../components/InviteCollectorPage";
import { COLLECTOR_STATUS } from "../store/types";
const collectorStatusMapping = {
    1: { status: "Invited", color: "#1A8CFF" },
    2: { status: "Onboarded", color: "#5CD22B" },
    3: { status: "Suspended", color: "#FD0000" },
    4: { status: "Deleted", color: "grey" },
};
const headerRowStyle = {
    backgroundColor: "#E6F7FA",
    borderTop: "1px solid #ADB3BC",
    borderBottom: "2px solid #ADB3BC",
};
const CollectorsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { closePopupBox, openMessageBox, openPopupBox } = useContext(ContentContext);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const currentCollectors = useAppSelector(getListCurrentCollectors);
    const allListCollectors = useAppSelector(getListCollectors);
    const [collectionPoints, setCollectionPoints] = useState([]);
    const app = useAppSelector(getApplicationInformation);
    const [collectionPointTree, setCollectionPointTree] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (collectorId, event) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [collectorId]: event.currentTarget,
        }));
    };
    const handleMenuClose = (collectorId) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [collectorId]: null,
        }));
    };
    const handleDeleteAccount = async (collectorId) => {
        await dispatch(deleteCollector(collectorId));
        await dispatch(getAllCollector());
    };
    const handleSuspendAccount = async (collectorId) => {
        await dispatch(suspendCollector(collectorId));
        await dispatch(getAllCollector());
    };
    const handleRestoreAccount = async (collectorId) => {
        await dispatch(restoreCollector(collectorId));
        await dispatch(getAllCollector());
    };
    const handleOnSubmitInvitation = async (body) => {
        closePopupBox();
        await dispatch(inviteCollector(body));
        await dispatch(getAllCollector());
    };
    const handleResendInviteAccount = async (collectorId) => {
        dispatch(resendInviteCollector(collectorId));
    };
    useEffect(() => {
        const getCollectorsData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getAllCollector());
            dispatch(setIsLoading(false));
        };
        getCollectorsData();
    }, []);
    useEffect(() => {
        setCollectionPoints(app.collectionPoints);
        if (app.collectionOrganization.collectionName && app.collectionPoints?.length) {
            const creatingCollectionPointTree = [
                {
                    title: app.collectionOrganization.collectionName,
                    value: app.collectionOrganization.id,
                    children: app.collectionPoints.map((point) => ({
                        title: point.collectionPointName,
                        value: point.id,
                    })),
                },
            ];
            setCollectionPointTree(creatingCollectionPointTree);
        }
    }, [app]);
    const findCollectionPointName = (collectionPointId) => {
        const point = collectionPoints.find((currentPoint) => currentPoint.id === collectionPointId);
        return point ? point.collectionPointName : "N/A";
    };
    const handleLocationChange = (data) => {
        if (data.value?.length === app.collectionPoints.length) {
            dispatch(setCurrentListCollector(allListCollectors));
        }
        else {
            const newListCollectors = allListCollectors.filter((collector) => some(intersection(collector.collectionPoints, data.value)));
            dispatch(setCurrentListCollector(newListCollectors));
        }
    };
    return (_jsxs("div", { style: { backgroundColor: "white", minHeight: "79vh" }, children: [isLoading && _jsx(OverlayLoading, {}), _jsxs("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }, children: [_jsxs("div", { style: {
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                        }, children: [_jsxs("div", { style: { display: "flex", gap: "15px", alignItems: "center" }, children: [_jsx("img", { style: { height: "30px" }, src: "/assets/svg/ic-collectors.svg", alt: "ic-collectors" }), _jsx(Typography, { children: "Collectors" })] }), _jsxs("div", { style: {
                                    display: "flex",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }, children: [_jsx(Typography, { children: "Show" }), _jsx("div", { style: {
                                            width: "240px",
                                        }, children: _jsx(InputWithLabel, { theme: "3", field: "collectionPointIds", onInputChange: handleLocationChange, inputProps: {
                                                type: "treeselect",
                                                options: collectionPointTree,
                                                treeCheckable: true,
                                                showCheckedStrategy: TreeSelect.SHOW_CHILD,
                                                treeLine: {},
                                                treeDefaultExpandAll: true,
                                                placeholder: "All",
                                                // disabled: !isEdit,
                                            } }) }), _jsx(Button, { startIcon: _jsx(AccountCircleIcon, { color: "primary" }), variant: "outlined", size: "small", onClick: () => openPopupBox(_jsx(InviteCollectorPage, { options: collectionPointTree, OnSubmitInvitation: handleOnSubmitInvitation }), null, {
                                            popupStyle: {
                                                width: "25%",
                                                borderRadius: "16px",
                                                border: `2px solid ${mainAppColours.MID_BLUE}`,
                                            },
                                            // closeIcon: <Image src="/assets/svg/ic-close.svg" preview={false} />,
                                        }), style: {
                                            textTransform: "none",
                                            padding: "10px",
                                            fontWeight: "bolder",
                                            color: "#1A8CFF",
                                            border: "#1A8CFF solid 3px",
                                            width: "120px",
                                            marginLeft: "20px",
                                        }, children: "Invite" })] })] }), _jsx(TableContainer, { style: {
                            maxHeight: "100vh",
                            width: "100%",
                            backgroundColor: "white",
                        }, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Collector" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Email" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Mobile" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Invitation Code" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Work Place" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Status" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Last Active" }) }), _jsx(TableCell, { style: headerRowStyle })] }) }), _jsx(TableBody, { children: currentCollectors.map((collector) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: collector.collectorName }), _jsx(TableCell, { children: collector.email }), _jsx(TableCell, { children: collector.mobile }), _jsx(TableCell, { children: collector.invitationCode }), _jsx(TableCell, { children: collector.collectionPoints.length <= 2
                                                    ? collector.collectionPoints
                                                        .map((collectionPointId) => findCollectionPointName(collectionPointId))
                                                        .join(", ")
                                                    : collector.collectionPoints
                                                        .slice(0, 2)
                                                        .map((collectionPointId) => findCollectionPointName(collectionPointId))
                                                        .join(", ") + " ..." }), _jsx(TableCell, { children: _jsx(Chip, { style: {
                                                        backgroundColor: collectorStatusMapping[collector.collectorStatus].color,
                                                        color: "white",
                                                        width: "100px",
                                                    }, label: collectorStatusMapping[collector.collectorStatus].status }) }), _jsx(TableCell, { children: collector.lastActive ? calculateLastPeriod(collector.lastActive) : "-" }), _jsxs(TableCell, { children: [_jsx(IconButton, { onClick: (event) => handleMenuOpen(collector.id, event), children: _jsx(MoreHorizIcon, {}) }), anchorEl && (_jsx(Menu, { anchorEl: anchorEl[collector.id], open: Boolean(anchorEl[collector.id]), onClose: () => handleMenuClose(collector.id), children: _jsxs("div", { style: {
                                                                width: "200px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }, children: [_jsx(MenuItem, { onClick: () => {
                                                                        handleMenuClose(collector.id);
                                                                        navigate(`${location.pathname}/${collector.id}/profile`);
                                                                    }, children: "View Details" }), collector.collectorStatus === COLLECTOR_STATUS.ONBOARDED && (_jsx(MenuItem, { onClick: () => handleSuspendAccount(collector.id), children: "Suspend" })), collector.collectorStatus === COLLECTOR_STATUS.SUSPENDED && (_jsx(MenuItem, { onClick: () => handleRestoreAccount(collector.id), children: "Restore" })), collector.collectorStatus === COLLECTOR_STATUS.INVITED && (_jsx(MenuItem, { onClick: () => handleResendInviteAccount(collector.id), children: "Resend Invite" })), _jsx(Divider, { style: { margin: "5px 20px" } }), _jsx(MenuItem, { style: { alignSelf: "flex-end" }, onClick: () => {
                                                                        handleMenuClose(collector.id);
                                                                        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, _jsxs(React.Fragment, { children: [_jsx(Typography, { variant: "h4", children: "Are you sure?" }), _jsxs(Typography, { children: [_jsx("b", { children: collector.collectorName }), " will be deleted and no longer have access to."] })] }), () => {
                                                                            handleDeleteAccount(collector.id);
                                                                        });
                                                                    }, children: "Delete" })] }) }))] })] }, collector.id))) })] }) })] })] }));
};
export default CollectorsPage;
