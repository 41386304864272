import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Divider, Spin } from "src/components/base";
import { LeftRowData, LeftRowTitle, MiddleRowData, RightRowData, RowTotal, } from "src/pages/DashboardPage/components/Common";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { TestsProcessedWrapper } from "src/pages/DashboardPage/components/TestsProcessed/index.style";
import { checkAuth } from "src/api/auth/login";
import { getOralFluidLeftRowData, getOralFluidMiddleRowData, getOralFluidRightRowData, } from "src/api/dashboard/dashboardService";
export const OralFluidDrugTest = ({ collectionPoints }) => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // Left Data
    const [leftSentToLabData, setleftSentToLabData] = useState({});
    // Middle Data
    const [middleSentToLabData, setMiddleSentToLabData] = useState({});
    // Right Data
    const [rightSentToLabData, setRightSentToLabData] = useState({});
    // Left protocalData
    const [leftRefusalData, setLeftRefusalData] = useState({});
    // Middle protocalData
    const [middleRefusalData, setMiddleRefusalData] = useState({});
    // Right protocalData
    const [rightRefusalData, setRightRefusalData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getOralFluidLeftRowData(location, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setleftSentToLabData(result?.data?.sentToLab);
            setLeftRefusalData(result?.data?.sampleRefusal);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getOralFluidMiddleRowData(location, isMonthView, selectYear, userInfo.accessToken);
        if (result.status === "success") {
            setMiddleSentToLabData(result?.data?.sentToLab);
            setMiddleRefusalData(result?.data?.sampleRefusal);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getOralFluidRightRowData(location, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setRightSentToLabData(result?.data?.sentToLab);
            setRightRefusalData(result?.data?.sampleRefusal);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsx(TestsProcessedWrapper, { children: _jsxs(HeaderTable, { title: "Oral Fluid Drug Test Results (Laboratory Test)", collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-test-result.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "40vw",
                        } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { title: "Results", children: [_jsx(LeftRowTitle, { title: "Sent to Lab (RFT)", highlight: true }), _jsx(LeftRowData, { title: "Sample 1", data: leftSentToLabData["sample1"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Sample 2", data: leftSentToLabData["sample2"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Sample 3", data: leftSentToLabData["sample3"] || [0, 0, 0, 0] }), _jsx(RowTotal, { style: { marginTop: 7 }, title: "Total", data: leftSentToLabData["sample1"]?.map((_, index) => [
                                        leftSentToLabData["sample1"][index] || 0,
                                        leftSentToLabData["sample2"][index] || 0,
                                        leftSentToLabData["sample3"][index] || 0,
                                    ].reduce((acc, val) => acc + val, 0)) }), _jsx(Divider, {}), _jsx(LeftRowTitle, { title: "Refusal", highlight: true }), _jsx(LeftRowData, { title: "Sample 1", data: leftRefusalData["sample1"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Sample 2", data: leftRefusalData["sample2"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Sample 3", data: leftRefusalData["sample3"] || [0, 0, 0, 0] }), _jsx(RowTotal, { style: { marginTop: 7 }, title: "Total", data: leftRefusalData["sample1"]?.map((_, index) => [
                                        leftRefusalData["sample1"][index] || 0,
                                        leftRefusalData["sample2"][index] || 0,
                                        leftRefusalData["sample3"][index] || 0,
                                    ].reduce((acc, val) => acc + val, 0)) })] }), _jsxs(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: [_jsx("div", { style: { height: 33 } }), Object.entries(middleSentToLabData)?.map(([month, data]) => (_jsx(React.Fragment, { children: _jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(data) }, month) }, month))), _jsx(MiddleRowData, { startIndex: startIndex, cellStyle: { background: "#FAFAFA" }, style: { marginTop: 7 }, data: Array.from({ length: 12 }, (_, monthIndex) => Object.values(middleSentToLabData)?.reduce((acc, monthlyCounts) => acc + monthlyCounts[Object.keys(monthlyCounts)[monthIndex]], 0)) }), _jsx(Divider, {}), _jsx("div", { style: { height: 33 } }), Object.entries(middleRefusalData)?.map(([collcetor, monthlyCounts]) => (_jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(monthlyCounts) }, collcetor))), _jsx(MiddleRowData, { startIndex: startIndex, cellStyle: { background: "#FAFAFA" }, style: { marginTop: 7 }, data: Array.from({ length: 12 }, (_, monthIndex) => Object.values(middleRefusalData)?.reduce((acc, monthlyCounts) => acc + monthlyCounts[Object.keys(monthlyCounts)[monthIndex]], 0)) })] }), _jsxs(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: [_jsx("div", { style: { height: 33 } }), _jsx(RightRowData, { value: rightSentToLabData["sample1"] || 0 }), _jsx(RightRowData, { value: rightSentToLabData["sample2"] || 0 }), _jsx(RightRowData, { value: rightSentToLabData["sample3"] || 0 }), _jsx(RightRowData, { value: [
                                        rightSentToLabData["sample1"],
                                        rightSentToLabData["sample2"],
                                        rightSentToLabData["sample3"],
                                    ].reduce((acc, curr) => acc + curr, 0) || 0 }), _jsx(Divider, {}), _jsx("div", { style: { height: 33 } }), _jsx(RightRowData, { value: rightRefusalData["sample1"] || 0 }), _jsx(RightRowData, { value: rightRefusalData["sample2"] || 0 }), _jsx(RightRowData, { value: rightRefusalData["sample3"] || 0 }), _jsx(RightRowData, { value: [
                                        rightRefusalData["sample1"],
                                        rightRefusalData["sample2"],
                                        rightRefusalData["sample3"],
                                    ].reduce((acc, curr) => acc + curr, 0) || 0 })] })] })] }) }));
};
