import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, getIdTokenResult } from "firebase/auth";
import moment from "moment";
import { env } from "src/constants";
import { app } from "src/firebase";
import { $get } from "src/infra/http";
const auth = getAuth(app);
const tenantId = env.TENANT_ID;
if (tenantId) {
    auth.tenantId = tenantId;
}
const checkAuthentication = createAsyncThunk("checkAuthentication", async () => {
    const response = await new Promise((resolve, reject) => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                let tokenResult = await getIdTokenResult(user);
                if (moment(tokenResult.expirationTime).isSameOrBefore(moment())) {
                    tokenResult = await getIdTokenResult(user, true);
                }
                return resolve({ data: tokenResult.claims });
            }
            return reject({ error: "error", message: new Error("User is not logged in") });
        }, (error) => reject({ error: "error", message: error }));
    });
    return response;
});
const getAppInformation = createAsyncThunk("getAppInformation", async () => await $get("/collection-manager/home/app"));
export { checkAuthentication, getAppInformation };
