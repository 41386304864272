import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { useSelector } from "react-redux";
import { Divider, Spin } from "src/components/base";
import { LeftRowData, LeftRowTitle, MiddleRowData, RightRowData, } from "src/pages/DashboardPage/components/Common";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { TestsProcessedWrapper } from "src/pages/DashboardPage/components/TestsProcessed/index.style";
import { checkAuth } from "src/api/auth/login";
import { getPatientTimeLeftRowData, getPatientTimeMiddleRowData, getPatientTimeRightRowData, } from "src/api/dashboard/dashboardService";
export const PatientTime = ({ collectionPoints, collectorsData }) => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [location, setLocation] = useState("All");
    const [collector, setCollector] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [collectorsList, setCollectorsList] = useState([]);
    useEffect(() => {
        if (collectorsData && collectorsData.length) {
            setCollectorsList(collectorsData.map((item) => ({
                label: item.name,
                value: item.id,
            })));
        }
    }, [collectorsData]);
    // Left Data
    const [leftPatientTimeData, setleftPatientTimeData] = useState({});
    // Middle Data
    const [middlePatientTimeData, setMiddlePatientTimeLabData] = useState({});
    // Right Data
    const [rightPatientTimeData, setRightPatientTimeData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getPatientTimeLeftRowData(location, collector, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setleftPatientTimeData(result?.data);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getPatientTimeMiddleRowData(location, collector, isMonthView, selectYear, userInfo.accessToken);
        if (result.status === "success") {
            setMiddlePatientTimeLabData(result?.data);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getPatientTimeRightRowData(location, collector, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setRightPatientTimeData(result?.data);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location, collector]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsx(TestsProcessedWrapper, { children: _jsxs(HeaderTable, { title: "Patient Time", collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-time.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "40vw",
                        } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { title: "Test Time", children: [_jsxs("div", { style: {
                                        width: "100%",
                                        margin: "-20px 0px 10px 0px",
                                        padding: "10px",
                                        background: "#E6F7FA",
                                    }, children: [_jsx(Text, { style: { fontSize: 14, marginRight: 10, fontWeight: "700" }, children: "Collector" }), _jsx(Select, { defaultValue: "All", style: { width: "85%" }, size: "small", options: [
                                                {
                                                    label: "All",
                                                    value: "All",
                                                },
                                                ...collectorsList,
                                            ], onChange: (value) => setCollector(value) })] }), Object.entries(leftPatientTimeData)?.map(([test, data], index) => (_jsxs("div", { style: { position: "relative" }, children: [_jsx(LeftRowTitle, { title: test, highlight: true }), _jsx("span", { style: { position: "absolute", top: "0px", right: "0%" }, children: "Minutes" }), _jsx(LeftRowData, { title: "Average", data: data["average"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Shortest", data: data["shortest"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Longest", data: data["longest"] || [0, 0, 0, 0] }), _jsx(Divider, {})] }, index)))] }), _jsxs(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: [_jsx("div", { style: { height: 34 } }), _jsx(React.Fragment, { children: Object.keys(middlePatientTimeData).map((month) => (_jsxs("div", { children: [_jsx("div", { style: { height: 33 } }), _jsx(MiddleRowData, { startIndex: startIndex, data: Object.keys(middlePatientTimeData[month]).map((test) => middlePatientTimeData[month][test].average) }, `${month}-average`), _jsx(MiddleRowData, { startIndex: startIndex, data: Object.keys(middlePatientTimeData[month]).map((test) => middlePatientTimeData[month][test].shortest) }, `${month}-shortest`), _jsx(MiddleRowData, { startIndex: startIndex, data: Object.keys(middlePatientTimeData[month]).map((test) => middlePatientTimeData[month][test].longest) }, `${month}-longest`), _jsx(Divider, {})] }, month))) })] }), _jsxs(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: [_jsx("div", { style: { height: 34 } }), Object.entries(rightPatientTimeData)?.map(([_, data]) => (_jsxs(_Fragment, { children: [_jsx("div", { style: { height: 33 } }), _jsx(RightRowData, { value: data["average"] || 0 }), _jsx(RightRowData, { value: data["shortest"] || 0 }), _jsx(RightRowData, { value: data["longest"] || 0 }), _jsx(Divider, {})] })))] })] })] }) }));
};
