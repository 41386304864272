var UploadPrefix;
(function (UploadPrefix) {
    UploadPrefix["Avatar"] = "avatar";
    UploadPrefix["Image"] = "img";
    UploadPrefix["Background"] = "background";
    UploadPrefix["Document"] = "doc";
})(UploadPrefix || (UploadPrefix = {}));
var UploadFolder;
(function (UploadFolder) {
    UploadFolder["UserProfile"] = "user-profile";
})(UploadFolder || (UploadFolder = {}));
export { UploadPrefix, UploadFolder };
