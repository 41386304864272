var Environment;
(function (Environment) {
    Environment["sandbox"] = "sandbox";
    Environment["local"] = "develop";
})(Environment || (Environment = {}));
var Domain;
(function (Domain) {
    Domain["Main"] = "main";
    Domain["Upload"] = "upload";
})(Domain || (Domain = {}));
const DOMAIN_CONFIG = {
    [Domain.Main]: process.env.DOMAIN || "",
    [Domain.Upload]: "/sa/upload",
};
export { Environment, Domain, DOMAIN_CONFIG };
