import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from "react";
import PinInput from "react-pin-input";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { checkAuth } from "src/api/auth/login";
import { updateSignature, verifyPin } from "src/api/authority/authorityService";
import { deleteImage, uploadImage } from "src/api/uploadImage";
import { Button, Image, Typography, Upload } from "src/components/base";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { mainAppColours, MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
import { supportingColours } from "src/styles";
import DeleteIcon from "src/assets/svg/authorising-authority/ic-signature-vault-delete.svg";
import UploadIcon from "src/assets/svg/authorising-authority/ic-signature-vault-upload.svg";
import CloseIcon from "src/assets/svg/authorising-authority/ic-close.svg";
import { useAppSelector } from "src/store/hooks";
import { selectAuthorityInformation } from "../store/selector";
import { SetUpPin } from "./SetUpPin";
export const SignatureVault = () => {
    const pinRef = useRef(null);
    const [isLocked, setIsLocked] = useState(true);
    const [isPinDisabled, setIsPinDisabled] = useState(false);
    const [lockTimeout, setLockTimeout] = useState(null);
    const [file, setFile] = useState();
    const [signature, setSignature] = useState("");
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const { openPopupBox, openMessageBox, openToast } = useContext(ContentContext);
    const authorityInformation = useAppSelector(selectAuthorityInformation);
    const { isCreatedPin } = authorityInformation.data;
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (upFile) => {
        setFile(upFile);
        return false;
    };
    const onChange = () => {
        if (file) {
            getBase64(file, async (imgUrl) => {
                const imgEl = document.createElement("img");
                imgEl.src = imgUrl;
                imgEl.addEventListener("load", async () => {
                    const fileSizeInMB = file.size / (1024 * 1024);
                    if (fileSizeInMB > 2) {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Maximum upload file size: \b{2 MB}\nSupported formats: \b{PNG, JPG, GIF, BMP.}`);
                        return;
                    }
                    openLoadingScreen();
                    if (signature) {
                        await deleteImage(signature);
                        setSignature(null);
                    }
                    const url = await uploadImage("signature", imgUrl, file.type.split("/")[1]);
                    const result = await updateSignature(userInfo.accessToken, url);
                    if (result.status === "success") {
                        setSignature(url);
                        openToast("Uploaded successfully");
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        setSignature(null);
                    }
                    closeLoadingScreen();
                });
            });
        }
    };
    const onDelete = async () => {
        openLoadingScreen();
        if (signature) {
            await deleteImage(signature);
            setSignature(null);
        }
        const result = await updateSignature(userInfo.accessToken, "");
        if (result.status === "success") {
            openToast("Deleted successfully");
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
        }
        closeLoadingScreen();
    };
    useEffect(() => {
        if (lockTimeout) {
            clearTimeout(lockTimeout);
        }
        setLockTimeout(setTimeout(() => {
            setIsLocked(true);
        }, 1000 * 30 * 5));
        return () => {
            clearTimeout(lockTimeout);
        };
    }, [isLocked]);
    const openSetUpPin = (isCreatingPin) => {
        openPopupBox(_jsx(SetUpPin, { isCreatedPin: isCreatingPin }), null, {
            popupStyle: {
                width: "50%",
                borderRadius: "16px",
                border: `2px solid ${mainAppColours.MID_BLUE}`,
            },
            closeIcon: _jsx(Image, { src: "/assets/svg/ic-close.svg", preview: false }),
        });
    };
    const checkPin = async (pin) => {
        const result = await verifyPin(userInfo.accessToken, pin);
        setSignature(result.data.signature);
        setIsPinDisabled(true);
        const timeout = setTimeout(() => {
            setIsPinDisabled(false);
            if (result.status == "success") {
                setIsLocked(false);
            }
            else {
                openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Your PIN has not been created or is incorrect`);
                pinRef?.current?.clear();
            }
            clearTimeout(timeout);
        }, 1000);
    };
    return (_jsx(Container, { children: isLocked ? (_jsx(LockedContainer, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 4, children: _jsx(BackgroundImage, {}) }), _jsx(SAColumn, { span: 20, children: isCreatedPin ? (_jsxs(PinFormContainer, { children: [_jsxs(TextContainer, { children: [_jsx(GuideText, { children: "Please enter your PIN" }), _jsx(CaptionGuideText, { children: "or" }), _jsx(StyledButton, { onClick: () => openSetUpPin(isCreatedPin), children: "Update" })] }), _jsx(PinInputStyled, { ref: pinRef, disabled: isPinDisabled, length: 6, initialValue: "", type: "numeric", inputMode: "number", autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/, onComplete: (value) => checkPin(value), inputStyle: {
                                        color: `${SAColourScheme.BLACK}`,
                                        backgroundColor: isPinDisabled
                                            ? `${supportingColours.BOX_GRAY}`
                                            : `${SAColourScheme.WHITE}`,
                                        width: "2rem",
                                        height: "2rem",
                                        borderRadius: "4px",
                                        border: `none`,
                                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                                    }, inputFocusStyle: {
                                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                                    } })] })) : (_jsx(PinFormContainer, { children: _jsxs(TextContainer, { children: [_jsx(GuideText, { children: "Please create your PIN" }), _jsx(StyledButton, { onClick: () => openSetUpPin(isCreatedPin), children: "Create" })] }) })) })] }) })) : (_jsx(UploadContainer, { children: _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { span: 4, children: _jsxs(UploadButtonWrapper, { children: [_jsx(DeleteButton, { onClick: () => {
                                        if (!signature) {
                                            return;
                                        }
                                        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Do you want to delete signature?", onDelete);
                                    }, icon: _jsx(DeleteIcon, {}), children: "Delete" }), _jsx(DeleteButton, { children: _jsxs(UploadArea, { showUploadList: false, onChange: onChange, beforeUpload: beforeUpload, accept: "image/*", children: [_jsx(UploadIcon, {}), _jsx(Typography.Text, { children: "Upload" })] }) })] }) }), _jsx(SAColumn, { span: 18, children: _jsx(SignatureImageContainer, { children: _jsx(SignatureImage, { src: signature ?? "", preview: false, alt: "Signature", fallback: "/assets/images/signature-placeholder.png" }) }) }), _jsx(SAColumn, { span: 2, children: _jsx(CloseButton, { onClick: () => setIsLocked(true), icon: _jsx(CloseIcon, {}) }) })] }) })) }));
};
const Container = styled.div `
  display: flex;
  background-color: ${SAColourScheme.WHITE};
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  height: 10rem;
`;
const LockedContainer = styled.div `
  display: flex;
  flex-direction: row;
  background-color: #243e84;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  margin: 0.3125rem;
  width: 100%;
  padding: 1rem;
`;
const BackgroundImage = styled.div `
  background-image: url("/assets/svg/img-signature-vault.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
const PinFormContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const TextContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.0625rem solid ${SAColourScheme.WHITE};
`;
const GuideText = styled.p `
  font-size: 1rem;
  color: #ffffff;
`;
const CaptionGuideText = styled.p `
  font-size: 0.875rem;
  color: #ffffff;
`;
const UploadContainer = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const StyledButton = styled(Button) `
  margin-bottom: 0.625rem;
  border: 0.0625rem solid #bdc5da;
  background-color: transparent;
  font-size: 0.75rem;
  color: #bdc5da;
`;
const PinInputStyled = styled(PinInput) `
  padding-top: 0.5rem;
`;
const CloseButton = styled(Button) `
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  cursor: pointer;
  border-radius: 1rem;
  border: 0;
`;
const DeleteButton = styled(Button) `
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  width: 100%;
  border-bottom: 0.1rem solid #ebedf0;
`;
const UploadArea = styled(Upload) `
  cursor: pointer;
  height: 50%;
  .ant-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const UploadButtonWrapper = styled.div `
  height: 100%;
  width: 100%;
  border-right: 0.1rem solid #ebedf0;
`;
const SignatureImageContainer = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SignatureImage = styled(Image) `
  max-height: 10rem;
  max-width: 15rem;
  object-fit: cover;
  margin: 0 auto;
  padding: 2rem;
`;
