import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { env } from "src/constants";
import { app } from "src/firebase";
const db = getFirestore(app);
const collectionCentreDatabase = `${env.DATA_COLLECTION}/collectionOrganization`;
const collectionPointDatabase = `${env.DATA_COLLECTION}/collectionPoints`;
export const getById = async (id) => {
    const queryCondition = query(collection(db, collectionCentreDatabase), where("id", "==", id));
    const querySnapshot = await getDocs(queryCondition);
    return querySnapshot.docs.map((snapDoc) => snapDoc.data());
};
export const getCollectionPointByCentreId = async (collectionOrganizationId) => {
    const queryCondition = query(collection(db, collectionPointDatabase), where("collectionOrganizationId", "==", collectionOrganizationId));
    const querySnapshot = await getDocs(queryCondition);
    return querySnapshot.docs.map((snapDoc) => snapDoc.data());
};
