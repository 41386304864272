import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, DatePicker, Dropdown, Form, Image, Input, Menu, Modal, Row, Switch, TreeSelect, } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { createTestingDevice, restoreTestingDevice, updateTestingDevice, } from "src/api/testingDevices/testingDeviceService";
import { InputWithLabel } from "src/components/base";
import { MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
import { MainWrapper, NewDeviceWrapper } from "../index.style";
import { FileUpload } from "./FileUpload";
export const NewUrine = ({ record, collectionsPointOptions }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const userInfo = useSelector(checkAuth.dataSelector);
    const [initialData, setInitialData] = useState([]);
    const [expiryDateReminder, setExpiryDateReminder] = useState(true);
    const [isInUse, setIsInUse] = useState(false);
    const [componentSize, setComponentSize] = useState("default");
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [newRecord, setNewRecord] = useState(false);
    const [clickedFieldName, setClickedFieldName] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const [previewVisible, setPreviewVisible] = useState(false);
    const handleViewClick = (fieldName) => {
        setClickedFieldName(fieldName);
        setPreviewVisible(true);
    };
    const handleCancel = () => {
        setPreviewVisible(false);
        setClickedFieldName(null);
    };
    const restoreTD = async (id) => {
        openLoadingScreen();
        const response = await restoreTestingDevice(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Restored successfully");
            setFormDisabled(false);
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const showDrawer = () => {
        setDrawerVisible(true);
    };
    const onCloseDrawer = () => {
        setDrawerVisible(false);
    };
    const handleClick = (fieldName) => {
        setClickedFieldName(fieldName);
    };
    const getMenu = () => (_jsxs(Menu, { style: { padding: "10px 10px" }, children: [_jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, disabled: !!(clickedFieldName && form.getFieldValue(clickedFieldName)), onClick: () => {
                    showDrawer();
                }, children: "Upload File" }, "upload"), _jsx(FileUpload, { onCloseDrawer: onCloseDrawer, onFileUploaded: (url) => {
                    if (clickedFieldName) {
                        form.setFieldsValue({ [clickedFieldName]: url });
                    }
                }, visible: drawerVisible }), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => clickedFieldName && handleViewClick(clickedFieldName), children: "View File" }, "view"), _jsx(Modal, { visible: previewVisible, onCancel: handleCancel, footer: null, width: 800, children: clickedFieldName && (_jsx("iframe", { title: "File Preview", src: form.getFieldValue(clickedFieldName), style: { width: "100%", height: "80vh", padding: "10px" } })) }), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => {
                    onDelete();
                }, children: "Delete File" }, "delete")] }));
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };
    const handleInUseSwitchChange = () => {
        setIsInUse(!isInUse);
    };
    const handleExpiryDateSwitchChange = () => {
        setExpiryDateReminder((prevReminder) => !prevReminder);
        form.setFieldsValue({
            reminderAt: undefined,
        });
    };
    const onDelete = async () => {
        openLoadingScreen();
        if (clickedFieldName) {
            const fieldValue = form.getFieldValue(clickedFieldName);
            if (fieldValue && typeof fieldValue === "string" && fieldValue.startsWith("http")) {
                // await deleteImage(fieldValue);
                form.setFieldsValue({ [clickedFieldName]: "" });
            }
        }
        closeLoadingScreen();
    };
    const saveTD = async () => {
        form.validateFields().then(async (values) => {
            const combinedSideImageUrls = [
                values["sideImageUrls0"],
                values["sideImageUrls1"],
                values["sideImageUrls2"],
            ].filter(Boolean);
            if (newRecord) {
                values = {
                    ...values,
                    expireAt: values.expireAt.toDate(),
                    reminderAt: values?.reminderAt?.toDate(),
                    deviceTypeId: record,
                    isInUse: isInUse ? isInUse : false,
                    isArchived: false,
                    sideImageUrls: combinedSideImageUrls,
                };
            }
            else {
                values = {
                    ...values,
                    expireAt: values.expireAt.toDate(),
                    reminderAt: values.reminderAt && values.reminderAt?.toDate(),
                    isArchived: initialData.isArchived,
                    deviceTypeId: initialData.deviceTypeId,
                    testTypeId: initialData.testTypeId,
                    sideImageUrls: combinedSideImageUrls,
                };
            }
            const isFormDirty = Object.keys(values).some((field) => {
                // If the field is an array, perform a granular comparison
                if (Array.isArray(values[field]) && Array.isArray(initialData[field])) {
                    return !arraysEqual(values[field], initialData[field]);
                }
                else {
                    return values[field] !== initialData[field];
                }
            });
            if (isFormDirty) {
                const newData = {
                    ...values,
                    id: record?.record?.id || undefined,
                };
                if (newData.id === undefined) {
                    openLoadingScreen();
                    const response = await createTestingDevice(userInfo.accessToken, newData);
                    if (response.status === "success") {
                        openToast("Created successfully");
                        closeLoadingScreen();
                        navigate(-1);
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        closeLoadingScreen();
                    }
                }
                else {
                    openLoadingScreen();
                    const response = await updateTestingDevice(userInfo.accessToken, newData.id, newData);
                    if (response.status === "success") {
                        openToast("Updated successfully");
                        closeLoadingScreen();
                        navigate(-1);
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        closeLoadingScreen();
                    }
                }
            }
            else {
                navigate(-1);
            }
        });
    };
    function arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (!objectsEqual(arr1[i], arr2[i])) {
                return false;
            }
        }
        return true;
    }
    function objectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }
    useEffect(() => {
        if (record !== null && typeof record !== "string") {
            setNewRecord(false);
            const mappedRecord = {
                artgNumber: record.record.artgNumber,
                certificateNumber: record.record.certificateNumber,
                id: record.record.id,
                deviceName: record.record.deviceName,
                deviceTypeId: record.record.deviceTypeId,
                artgSummarySheetUrl: record.record.artgSummarySheetUrl,
                calibrationAt: record.record.calibrationAt ? moment(record.record.calibrationAt) : null,
                calibrationCertificateUrl: record.record.calibrationCertificateUrl,
                calibrationReportUrl: record.record.calibrationReportUrl,
                collectionPointIds: record.record.collectionPointIds,
                deviceManualUrl: record.record.deviceManualUrl,
                expireAt: record.record.expireAt ? moment(record.record.expireAt) : undefined,
                deviceCommissioningDate: record.record.deviceCommissioningDate
                    ? moment(record.record.deviceCommissioningDate)
                    : null,
                fontImageUrl: record.record.fontImageUrl,
                instructionUrl: record.record.instructionUrl,
                isArchived: record.record.isArchived,
                isInUse: record.record.isInUse,
                isReminderExpire: record.record.isReminderExpire,
                laboratorySheetUrl: record.record.laboratorySheetUrl,
                lotNumber: record.record.lotNumber,
                manufacturer: record.record.manufacturer,
                measurementUnit: record.record.measurementUnit,
                reminderAt: record.record.reminderAt ? moment(record.record.reminderAt) : null,
                serialNumber: record.record.serialNumber,
                sideImageUrls: record.record.sideImageUrls,
                sideImageUrls0: record.record.sideImageUrls[0],
                sideImageUrls1: record.record.sideImageUrls[1],
                sideImageUrls2: record.record.sideImageUrls[2],
                specificationSheetUrl: record.record.specificationSheetUrl,
                sponsor: record.record.sponsor,
                testTypeId: record.record.testTypeId,
            };
            form.setFieldsValue(mappedRecord);
            setInitialData(mappedRecord);
            setIsInUse(mappedRecord.isInUse);
            setExpiryDateReminder(!mappedRecord.isReminderExpire);
            setFormDisabled(mappedRecord.isArchived);
        }
        else {
            setNewRecord(true);
        }
    }, [record, form]);
    return (_jsx(MainWrapper, { children: _jsx(NewDeviceWrapper, { children: _jsxs(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 54 }, initialValues: { size: componentSize }, onValuesChange: onFormLayoutChange, size: componentSize, children: [_jsxs(Row, { children: [_jsx(Col, { span: 10, children: _jsx(Form.Item, { label: "Device Name", name: "deviceName", rules: [{ required: true, message: "Device name is required" }], labelCol: { span: 8 }, wrapperCol: { span: 16 }, children: _jsx(Input, { disabled: formDisabled }) }) }), _jsx(Col, { span: 6, children: _jsx(Form.Item, { label: "In Use", name: "isInUse", valuePropName: "checked", labelCol: { span: 8 }, wrapperCol: { span: 16 }, children: _jsx(Switch, { disabled: formDisabled, style: {
                                            marginLeft: "30px",
                                            backgroundColor: isInUse ? "#1A8CFF" : "#C5C7D1",
                                        }, onChange: handleInUseSwitchChange }) }) }), _jsx(Col, { span: 8, style: { textAlign: "end" }, children: !formDisabled ? (_jsxs(_Fragment, { children: [_jsx(Button, { style: {
                                                background: SAColourScheme.WHITE,
                                                color: "#FD0000",
                                                border: "1px solid #ADB3BC",
                                                borderRadius: "5px",
                                                width: "110px",
                                            }, onClick: () => navigate(-1), children: _jsx("span", { children: "Cancel" }) }), _jsx(Button, { style: {
                                                background: "#1A8CFF",
                                                color: SAColourScheme.WHITE,
                                                border: "1px solid white",
                                                borderRadius: "5px",
                                                marginLeft: "10px",
                                                width: "110px",
                                            }, onClick: () => {
                                                form.validateFields().then(() => {
                                                    saveTD();
                                                });
                                            }, children: "Save" })] })) : (_jsx(Button, { style: {
                                        background: "#1A8CFF",
                                        color: SAColourScheme.WHITE,
                                        border: "1px solid white",
                                        borderRadius: "5px",
                                        marginLeft: "10px",
                                        width: "110px",
                                    }, onClick: () => {
                                        restoreTD(initialData.id);
                                    }, children: "Restore" })) })] }), _jsx("div", { style: {
                            borderBottom: "1px solid #ADB3BC",
                            marginBottom: "15px",
                        } }), _jsxs(Row, { justify: "space-between", style: { paddingLeft: "2%" }, children: [_jsxs(Col, { span: 9, children: [_jsx(Form.Item, { label: "Lot Number", name: "lotNumber", rules: [
                                            {
                                                required: true,
                                                message: " Lot Number is required",
                                            },
                                        ], children: _jsx(Input, { disabled: formDisabled }) }), _jsx(Form.Item, { label: "Expiry Date", name: "expireAt", rules: [{ required: true, message: "Expiry Date is required" }], children: _jsx(DatePicker, { disabled: formDisabled, style: { width: "100%" }, format: "DD MMM YYYY" }) }), _jsxs("div", { style: { display: "flex" }, children: [_jsx(Form.Item, { label: "Expiry Date Reminder", name: "isReminderExpire", valuePropName: "checked", style: { display: "flex", alignItems: "center" }, children: _jsx(Switch, { style: {
                                                        backgroundColor: expiryDateReminder ? "#C5C7D1" : "#1A8CFF",
                                                    }, disabled: formDisabled, onChange: handleExpiryDateSwitchChange }) }), _jsx(Form.Item, { label: " ", name: "reminderAt", style: { width: "100%" }, rules: [
                                                    {
                                                        required: !expiryDateReminder,
                                                        message: "Expiry Reminder Date is required",
                                                    },
                                                ], children: _jsx(DatePicker, { format: "DD MMM YYYY", style: { width: "100%" }, disabled: expiryDateReminder || formDisabled }) })] }), _jsx(Form.Item, { label: "Service Office / Clinic (where the device will be used)", name: "collectionPointIds", rules: [
                                            {
                                                required: true,
                                                message: "Please select atleast one Service Office / Clinic",
                                            },
                                        ], children: _jsx(InputWithLabel, { theme: "3", field: "collectionPointIds", inputProps: {
                                                type: "treeselect",
                                                options: collectionsPointOptions,
                                                treeCheckable: true,
                                                showCheckedStrategy: TreeSelect.SHOW_CHILD,
                                                treeLine: {},
                                                treeDefaultExpandAll: true,
                                            } }) })] }), _jsx(Col, { span: 7, children: _jsx(Form.Item, { label: "Picture of the Device", name: "fontImageUrl", style: { marginLeft: "35px", alignSelf: "center" }, children: _jsx(Dropdown, { overlay: getMenu, disabled: formDisabled, trigger: ["click"], overlayStyle: {
                                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                        }, children: _jsx("div", { onClick: () => handleClick("fontImageUrl"), children: _jsx(Image, { src: form.getFieldValue("fontImageUrl") || "/assets/images/no-device-image.png", alt: "No File", preview: false, height: 280, width: 200, style: {
                                                    marginTop: "10px",
                                                    cursor: "pointer",
                                                    background: "white",
                                                    border: "1px solid #D9D9D9",
                                                    padding: "10px",
                                                } }) }) }) }) }), _jsx(Col, { span: 7, children: _jsxs(Form.Item, { label: " ", name: "sideImageUrls", style: { marginLeft: "15px", alignSelf: "center" }, children: [_jsxs(Row, { gutter: 10, children: [_jsx(Col, { span: 12, children: _jsx(Form.Item, { label: "", name: "sideImageUrls0", style: { alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                                            }, children: _jsx("div", { onClick: () => handleClick("sideImageUrls0"), style: { width: "150px", border: "1px" }, children: _jsx(Image, { src: form.getFieldValue("sideImageUrls0") ||
                                                                        "/assets/images/no-device-image.png", alt: "No File", preview: false, height: 140, width: 120, style: {
                                                                        cursor: "pointer",
                                                                        background: "white",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding: "10px",
                                                                    } }) }) }) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: "", name: "sideImageUrls1", style: { alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                                            }, children: _jsx("div", { onClick: () => handleClick("sideImageUrls1"), style: { width: "150px", border: "1px" }, children: _jsx(Image, { src: form.getFieldValue("sideImageUrls1") ||
                                                                        "/assets/images/no-device-image.png", alt: "No File", preview: false, height: 140, width: 120, style: {
                                                                        cursor: "pointer",
                                                                        background: "white",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding: "10px",
                                                                    } }) }) }) }) })] }), _jsxs(Row, { gutter: 10, style: { marginTop: "10px" }, children: [_jsx(Col, { span: 12, children: _jsx(Form.Item, { label: "", name: "sideImageUrls2", style: { alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                                            }, children: _jsx("div", { onClick: () => handleClick("sideImageUrls2"), style: { width: "150px", border: "1px" }, children: _jsx(Image, { src: form.getFieldValue("sideImageUrls2") ||
                                                                        "/assets/images/no-device-image.png", alt: "No File", preview: false, height: 140, width: 120, style: {
                                                                        cursor: "pointer",
                                                                        background: "white",
                                                                        border: "1px solid #D9D9D9",
                                                                        padding: "10px",
                                                                    } }) }) }) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: "", name: "instructionUrl", style: { alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                                            }, children: _jsx("div", { onClick: () => handleClick("instructionUrl"), style: { width: "150px", border: "1px" }, children: _jsx(Image, { src: form.getFieldValue("instructionUrl")
                                                                        ? "/assets/images/operation-file.png"
                                                                        : "/assets/images/no-operation-file.png", alt: "No File", preview: false, height: 140, style: { cursor: "pointer" } }) }) }) }) })] })] }) })] }), _jsx("div", { style: {
                            borderBottom: "1px solid #ADB3BC",
                            marginBottom: "16px",
                            marginTop: "8px",
                            marginLeft: "2%",
                        } }), _jsxs(Row, { justify: "space-between", style: { paddingLeft: "2%" }, children: [_jsxs(Col, { span: 9, children: [_jsx(Form.Item, { label: "ARTG Number", name: "artgNumber", children: _jsx(Input, { disabled: formDisabled }) }), _jsx(Form.Item, { label: "Sponsor", name: "sponsor", children: _jsx(Input, { disabled: formDisabled }) }), _jsx(Form.Item, { label: "Manufacturer", name: "manufacturer", children: _jsx(Input, { disabled: formDisabled }) })] }), _jsx(Col, { span: 5, children: _jsx(Form.Item, { label: "ARTG Public Summary Sheet", name: "artgSummarySheetUrl", style: { marginLeft: "50px", alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                        }, children: _jsx("div", { onClick: () => handleClick("artgSummarySheetUrl"), style: { marginTop: "5px" }, children: form.getFieldValue("artgSummarySheetUrl") ? (_jsx(Image, { src: "/assets/images/file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: {
                                                    cursor: "pointer",
                                                    border: "1px solid #D9D9D9",
                                                } })) : (_jsx(Image, { src: "/assets/images/no-file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: { cursor: "pointer" } })) }) }) }) }), _jsx(Col, { span: 5, children: _jsx(Form.Item, { label: "Laboratory Verification Sheet", name: "laboratorySheetUrl", style: { marginLeft: "50px", alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                        }, children: _jsx("div", { onClick: () => handleClick("laboratorySheetUrl"), style: { marginTop: "5px" }, children: form.getFieldValue("laboratorySheetUrl") ? (_jsx(Image, { src: "/assets/images/file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: {
                                                    cursor: "pointer",
                                                    border: "1px solid #D9D9D9",
                                                } })) : (_jsx(Image, { src: "/assets/images/no-file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: { cursor: "pointer" } })) }) }) }) }), _jsx(Col, { span: 5, children: _jsx(Form.Item, { label: "Specification Sheet", name: "specificationSheetUrl", style: { marginLeft: "50px", alignSelf: "center" }, children: _jsx(Dropdown, { disabled: formDisabled, overlay: getMenu, trigger: ["click"], overlayStyle: {
                                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
                                        }, children: _jsx("div", { onClick: () => handleClick("specificationSheetUrl"), style: { marginTop: "5px" }, children: form.getFieldValue("specificationSheetUrl") ? (_jsx(Image, { src: "/assets/images/file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: { cursor: "pointer" } })) : (_jsx(Image, { src: "/assets/images/no-file-image.png", alt: "No File", preview: false, height: 198, width: 168, style: { cursor: "pointer" } })) }) }) }) })] })] }) }) }));
};
