import styled from "styled-components";
import { SAColourScheme } from "src/constants";
export const MainWrapper = styled.div `
  padding: 2rem;
  background-color: ${SAColourScheme.WHITE};
`;
export const NewDeviceWrapper = styled.div `
  width: 100%;
  background-color: #edeef0;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .ant-input {
    border-radius: 4px;
  }
  .ant-form-item-label {
    padding: 0 0;
  }

  .ant-radio-group > div:nth-child(1) {
    border-bottom: 1px solid white;
  }
`;
export const PageWrapper = styled.div `
  width: 100%;

  .title {
    margin-top: 8px;
    margin-left: 5px;
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 0;
  }
  .sub-title {
    font-size: 12px;
    color: white;
    margin-bottom: 0;
  }
  .ant-form-item-control-input input {
    height: 40px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .info-element {
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    color: #2f3337;
    font-size: 16px;
  }
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }
  .check-box .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }
  .measurement {
    width: 97px;
    height: 32px;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 24px;
    border-radius: 8px;
  }

  .select-label {
    font-size: 16px;
    color: #2f3337;
    margin-top: 7px;
  }
  .number-box input.text-input {
    border-radius: 4px;
    text-align: center;
  }

  .number-box .info-element {
    padding: 3px 0 !important;
    height: 32px;
    width: 40px;
  }
`;
export const Row = styled.div `
  display: flex;
`;
export const Col = styled.div `
  flex: 33%;
  margin-right: 20px;
`;
