import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";
import MainLayout from "src/components/layouts/MainLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { HomeCalendar } from "./components/Calendar";
import { HomeWidget } from "./components/HomeWidget";
import { Summary } from "./components/Summary";
import { getInClinicDailyAppointmentSummary, getListCollectionPoints, getOnSiteSummary, } from "./store/action";
import { getIsOnsiteViewStatus, getSelectedDate } from "./store/selector";
import { initialState, setCurrentDate, setDailySummary, setOnSiteSummary } from "./store/slice";
const HomePage = () => {
    const dispatch = useAppDispatch();
    const currentDate = useAppSelector(getSelectedDate);
    const isOnsiteView = useAppSelector(getIsOnsiteViewStatus);
    const isLoading = useAppSelector(selectIsLoading);
    useEffect(() => {
        dispatch(getListCollectionPoints());
    }, []);
    useEffect(() => {
        dispatch(setIsLoading(true));
        const loadData = () => {
            if (isOnsiteView) {
                dispatch(getOnSiteSummary());
            }
            else {
                dispatch(getInClinicDailyAppointmentSummary());
            }
        };
        loadData();
        setTimeout(() => {
            dispatch(setIsLoading(false));
        }, 1000);
    }, [currentDate, isOnsiteView]);
    const handleDateChange = (date) => {
        dispatch(setDailySummary(initialState.dailySummary));
        dispatch(setOnSiteSummary(initialState.onSiteSummary));
        dispatch(setCurrentDate(date.toISOString()));
    };
    return (_jsx(MainLayout, { children: _jsxs(ContentWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(MainRowContainer, { children: [_jsx(HomeContainer, { children: _jsx(HomeCalendar, { currentDate: moment(new Date(currentDate).toISOString()), fullscreen: false, onDateChange: handleDateChange }) }), _jsx(DashboardContainer, { children: _jsx(Summary, {}) })] }), _jsx(SecondRowContainer, { children: _jsx(HomeWidget, {}) })] }) }));
};
export default HomePage;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  gap: 3rem !important;
  width: 100%;
  height: 100%;
`;
const MainRowContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  min-height: 30vh;
  width: 100%;
  padding-inline: 2rem;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    min-height: fit-content;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1200px) {
    min-height: 40vh;
  }
`;
const SecondRowContainer = styled.div `
  width: 100%;
`;
const HomeContainer = styled.div `
  width: 30%;
  height: 100%;
  @media screen and (max-width: 1400px) {
    width: 40%;
  }
`;
const DashboardContainer = styled.div `
  width: 70%;
  height: 100%;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;
