import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Image } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonSelection } from "src/components/base";
import { mainAppColours } from "src/constants";
import { ColumnView } from "../DataView/ColumnView";
import { ListView } from "../DataView/ListView";
import { HeaderWrapper } from "./index.style";
import ICColumnViewSelected from "/public/assets/svg/ic-column-view-selected.svg";
import ICColumnView from "/public/assets/svg/ic-column-view.svg";
import ICListViewSelected from "/public/assets/svg/ic-list-view-selected.svg";
import ICListView from "/public/assets/svg/ic-list-view.svg";
export const HeaderFilter = ({ name, icon, noDataImage, data, getData, deviceTypeId, collectionPoints, deleteTD, archiveTD, }) => {
    const [selectedView, setSelectedView] = useState("column");
    const [valueList, setValueList] = useState([]);
    const [selectedCollectionPoint, setSelectedCollectionPoint] = useState("All");
    const navigate = useNavigate();
    const toggleView = (view) => {
        setSelectedView(view);
    };
    useEffect(() => {
        if (collectionPoints && collectionPoints.length) {
            const list = collectionPoints.map((e) => ({
                value: e.id,
                label: e.name,
            }));
            setValueList([{ value: "All", label: "All" }, ...list]);
        }
    }, [collectionPoints]);
    const handleCollectionPointChange = (value) => {
        setSelectedCollectionPoint(value);
    };
    const filteredData = selectedCollectionPoint === "All"
        ? data
        : data.filter((item) => item.collectionPointIds.includes(selectedCollectionPoint));
    return (_jsxs(HeaderWrapper, { children: [_jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                    alignItems: "center",
                }, children: [_jsxs(Col, { span: 14, children: [_jsx(Image, { src: icon, height: 30, width: 30, preview: false }), _jsx("span", { style: { marginLeft: "10px", fontSize: "16px", color: "#000000" }, children: name })] }), _jsxs(Col, { span: 2, style: { display: "flex", alignItems: "center" }, children: [selectedView === "column" ? (_jsx(ICColumnViewSelected, { height: 20.21, width: 32, onClick: () => toggleView("column"), style: { cursor: "pointer" } })) : (_jsx(ICColumnView, { height: 20.21, width: 32, style: { cursor: "pointer" }, onClick: () => toggleView("column") })), selectedView === "list" ? (_jsx(ICListViewSelected, { height: 20.21, width: 32, style: { marginLeft: "10px", cursor: "pointer" }, onClick: () => toggleView("list") })) : (_jsx(ICListView, { height: 20.21, width: 32, style: { marginLeft: "10px", cursor: "pointer" }, onClick: () => toggleView("list") }))] }), _jsxs(Col, { span: 4, style: { display: "flex", columnGap: "10px", alignItems: "center" }, children: [_jsx("span", { children: "Show" }), _jsx(ButtonSelection, { className: "button-selection", valueList: valueList, defaultValue: "All", onChange: handleCollectionPointChange })] }), _jsx(Col, { span: 4, style: { display: "flex", justifyContent: "flex-end" }, children: _jsxs(Button, { onClick: () => navigate(`/testing-devices/AddNew?name=${name}`, {
                                state: deviceTypeId,
                            }), style: {
                                border: `1px solid ${mainAppColours.MID_BLUE}`,
                                borderRadius: "4px",
                            }, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#1A8CFF" }, children: "Add New" })] }) })] }), _jsx("div", { style: { borderTop: "1px solid #ADB3BC" } }), selectedView === "list" ? (_jsx(ListView, { name: name, getData: getData, data: filteredData, collectionPoints: collectionPoints, deleteTD: deleteTD, archiveTD: archiveTD })) : (_jsx(ColumnView, { name: name, noDataImage: noDataImage, getData: getData, data: filteredData, collectionPoints: collectionPoints, deleteTD: deleteTD, archiveTD: archiveTD }))] }));
};
