import styled from "styled-components";
export const Wrapper = styled.div `
  button.ant-btn.ant-btn-default.ant-btn-icon-only {
    float: right;
    margin-right: 4px;
    margin-top: 4px;
  }
  .go-page > span {
    margin-bottom: 3px;
  }
`;
