import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkAuth } from "src/api/auth/login";
import { getCollectionManagerSetting, updateCalendarSetting, } from "src/api/settings/settingService";
import ContentContext from "src/providers/content";
import { convertTo12HourFormat, convertTo24HourFormat } from "src/utils/helper";
const CalendarSetting = () => {
    const authData = useSelector(checkAuth.dataSelector);
    const navigate = useNavigate();
    const [isEdited, setIsEdited] = useState(false);
    const { openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [globalSetting, setGlobalSetting] = useState({});
    const [collectionPointsSetting, setCollectionPointsSetting] = useState([]);
    useEffect(() => {
        getSettingData();
    }, []);
    const getSettingData = async () => {
        openLoadingScreen();
        const result = await getCollectionManagerSetting(authData.accessToken);
        if (result.status === "success") {
            setGlobalSetting(result.data.organizationSetting);
            setCollectionPointsSetting(result.data.collectionPointSetting);
            closeLoadingScreen();
        }
    };
    const handleSaveChange = async () => {
        openLoadingScreen();
        const body = {
            organizationSetting: {
                workDayFrom: globalSetting.workDayFrom,
                workDayTo: globalSetting.workDayTo,
                workTimeFrom: globalSetting.workTimeFrom,
                workTimeTo: globalSetting.workTimeTo,
                lunchTimeFrom: globalSetting.lunchTimeFrom,
                lunchTimeTo: globalSetting.lunchTimeTo,
                isLunchTimeCanBook: globalSetting.isLunchTimeCanBook,
            },
            collectionPointSetting: collectionPointsSetting.map((cp) => ({
                collectionPointId: cp.collectionPointId,
                workDayFrom: cp.workDayFrom,
                workDayTo: cp.workDayTo,
                workTimeFrom: cp.workTimeFrom,
                workTimeTo: cp.workTimeTo,
                lunchTimeFrom: cp.lunchTimeFrom,
                lunchTimeTo: cp.lunchTimeTo,
                isLunchTimeCanBook: cp.isLunchTimeCanBook,
            })),
        };
        const result = await updateCalendarSetting(authData.accessToken, body);
        if (result.status === "success") {
            openToast("Saved successfully");
            setIsEdited(false);
            closeLoadingScreen();
        }
    };
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = hour === 0
                    ? "12"
                    : hour <= 12
                        ? hour < 10
                            ? `0${hour}`
                            : `${hour}`
                        : hour - 12 < 10
                            ? `0${hour - 12}`
                            : `${hour - 12}`;
                const formattedMinute = minute === 0 ? "00" : `${minute}`;
                const period = hour < 12 ? "am" : "pm";
                const timeString = `${formattedHour}:${formattedMinute} ${period}`;
                options.push(_jsx(MenuItem, { value: timeString, children: timeString }, timeString));
            }
        }
        return options;
    };
    return (_jsxs(ContentWrapper, { children: [_jsxs("div", { style: { display: "flex", justifyContent: "space-between" }, children: [_jsxs("div", { style: { display: "flex", gap: "10px", margin: "10px", alignItems: "center" }, children: [_jsx(Button, { onClick: () => navigate(-1), startIcon: _jsx("img", { src: "/assets/svg/ic-arrow-left.svg" }) }), _jsx(Typography, { variant: "h6", children: "Calendar" })] }), _jsx(Button, { variant: "contained", style: {
                            textTransform: "none",
                            alignSelf: "flex-end",
                            color: "white",
                            backgroundColor: isEdited ? "#1A8CFF" : "",
                            borderRadius: "5px",
                        }, disabled: !isEdited, onClick: handleSaveChange, children: "Save Changes" })] }), _jsx(Divider, { style: { width: "100%" } }), _jsxs("div", { style: { display: "flex", alignItems: "flex-start", gap: "20px" }, children: [_jsx("img", { src: "/assets/svg/platform-settings/ic-country.svg" }), _jsxs("div", { style: {
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                            borderBottom: "2px solid #ADB3BC80",
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "0 20px 20px 0",
                        }, children: [_jsx("b", { style: { alignSelf: "flex-start", minWidth: "200px" }, children: "Global setting for the organisation" }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsxs("div", { style: { display: "flex", gap: "30px", flexWrap: "wrap" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                            display: "flex",
                                                            gap: "10px",
                                                            borderBottom: "1px solid #ADB3BC80",
                                                            width: "100%",
                                                            alignItems: "center",
                                                        }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Work Week" })] }), _jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: globalSetting.workDayFrom || "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({ ...globalSetting, workDayFrom: e.target.value });
                                                                }, children: days.map((d) => (_jsx(MenuItem, { value: d, children: d }, `${d}-menu-item`))) }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: globalSetting.workDayTo || "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({ ...globalSetting, workDayTo: e.target.value });
                                                                }, children: days.map((d) => (_jsx(MenuItem, { value: d, children: d }, `${d}-menu-item`))) })] })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                            display: "flex",
                                                            gap: "10px",
                                                            borderBottom: "1px solid #ADB3BC80",
                                                            width: "100%",
                                                            alignItems: "center",
                                                        }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Work Time (Booking Availability)" })] }), _jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: (globalSetting.workTimeFrom &&
                                                                    convertTo12HourFormat(globalSetting.workTimeFrom)) ||
                                                                    "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({
                                                                        ...globalSetting,
                                                                        workTimeFrom: convertTo24HourFormat(e.target.value),
                                                                    });
                                                                }, children: generateTimeOptions() }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: (globalSetting.workTimeTo &&
                                                                    convertTo12HourFormat(globalSetting.workTimeTo)) ||
                                                                    "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({
                                                                        ...globalSetting,
                                                                        workTimeTo: convertTo24HourFormat(e.target.value),
                                                                    });
                                                                }, children: generateTimeOptions() })] })] })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                    display: "flex",
                                                    gap: "10px",
                                                    borderBottom: "1px solid #ADB3BC80",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Lunch Time" })] }), _jsxs("div", { style: { display: "flex", gap: "30px", alignItems: "center", flexWrap: "wrap" }, children: [_jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: (globalSetting.lunchTimeFrom &&
                                                                    convertTo12HourFormat(globalSetting.lunchTimeFrom)) ||
                                                                    "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({
                                                                        ...globalSetting,
                                                                        lunchTimeFrom: convertTo24HourFormat(e.target.value),
                                                                    });
                                                                }, children: generateTimeOptions() }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: (globalSetting.lunchTimeTo &&
                                                                    convertTo12HourFormat(globalSetting.lunchTimeTo)) ||
                                                                    "", onChange: (e) => {
                                                                    setIsEdited(true);
                                                                    setGlobalSetting({
                                                                        ...globalSetting,
                                                                        lunchTimeTo: convertTo24HourFormat(e.target.value),
                                                                    });
                                                                }, children: generateTimeOptions() })] }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "Open for bookings" }), _jsx(Switch, { checked: globalSetting.isLunchTimeCanBook || false, onChange: (e) => {
                                                            setIsEdited(true);
                                                            setGlobalSetting({ ...globalSetting, isLunchTimeCanBook: e.target.checked });
                                                        } })] })] })] })] })] }), _jsx("div", { style: { overflowY: "auto", display: "flex", flexDirection: "column", gap: "10px" }, children: collectionPointsSetting.map((point) => (_jsxs("div", { style: { display: "flex", alignItems: "flex-start", gap: "20px" }, children: [_jsx("img", { style: { marginTop: "20px" }, src: "/assets/svg/ic-location-dark.svg" }), _jsxs("div", { style: {
                                display: "flex",
                                gap: "15px",
                                alignItems: "center",
                                backgroundColor: "white",
                                width: "100%",
                                justifyContent: "space-between",
                                padding: "20px",
                                borderRadius: "5px",
                            }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", alignSelf: "flex-start" }, children: [_jsx("p", { style: { borderBottom: "1px solid #ADB3BC80", minWidth: "200px" }, children: point.collectionPointName }), _jsx(TextField, { size: "small", value: point ? point.address?.split(",")[1] : "", disabled: true })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsxs("div", { style: { display: "flex", gap: "30px", flexWrap: "wrap" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                                display: "flex",
                                                                gap: "10px",
                                                                borderBottom: "1px solid #ADB3BC80",
                                                                width: "100%",
                                                                alignItems: "center",
                                                            }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Work Week" })] }), _jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: point.workDayFrom || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, workDayFrom: e.target.value }
                                                                            : item));
                                                                    }, children: days.map((d) => (_jsx(MenuItem, { value: d, children: d }, `${d}-menu-item`))) }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: point.workDayTo || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, workDayTo: e.target.value }
                                                                            : item));
                                                                    }, children: days.map((d) => (_jsx(MenuItem, { value: d, children: d }, `${d}-menu-item`))) })] })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                                display: "flex",
                                                                gap: "10px",
                                                                borderBottom: "1px solid #ADB3BC80",
                                                                width: "100%",
                                                                alignItems: "center",
                                                            }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Work Time (Booking Availability)" })] }), _jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: (point.workTimeFrom && convertTo12HourFormat(point.workTimeFrom)) || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, workTimeFrom: convertTo24HourFormat(e.target.value) }
                                                                            : item));
                                                                    }, children: generateTimeOptions() }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: (point.workTimeTo && convertTo12HourFormat(point.workTimeTo)) || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, workTimeTo: convertTo24HourFormat(e.target.value) }
                                                                            : item));
                                                                    }, children: generateTimeOptions() })] })] })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsxs("div", { style: {
                                                        display: "flex",
                                                        gap: "10px",
                                                        borderBottom: "1px solid #ADB3BC80",
                                                        width: "100%",
                                                        alignItems: "center",
                                                    }, children: [_jsx("div", { style: { width: "15px", height: "15px", backgroundColor: "#D9D9D9" } }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "black" }, children: "Lunch Time" })] }), _jsxs("div", { style: { display: "flex", gap: "30px", alignItems: "center", flexWrap: "wrap" }, children: [_jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "From" }), _jsx(Select, { size: "small", value: (point.lunchTimeFrom && convertTo12HourFormat(point.lunchTimeFrom)) || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, lunchTimeFrom: convertTo24HourFormat(e.target.value) }
                                                                            : item));
                                                                    }, children: generateTimeOptions() }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "To" }), _jsx(Select, { size: "small", value: (point.lunchTimeTo && convertTo12HourFormat(point.lunchTimeTo)) || "", onChange: (e) => {
                                                                        setIsEdited(true);
                                                                        setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                            ? { ...item, lunchTimeTo: convertTo24HourFormat(e.target.value) }
                                                                            : item));
                                                                    }, children: generateTimeOptions() })] }), _jsx("p", { style: { margin: "0", fontSize: "larger", color: "#1A576F" }, children: "Open for bookings" }), _jsx(Switch, { checked: point.isLunchTimeCanBook || false, onChange: (e) => {
                                                                setIsEdited(true);
                                                                setCollectionPointsSetting(collectionPointsSetting.map((item) => item.collectionPointId === point.collectionPointId
                                                                    ? { ...item, isLunchTimeCanBook: e.target.checked }
                                                                    : item));
                                                            } })] })] })] })] })] }, point.collectionPointId))) })] }));
};
export default CalendarSetting;
const ContentWrapper = styled.div `
  background-color: #fafafa;
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
