import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Image, SAButton } from "src/components/base";
import { MESSAGE_BOX_TYPE } from "src/constants";
import { UserAction } from "src/pages/UserProfilePage";
import { verifyPasswordDeleteAccount } from "src/pages/UserProfilePage/store/action";
import { useAppDispatch } from "src/store/hooks";
const DeleteAccountPopup = ({ onUpdateStatus, openMessageBox }) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [isPwdType, setIsPwdType] = useState(true);
    const dispatch = useAppDispatch();
    const handleChange = (value) => {
        setCurrentPassword(value);
    };
    const onClickEye = () => {
        setIsPwdType(!isPwdType);
    };
    const handleSubmitCurrentPassword = async () => {
        const result = await dispatch(verifyPasswordDeleteAccount(currentPassword));
        // @ts-expect-error: remove this later
        if (result.payload.status === "success") {
            onUpdateStatus(UserAction.DELETE_ACCOUNT);
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "The password you entered is incorrect. \r Please try again.");
        }
    };
    return (_jsxs("div", { style: {
            backgroundColor: "#FAFAFA",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            gap: "4vh",
            paddingBottom: "10%",
        }, children: [_jsx(Typography, { variant: "h5", style: {
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#1A8CFF",
                    color: "white",
                    padding: "10px",
                }, children: "Delete Account" }), _jsxs("div", { style: {
                    backgroundColor: "#FAFAFA",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    flexWrap: "wrap",
                    gap: "4vh",
                }, children: [_jsx(Typography, { style: { color: "#00004C" }, children: "Please enter your current password to continue" }), _jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                        }, children: [_jsx("label", { style: { color: "#2A44FF" }, children: "Current Password" }), _jsxs("div", { style: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "2px solid #156CC9",
                                    borderRadius: "4px",
                                    height: "48px",
                                }, children: [_jsx("input", { onChange: ({ target: { value } }) => handleChange(value), className: "password-input", style: {
                                            flex: "70%",
                                            outline: "none",
                                            border: "none",
                                            paddingLeft: "13px",
                                            color: "#2A44FF",
                                        }, placeholder: "Enter new password", type: isPwdType ? "password" : "text", value: currentPassword }), _jsx("div", { style: { margin: "10px 13px" }, children: _jsx(Image, { src: "/assets/svg/ic-look.svg", alt: "look icon", preview: false, width: "24px", onClick: onClickEye }) })] })] }), _jsx(SAButton, { name: "Continue", onClick: handleSubmitCurrentPassword, style: {
                            color: "white",
                            background: "#1A8CFF",
                            borderRadius: "8px",
                            border: "2px solid #FFFFFF",
                            width: "150px",
                            alignSelf: "center",
                        } })] })] }));
};
export default DeleteAccountPopup;
