import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Spin, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getTransportSatchels } from "src/api/inventory/inventoryService";
import { TableWrapper } from "src/pages/InventoryManagement/TransportSatchels/NewSatchel/index.style";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDateFormat } from "src/utils/helper";
export const SatchelsTable = () => {
    const { settings } = useSettings();
    const userInfo = useSelector(checkAuth.dataSelector);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getSatchelsData = async () => {
        setIsLoading(true);
        const result = await getTransportSatchels(userInfo.accessToken);
        if (result.status === "success") {
            setData(result.data);
        }
        else {
            setData([]);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (userInfo) {
            getSatchelsData();
        }
    }, [userInfo]);
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Barcode Number",
            dataIndex: "barcodeNumber",
            key: "barcodeNumber",
            sorter: (a, b) => a.barcodeNumber.localeCompare(b.barcodeNumber),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Commission Date",
            dataIndex: "commissionDate",
            key: "commissionDate",
            sorter: (a, b) => a.commissionDate.localeCompare(b.commissionDate),
            render: (text, record) => {
                const { commissionDate } = record;
                let formattedDate = "";
                if (commissionDate !== "") {
                    formattedDate = getDateFormat(new Date(commissionDate), settings);
                }
                return _jsx(Space, { children: formattedDate });
            },
        },
        {
            title: "Status",
            dataIndex: "",
            key: "",
            sorter: (a, b) => {
                const getStatusOrder = (record) => {
                    if (record.inStock) {
                        return 1;
                    }
                    else if (record.inService) {
                        return 2;
                    }
                    else if (record.outOfService) {
                        return 3;
                    }
                    else {
                        return 4;
                    }
                };
                return getStatusOrder(a) - getStatusOrder(b);
            },
            render: (record) => {
                const { inStock, inService, outOfService } = record;
                if (inStock) {
                    return "In Stock";
                }
                else if (inService) {
                    return "In Service";
                }
                else if (outOfService) {
                    return "Out of Service";
                }
                else {
                    return (_jsx(_Fragment, { children: _jsx("div", { style: {
                                width: "90px",
                                height: "25px",
                                background: "#FD0000",
                                fontSize: "13px",
                                color: "#FFFFFF",
                                paddingTop: "2px",
                                borderRadius: "15px",
                                textAlign: "center",
                            }, children: "Incomplete" }) }));
                }
            },
        },
        {
            title: "Days in Service",
            dataIndex: "daysInService",
            key: "daysInService",
            sorter: (a, b) => a.daysInService - b.daysInService,
            render: (text, record) => (_jsx(Space, { children: record.commissionDate ? moment().diff(moment(record.commissionDate), "days") : 0 })),
        },
        {
            title: "Issues",
            dataIndex: "securityIssues",
            key: "securityIssues",
            sorter: (a, b) => a.securityIssues - b.securityIssues,
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Location",
            dataIndex: "region",
            key: "region",
            sorter: (a, b) => a.region.localeCompare(b.region),
            render: (text) => _jsx(Space, { children: text }),
        },
    ];
    return (_jsx(_Fragment, { children: _jsx(TableWrapper, { children: _jsxs("div", { style: {
                    borderTop: "1px solid #ADB3BC",
                    borderBottom: "1px solid #ADB3BC",
                    overflow: "auto",
                    marginTop: "20px",
                }, children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsxs(_Fragment, { children: [_jsx(Spin, { style: {
                                        position: "absolute",
                                        top: "150px",
                                        left: "40vw",
                                    } }), _jsx("span", { style: { color: "#156CC9", position: "absolute", top: "180px", left: "37vw" }, children: "Fetching table details..." })] })) }), _jsx("div", { style: { opacity: isLoading ? 0.3 : 1 }, children: _jsx(Table, { columns: columns, dataSource: data.length > 0 ? data.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { x: 900, y: 650 } }) })] }) }) }));
};
