import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { createSupportQuestion } from "src/api/faqs/faqsService";
import { getCollectionManagerSetting } from "src/api/settings/settingService";
import { IconButton } from "src/components/base";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { ContactForm } from "./ContactForm";
import { Wrapper } from "./index.style";
export const ContactUs = (props) => {
    const { openMessageBox } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const [loading, setLoading] = useState(false);
    const { openToast } = useContext(ContentContext);
    const onSend = async (data) => {
        setLoading(true);
        const details = await getCollectionManagerSetting(userInfo.accessToken);
        const timezone = details.data.organizationSetting.timezone;
        const formData = {
            ...data,
            sendBy: `${userInfo.name}`,
            userId: userInfo.user_id,
            collectionOrganizationId: userInfo.collectionOrganizationId,
            timezone,
        };
        // await contactUs.actionCreator(userInfo.accessToken, formData);
        const response = await createSupportQuestion(userInfo.accessToken, formData);
        if (response.status === "success") {
            openToast("Submitted successfully");
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
        }
        setLoading(false);
    };
    return (_jsxs(Wrapper, { children: [_jsxs("div", { className: "contact-us-header", children: [_jsxs("div", { style: { display: "flex" }, children: [_jsx("div", { style: { marginTop: "5px" }, children: _jsx(IconButton, { icon: "/assets/svg/ic-arrow-back.svg", onClick: props.onBack }) }), _jsx("div", { className: "contact-us-title", style: { paddingBottom: "7px" }, children: "Contact Us" })] }), _jsx("div", { className: "got-question", children: "Got a question?" }), _jsx("div", { className: "title", style: { textAlign: "center", marginTop: "10px" }, children: "We would love to hear from you." }), _jsx("div", { className: "title", style: { textAlign: "center" }, children: "Send us a message and we will respond as soon as possible." })] }), _jsx("div", { style: { marginTop: "-65px", marginBottom: "30px" }, children: _jsx(ContactForm, { loading: loading, onSend: onSend, userName: `${userInfo?.name}`, 
                    // firstName={loggedUser?.firstName}
                    // lastName={loggedUser?.lastName}
                    email: userInfo?.email }) })] }));
};
