import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Drawer, message, Upload } from "antd";
import React, { useContext, useState } from "react";
import { uploadImage } from "src/api/uploadImage";
import { Image } from "src/components/base";
import { SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
import ICUpload from "/public/assets/svg/ic-file-upload.svg";
export const FileUpload = ({ onCloseDrawer, onFileUploaded, ...rest }) => {
    const [file, setFile] = useState(undefined);
    const [imgUrl, setImgUrl] = useState(null);
    const [isPdf, setIsPdf] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fileList, setFileList] = useState([]); // Controlled file list
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (curFile) => {
        setErrorMessage(null);
        const allowedFileTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
        if (!allowedFileTypes.includes(curFile.type.toLowerCase())) {
            setErrorMessage("Unsupported file type. Please upload a PDF, JPG, or PNG file.");
            return false;
        }
        const isPdfFile = curFile.type === "application/pdf";
        setIsPdf(isPdfFile);
        getBase64(curFile, (curImgUrl) => {
            setImgUrl(curImgUrl);
        });
        setFile(curFile);
        setFileList([curFile]); // Update the file list
        return false;
    };
    const saveFile = async () => {
        if (file) {
            openLoadingScreen();
            const url = await uploadImage("signature", imgUrl, file.type.split("/")[1]);
            onFileUploaded(url);
            onCloseDrawer();
            resetFile();
            closeLoadingScreen();
        }
    };
    const resetFile = () => {
        setErrorMessage(null);
        setFile(undefined);
        setImgUrl(null);
        setIsPdf(false);
        setFileList([]); // Reset the file list
    };
    return (_jsxs(Drawer, { title: "Upload File", onClose: () => {
            onCloseDrawer();
            resetFile();
        }, width: "40%", ...rest, headerStyle: { background: "#D3C2BA", textAlign: "center" }, bodyStyle: { padding: "15%" }, children: [_jsxs("p", { className: "ant-upload-text", children: ["Supported file types: ", _jsx("b", { children: "JPG, PNG or PDF" })] }), _jsx(Upload.Dragger, { name: "file", beforeUpload: beforeUpload, maxCount: 1, listType: "picture", fileList: fileList, onChange: (info) => {
                    if (errorMessage) {
                        message.error(errorMessage);
                    }
                    if (info.file.status === "removed") {
                        resetFile();
                    }
                }, onRemove: () => {
                    resetFile(); // Reset file when removed
                }, style: { maxHeight: "500px", marginTop: "30px" }, children: imgUrl ? (isPdf ? (_jsx("embed", { src: imgUrl, type: "application/pdf", style: { width: "100%", height: "100%" } })) : (_jsx(Image, { src: imgUrl, style: { width: "100%", height: "100%" } }))) : (_jsxs(_Fragment, { children: [_jsx("p", { className: "ant-upload-drag-icon", children: _jsx(ICUpload, {}) }), _jsx("p", { className: "ant-upload-text", style: { fontWeight: "500" }, children: "Drag and Drop Here" }), _jsx("p", { className: "ant-upload-text", children: "or" }), _jsx("p", { className: "ant-upload-text", style: { color: "#1A8CFF" }, children: "Click to upload" })] })) }), errorMessage && (_jsx("span", { style: { color: "red", fontSize: "15px" }, children: "Unsupported file type. Please re-upload again." })), _jsxs(Col, { span: 24, style: { textAlign: "center", marginTop: "80px" }, children: [_jsx(Button, { style: {
                            background: SAColourScheme.WHITE,
                            color: "#FD0000",
                            border: "1px solid #ADB3BC",
                            borderRadius: "5px",
                            width: "110px",
                        }, onClick: () => {
                            onCloseDrawer();
                            resetFile();
                        }, children: _jsx("span", { children: "Cancel" }) }), _jsx(Button, { style: {
                            background: "#1A8CFF",
                            color: SAColourScheme.WHITE,
                            border: "1px solid white",
                            borderRadius: "5px",
                            marginLeft: "10px",
                            width: "110px",
                        }, onClick: saveFile, children: "Save" })] })] }));
};
