import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
export const PageLayoutWithTabAndBack = ({ children, routes, heading = "", }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleChangeTab = (activeKey) => {
        const currentPaths = location.pathname.split("/");
        currentPaths.pop();
        const basePath = currentPaths;
        navigate(basePath.join("/") + activeKey, { replace: true });
    };
    return (_jsxs(PageLayoutWithTabAndBackWrapper, { children: [_jsxs(Header, { isDisplayTab: Boolean(routes), children: [_jsxs(CustomButton, { type: "link", onClick: () => navigate(-1), children: [_jsx(LeftOutlined, {}), " Go Back"] }), _jsx(Heading, { children: heading })] }), routes ? (_jsx(CustomTabs, { onChange: handleChangeTab, type: "card", activeKey: location.pathname.split("/")[-1], tabPosition: "top", children: routes.map((route) => (_jsx(CustomTabPane, { tab: route.name, children: children }, route.path))) })) : (children)] }));
};
const CustomTabPane = styled(Tabs.TabPane) `
  height: 100%;
`;
const CustomTabs = styled(Tabs) `
  margin-top: -2.5rem;
  height: 100%;

  .ant-tabs-content-holder {
    padding: 2rem;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    margin-left: 2.5rem;
    margin-top: 0.7rem;
  }

  .ant-tabs-nav-list {
    column-gap: 0.625rem;
  }

  .ant-tabs-nav {
    border: none;
    margin: 0 0;
  }

  .ant-tabs-tab {
    color: white;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    background-color: rgb(1, 180, 210) !important;
    min-width: 10rem !important;
    border: none !important;
    text-align: center !important;
    padding: 0.25rem !important;
    padding-inline: 1rem !important;
  }

  .ant-tabs-tab-active {
    background-color: white !important;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-tabs-tab-active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 7%;
    width: 86%;
    height: 0.125rem;
    background-color: #fdba00;
  }

  .ant-tabs-content {
    display: block;
  }
`;
const PageLayoutWithTabAndBackWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
`;
const Header = styled.div `
  height: ${({ isDisplayTab }) => (isDisplayTab ? "5rem" : "2.5rem")};
  background-color: #cbf5fc;
  width: 100%;
`;
const CustomButton = styled(Button) `
  color: #4b5767;
`;
const Heading = styled.p `
  font-size: 1.3rem;
  color: rgb(75, 87, 103);
  margin: 0px auto;
  text-align: center;
  margin-top: -1.6rem;
`;
