import styled from "styled-components";
export const TabsWrapper = styled.div `
  margin-top: -2.5rem;
  height: 100%;

  .ant-tabs-content-holder {
    padding: 2rem;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    margin-left: 2.5rem;
    margin-top: 0.7rem;
  }

  .ant-tabs-nav-list {
    column-gap: 0.625rem;
  }

  .ant-tabs-nav {
    border: none;
    margin: 0 0;
  }

  .ant-tabs-tab {
    color: white;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    background-color: rgb(1, 180, 210) !important;
    min-width: 10rem !important;
    border: none !important;
    text-align: center !important;
    padding: 0.25rem !important;
    padding-inline: 1rem !important;
  }

  .ant-tabs-tab-active {
    background-color: white !important;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-tabs-tab-active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 7%;
    width: 86%;
    height: 0.125rem;
    background-color: #fdba00;
  }

  .ant-tabs-content {
    display: block;
  }
`;
