import { TestTypeRecordIndex } from "src/store/app/types";
const selectDrugTestTypes = (state) => state.authentication.app.testTypes.filter((testType) => testType.recordIndex !== TestTypeRecordIndex.BreathAlcoholTest);
const selectTestTypes = (state) => {
    const testTypes = state.clientManagementPage.clientSpecificationsPage.listTestTypes;
    return testTypes.filter((testType) => testType.recordIndex !== TestTypeRecordIndex.BreathAlcoholTest);
};
const selectClientSpecifications = (state) => {
    const specifications = state.clientManagementPage.clientSpecificationsPage.clientSpecifications;
    return {
        combinationTestType: specifications?.combinationTestType,
        additionalTest: specifications?.additionalTest,
        confirmationInstruction: specifications?.confirmationInstruction,
        screeningProcess: specifications?.screeningProcess,
        specialInstruction: specifications?.specialInstruction,
        selectionProcess: specifications?.selectionProcess,
        siteAccessInstruction: specifications?.siteAccessInstruction,
    };
};
const selectCategoriesList = (state) => {
    const categories = state.clientManagementPage.clientSpecificationsPage.categoriesData;
    return categories.map((cat, index) => ({
        ...cat,
        index: index + 1,
    }));
};
const selectDevicePreferenceData = (state) => state.clientManagementPage.clientSpecificationsPage.devicePreferenceData;
const selectIsEdit = (state) => state.clientManagementPage.clientSpecificationsPage.isEdit;
const selectTestCombination = (state) => state.clientManagementPage.clientSpecificationsPage.selectedTestCombination;
export { selectDrugTestTypes, selectClientSpecifications, selectCategoriesList, selectDevicePreferenceData, selectIsEdit, selectTestCombination, selectTestTypes, };
