/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getProcessTestsCount = async (type, startDate, endDate, idToken) => {
    const params = {
        type,
        startAt: moment(startDate).format("YYYY-MM-DD"),
        endAt: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/retrieveTests`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getProcessPatientsCount = async (startDate, endDate, idToken) => {
    const params = {
        startAt: moment(startDate).format("YYYY-MM-DD"),
        endAt: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/retrieveTestsBySex`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestType = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/retrieveTestTypes`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestProcessedLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testProcessed/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestProcessedMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testProcessed/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestProcessedRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testProcessed/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTransportHistoryData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/satchelHistory`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTransportInventoryData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/satchelInventory`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getDashboardDevicesData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testingDevices`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getGlobalDevicesData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testingDevices/globalData`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getLocationWiseData = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testingDevices/locationData`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getHairResultsLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/hairDrugResults/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getHairResultsMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/hairDrugResults/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getHairResultsRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/hairDrugResults/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestCategoriesLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testCategories/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestCategoriesMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testCategories/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestCategoriesRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testCategories/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorPerformanceLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorPerformance/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorPerformanceMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorPerformance/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorPerformanceRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorPerformance/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectors = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/retrieveCollectors`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorRankingsLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorRankings/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorRankingsMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorRankings/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectorRankingsRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/collectorRankings/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientCommunicationLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientCommunication/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientCommunicationMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientCommunication/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientCommunicationRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientCommunication/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getOralFluidLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/oralFluid/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getOralFluidMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/oralFluid/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getOralFluidRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/oralFluid/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getUrineDrugLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/urineDrug/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getUrineDrugMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/urineDrug/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getUrineDrugRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/urineDrug/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestTimeLeftRowData = async (collectionPoint, collector, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testTime/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestTimeMiddleRowData = async (collectionPoint, collector, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        collector,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testTime/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestTimeRightRowData = async (collectionPoint, collector, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/testTime/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientTimeLeftRowData = async (collectionPoint, collector, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientTime/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientTimeMiddleRowData = async (collectionPoint, collector, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        collector,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientTime/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getPatientTimeRightRowData = async (collectionPoint, collector, startAt, endAt, idToken) => {
    const params = {
        collector,
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/patientTime/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidUrineLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidUrine/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidUrineMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidUrine/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidUrineRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidUrine/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidOralLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidOral/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidOralMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidOral/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getRapidOralRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/rapidOral/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getBreathAlcoholLeftRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/breathAlcohol/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getBreathAlcoholMiddleRowData = async (collectionPoint, isMonthView, selectYear, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/breathAlcohol/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getBreathAlcoholRightRowData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/breathAlcohol/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getDrugClassesLeftRowData = async (collectionPoint, startAt, endAt, resultType, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
        resultType,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/drugClasses/leftRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getDrugClassesMiddleRowData = async (collectionPoint, isMonthView, selectYear, resultType, idToken) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
        resultType,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/drugClasses/middleRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getDrugClassesRightRowData = async (collectionPoint, startAt, endAt, resultType, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
        resultType,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/modules/drugClasses/rightRowData`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestResultsData = async (collectionPoint, startAt, endAt, idToken) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    try {
        const response = await axios.get(`${API_URL}/collection-manager/dashboard/retrieveTestResults`, {
            params,
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
