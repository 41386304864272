import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Dropdown, Image, Menu, Space, Switch, Table } from "antd";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { createTestingDevice, toggleInUseTestingDevice, } from "src/api/testingDevices/testingDeviceService";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDate, getDateFormat } from "src/utils/helper";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
// List view common for all device type
// Few fields modified based on the device type
export const ListView = ({ name, getData, data, collectionPoints, deleteTD, archiveTD }) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    // For filtering data based on collection points
    const getCollectionPointName = (id) => {
        const collectionPoint = collectionPoints.find((point) => point.id === id);
        return collectionPoint ? collectionPoint.name : "Unknown Collection Point";
    };
    const userInfo = useSelector(checkAuth.dataSelector);
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(Space, { children: _jsx("a", { onClick: () => navigate(`/testing-devices/AddNew?name=${name}`, {
                        state: { record },
                    }), style: { color: "#2A44FF", textDecoration: "underline" }, children: text }) })),
        },
        name === "Breathalysers"
            ? {
                title: "Serial Number",
                dataIndex: "serialNumber",
                key: "serialNumber",
                sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
            }
            : {
                title: "Lot No.",
                dataIndex: "lotNumber",
                key: "lotNumber",
                sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            },
        name === "Breathalysers"
            ? {
                title: "Certificate No.",
                dataIndex: "certificateNumber",
                key: "certificateNumber",
                sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            }
            : {},
        name === "Breathalysers"
            ? {
                title: "Calibration Due Date",
                dataIndex: "expireAt",
                key: "expireAt",
                sorter: (a, b) => {
                    const dateA = new Date(a.expireAt);
                    const dateB = new Date(b.expireAt);
                    return dateA.getTime() - dateB.getTime();
                },
                render: (text, record) => {
                    const { expireAt } = record;
                    let formattedDate = "";
                    if (expireAt !== "") {
                        formattedDate = getDateFormat(new Date(expireAt), settings);
                    }
                    return (_jsx(Space, { children: new Date(getDate(expireAt)) < new Date() ? (_jsx("div", { style: {
                                width: "90px",
                                height: "25px",
                                background: "#FD0000",
                                fontSize: "13px",
                                color: "#FFFFFF",
                                paddingTop: "2px",
                                borderRadius: "15px",
                                textAlign: "center",
                            }, children: "Over Due" })) : (formattedDate) }));
                },
            }
            : {
                title: "Expiry Date",
                dataIndex: "expireAt",
                key: "expireAt",
                sorter: (a, b) => {
                    const dateA = new Date(getDate(a.expireAt));
                    const dateB = new Date(getDate(b.expireAt));
                    return dateA.getTime() - dateB.getTime();
                },
                render: (text, record) => {
                    const { expireAt } = record;
                    let formattedDate = "";
                    if (expireAt !== "") {
                        formattedDate = getDateFormat(new Date(getDate(expireAt)), settings);
                    }
                    return (_jsx(Space, { children: new Date(getDate(expireAt)) < new Date() ? (_jsx("div", { style: {
                                width: "90px",
                                height: "25px",
                                background: "#FD0000",
                                fontSize: "13px",
                                color: "#FFFFFF",
                                paddingTop: "2px",
                                borderRadius: "15px",
                                textAlign: "center",
                            }, children: "Expired" })) : (formattedDate) }));
                },
            },
        {
            title: "Usage Location",
            dataIndex: "usageLocation",
            key: "usageLocation",
            sorter: (a, b) => a.usageLocation.localeCompare(b.usageLocation),
            render: (text, record) => {
                if (record.collectionPointIds.length > 1) {
                    return _jsx(Space, { children: "Multiple Locations" });
                }
                else {
                    return _jsx(Space, { children: getCollectionPointName(record.collectionPointIds[0]) });
                }
            },
        },
        {
            title: "In Use",
            dataIndex: "isInUse",
            key: "isInUse",
            render: (text, record) => (_jsx(Switch, { style: {
                    backgroundColor: record.isInUse ? "#1A8CFF" : "#C5C7D1",
                }, checked: record.isInUse, onChange: async () => {
                    openLoadingScreen();
                    const updateUsage = {
                        isInUse: !record.isInUse,
                    };
                    const response = await toggleInUseTestingDevice(userInfo.accessToken, record.id, updateUsage);
                    if (response.status === "success") {
                        openToast("Updated successfully");
                        closeLoadingScreen();
                        getData();
                    }
                    else {
                        openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                        closeLoadingScreen();
                    }
                } })),
        },
        {
            title: "Summary Sheet",
            dataIndex: "artgSummarySheetUrl",
            key: "artgSummarySheetUrl",
            render: (record) => (_jsx(Button, { style: {
                    padding: "0px",
                    border: "none",
                    background: "none",
                }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
        },
        name === "Breathalysers"
            ? {
                title: "Certificate",
                dataIndex: "calibrationCertificateUrl",
                key: "calibrationCertificateUrl",
                render: (text, record) => (_jsx(Space, { children: record.calibrationCertificateUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            }
            : {
                title: "Laboratory Verification",
                dataIndex: "laboratorySheetUrl",
                key: "laboratorySheetUrl",
                render: (record) => (_jsx(Button, { style: {
                        padding: "0px",
                        border: "none",
                        background: "none",
                    }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            },
        name === "Breathalysers"
            ? {
                title: "Report",
                dataIndex: "deviceManualUrl",
                key: "deviceManualUrl",
                render: (text, record) => (_jsx(Space, { children: record.deviceManualUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            }
            : {
                title: "Specification sheet",
                dataIndex: "specificationSheetUrl",
                key: "specificationSheetUrl",
                render: (record) => (_jsx(Button, { style: {
                        padding: "0px",
                        border: "none",
                        background: "none",
                    }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => navigate(`/testing-devices/AddNew?name=${name}`, {
                                state: { record },
                            }), children: "View Details" }, "1"), _jsx(Menu.Item, { onClick: async () => {
                                openLoadingScreen();
                                const duplicatedRecord = {
                                    ...record,
                                    expireAt: getDate(record.expireAt).toDateString(),
                                    deviceName: record.deviceName + " - Copy",
                                    id: undefined,
                                };
                                const response = await createTestingDevice(userInfo.accessToken, duplicatedRecord);
                                if (response.status === "success") {
                                    openToast("Created successfully");
                                    getData();
                                }
                                else {
                                    openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                                }
                                closeLoadingScreen();
                            }, children: "Duplicate" }, "2"), _jsx(Menu.Item, { onClick: () => {
                                name === "Breathalysers"
                                    ? openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.deviceName} - Serial No. ${record.serialNumber} will be archived and no longer can be used by collectors`, () => {
                                        archiveTD(record.id);
                                    })
                                    : openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.deviceName} - Lot No. ${record.lotNumber} will be archived and no longer can be used by collectors`, () => {
                                        archiveTD(record.id);
                                    });
                            }, children: "Archive" }, "3"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                name === "Breathalysers"
                                    ? openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.deviceName} - Serial No. ${record.serialNumber} will be removed permanently and no longer can be used by collectors?`, () => {
                                        deleteTD(record.id);
                                    })
                                    : openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.deviceName} - Lot No. ${record.lotNumber} will be removed permanently and no longer can be used by collectors?`, () => {
                                        deleteTD(record.id);
                                    });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Delete" }) }, "4")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    if (name !== "Breathalysers") {
        columns.splice(3, 1);
        if (name === "Oral Fluid Drug Screen Devices" || name === "Oral Fluid Collection Devices") {
            columns.splice(6, 1);
        }
    }
    else {
        columns.splice(7, 1);
    }
    return (_jsx("div", { style: { marginTop: 10 }, children: _jsx(Table, { columns: columns, dataSource: data.length > 0 ? data.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                border: "1px solid #D9D9D9",
                overflow: "hidden",
            }, footer: () => "" }) }));
};
