/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
export const getAllPatients = async (startDate, endDate) => {
    const newStartDate = moment(startDate._d).format("YYYY-MM-DD");
    const newEndDate = moment(endDate._d).format("YYYY-MM-DD");
    const queryString = stringify({
        startAt: newStartDate,
        endAt: newEndDate,
    });
    return await $get(`/collection-manager/patient-list/get-patients?${queryString}`);
};
