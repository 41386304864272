import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getCollectorRankingsLeftRowData, getCollectorRankingsMiddleRowData, getCollectorRankingsRightRowData, } from "src/api/dashboard/dashboardService";
import { Spin } from "src/components/base";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { LeftRowData, MiddleRowData, RightRowData } from "../Common";
export const CollectorRankings = ({ collectionPoints, collectorsData }) => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // Left Data
    const [leftData, setLeftData] = useState({});
    // Middle Data
    const [middleData, setMiddleData] = useState({});
    // Right Data
    const [rightData, setRightData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getCollectorRankingsLeftRowData(location, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setLeftData(result?.data);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getCollectorRankingsMiddleRowData(location, isMonthView, selectYear, userInfo.accessToken);
        if (result.status === "success") {
            setMiddleData(result?.data);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getCollectorRankingsRightRowData(location, startDate, endDate, userInfo.accessToken);
        if (result.status === "success") {
            setRightData(result?.data);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsxs(HeaderTable, { title: "Collector Rankings", collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-collector-ranking.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                        position: "absolute",
                        top: "50%",
                        left: "40vw",
                    } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsx(LeftData, { title: "Rankings", children: collectorsData?.map((collector) => (_jsx(LeftRowData, { title: collector.name, data: leftData[collector.name] || [0, 0, 0, 0] }, collector.name))) }), _jsx(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: Object.entries(middleData)?.map(([collector, monthlyCounts]) => (_jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(monthlyCounts) }, collector))) }), _jsx(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: collectorsData?.map((collector) => (_jsx(RightRowData, { value: rightData?.[collector.name] || 0 }, collector.name))) })] })] }));
};
