import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Dropdown, Image, Menu, Space, Table } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
// Archive Devices view common for all device type
// Few fields modified based on the device type
export const Archieve = ({ name, icon, data, deleteTD, restoreTD }) => {
    const navigate = useNavigate();
    const cleanedName = name.replace(/^Archived\s/, "");
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(Space, { children: _jsx("a", { onClick: () => {
                        navigate(`/testing-devices/AddNew?name=${cleanedName}`, {
                            state: { record },
                        });
                    }, style: { color: "#2A44FF", textDecoration: "underline" }, children: text }) })),
        },
        cleanedName === "Breathalysers"
            ? {
                title: "Serial Number",
                dataIndex: "serialNumber",
                key: "serialNumber",
                sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
            }
            : {
                title: "Lot No.",
                dataIndex: "lotNumber",
                key: "lotNumber",
                sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            },
        cleanedName === "Breathalysers"
            ? {
                title: "Certificate No.",
                dataIndex: "certificateNumber",
                key: "certificateNumber",
                sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            }
            : {},
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: () => (_jsx(Space, { children: _jsx("div", { style: {
                        width: "100px",
                        height: "25px",
                        background: "#83ABBB",
                        fontSize: "13px",
                        color: "#FFFFFF",
                        paddingTop: "2px",
                        borderRadius: "15px",
                        textAlign: "center",
                    }, children: "Archived" }) })),
        },
        {
            title: "Summary Sheet",
            dataIndex: "artgSummarySheetUrl",
            key: "artgSummarySheetUrl",
            render: (record) => (_jsx(Button, { style: {
                    padding: "0px",
                    border: "none",
                    background: "none",
                }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
        },
        cleanedName === "Breathalysers"
            ? {
                title: "Certificate",
                dataIndex: "calibrationCertificateUrl",
                key: "calibrationCertificateUrl",
                render: (text, record) => (_jsx(Space, { children: record.calibrationCertificateUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            }
            : {
                title: "Laboratory Verification",
                dataIndex: "laboratorySheetUrl",
                key: "laboratorySheetUrl",
                render: (record) => (_jsx(Button, { style: {
                        padding: "0px",
                        border: "none",
                        background: "none",
                    }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            },
        cleanedName === "Breathalysers"
            ? {
                title: "Report",
                dataIndex: "deviceManualUrl",
                key: "deviceManualUrl",
                render: (text, record) => (_jsx(Space, { children: record.deviceManualUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            }
            : {
                title: "Specification sheet",
                dataIndex: "specificationSheetUrl",
                key: "specificationSheetUrl",
                render: (record) => (_jsx(Button, { style: {
                        padding: "0px",
                        border: "none",
                        background: "none",
                    }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })),
            },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => navigate(`/testing-devices/AddNew?name=${cleanedName}`, {
                                state: { record },
                            }), children: "View Details" }, "1"), _jsx(Menu.Item, { onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, `${record.deviceName} - Lot No. ${record.lotNumber} will be restored and available for use.`, () => {
                                    restoreTD(record.id);
                                });
                            }, children: "Restore" }, "2"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.deviceName} - Lot No. ${record.lotNumber} will be removed permanently and no longer can be used by collectors?`, () => {
                                    deleteTD(record.id);
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Delete" }) }, "3")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    const [tableVisible, setTableVisible] = useState(false);
    const { openMessageBox } = useContext(ContentContext);
    const handleButtonClick = () => {
        setTableVisible(!tableVisible);
    };
    if (cleanedName !== "Breathalysers") {
        columns.splice(3, 1);
        if (cleanedName === "Oral Fluid Drug Screen Devices" ||
            cleanedName === "Oral Fluid Collection Devices") {
            columns.splice(4, 1);
        }
    }
    else {
        columns.splice(5, 1);
    }
    return (_jsxs("div", { style: { marginTop: 30 }, children: [_jsxs(Button, { onClick: handleButtonClick, style: {
                    border: `1px solid #D9D9D9`,
                    borderRadius: "4px",
                    marginBottom: 10,
                    background: `${SAColourScheme.PLASSMA10}`,
                }, children: [_jsx("span", { style: { color: `${mainAppColours.MID_BLUE}` }, children: tableVisible ? "Hide Archive" : "Show Archive" }), _jsx("span", { style: { marginLeft: 12 }, children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx("div", { style: {
                    borderBottom: "2px solid #01B4D2",
                    marginBottom: "20px",
                } }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "flex-start",
                    visibility: tableVisible ? "visible" : "hidden",
                    marginBottom: "10px",
                    alignItems: "center",
                }, children: [_jsx(Image, { src: icon, height: 30, width: 30, preview: false }), _jsx("span", { style: { marginLeft: "10px", fontSize: "16px", color: "#000000" }, children: name })] }), _jsx(Table, { columns: columns, dataSource: data.length > 0 ? data.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { y: 650 }, style: {
                    border: "1px solid #D9D9D9",
                    visibility: tableVisible ? "visible" : "hidden",
                    overflow: "hidden",
                    height: tableVisible ? "100%" : "0",
                }, footer: () => "" })] }));
};
