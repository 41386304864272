/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { env } from "src/constants";
import { makeFetchAction } from "src/utils/apiRedux";
import { searchData } from "src/api/database";
import { throwHttpRequestError } from "src/infra/http";
export const getPatientWebsByCenterId = makeFetchAction("getPatientWebsByCenterId", (collectionOrganizationId) => ({
    requestHandler: async () => {
        const users = await searchData("users", [
            {
                fieldPath: "role",
                opStr: "==",
                value: "patient",
            },
        ]);
        const patients = await searchData("patients", [
            {
                fieldPath: "collectionOrganizationId",
                opStr: "==",
                value: collectionOrganizationId,
            },
        ]);
        const usersWithEmail = patients.map((patient) => {
            const { email } = users.find((user) => user.id === patient.userId);
            return { ...patient, email };
        });
        return { data: { data: usersWithEmail } };
    },
}));
const API_URL = env.DOMAIN;
export const checkEmailAndMobileIsExist = async (email) => {
    const requestBody = { email: email };
    try {
        const response = await axios.post(`${API_URL}/collection-manager/account/forgot-password/get-phone`, requestBody, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const sendPasswordVerificationCode = async (email, isSendToPhone) => {
    const requestBody = { email: email, isSendToPhone: isSendToPhone };
    try {
        const response = await axios.post(`${API_URL}/collection-manager/account/ask-forgot-password`, requestBody, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const verifyVerificationCode = async (email, code) => {
    const requestBody = { email: email, code: code };
    try {
        const response = await axios.post(`${API_URL}/collection-manager/account/forgot-password/check-code`, requestBody, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const resetPassword = async (password, timezone, isSignOutAllDevice) => {
    const requestBody = {
        customToken: sessionStorage.getItem("customToken"),
        password: password,
        timezone: timezone,
        isSignOutAllDevice: isSignOutAllDevice,
    };
    try {
        const response = await axios.put(`${API_URL}/collection-manager/account/forgot-password/change-password`, requestBody, {
            headers: { "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const activateAccount = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/account/active`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const askChangePassword = async (password, idToken) => {
    const requestBody = {
        password: password,
    };
    try {
        const response = await axios.post(`${API_URL}/collection-manager/account/ask-change-password`, requestBody, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const changePassword = makeFetchAction("changePassword", (idToken, customToken, password) => ({
    requestHandler: async () => await axios.put(`${API_URL}/collection-manager/account/change-password`, { password: password, customTokenId: customToken, timezone: "Australia/Sydney" }, { headers: { idToken, "Content-Type": "application/json" } }),
}));
export const updateUserProfile = makeFetchAction("updateUserProfile", (requestBody, idToken) => ({
    requestHandler: async () => {
        const updateStatus = await axios.put(`${API_URL}/collection-manager/user/information`, requestBody, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return { data: { data: updateStatus } };
    },
}));
export const getUserInformation = makeFetchAction("getUserInformation", (idToken) => ({
    requestHandler: async () => {
        const userInformation = await axios.get(`${API_URL}/collection-manager/user/information`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return { data: { data: userInformation } };
    },
}));
export const deleteUser = makeFetchAction("deleteUser", (requestBody, idToken) => ({
    requestHandler: async () => {
        const result = await axios.post(`${API_URL}/collection-manager/account/delete-account/ask`, requestBody, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return { data: { data: result } };
    },
}));
export const verifyPassword = makeFetchAction("verifyPassword", (requestBody, idToken) => ({
    requestHandler: async () => {
        const result = await axios.post(`${API_URL}/collection-manager/account/verify-password`, requestBody, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return { data: { data: result } };
    },
}));
