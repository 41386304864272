import { getDownloadURL, getStorage, ref, uploadString, deleteObject } from "firebase/storage";
import { uuidv4 } from "@antv/xflow-core";
import { app } from "src/firebase";
const storage = getStorage(app);
export const uploadImage = async (storagePath, file, fileExt) => {
    const fileName = uuidv4();
    const profilePictureRef = ref(storage, `${storagePath}/${fileName}.${fileExt}`);
    const result = await uploadString(profilePictureRef, file, "data_url");
    const url = await getDownloadURL(result.ref);
    return url;
};
export const uploadFile = async (storagePath, file, fileExt) => {
    const fileName = uuidv4();
    const pdfRef = ref(storage, `${storagePath}/${fileName}.${fileExt}`);
    const result = await uploadString(pdfRef, file, "data_url");
    const url = await getDownloadURL(result.ref);
    return url;
};
// https://firebase.google.com/docs/storage/web/delete-files
export const deleteImage = async (imgUrl) => {
    // Create a reference to the file to delete
    const desertRef = ref(storage, imgUrl);
    // Delete the file
    deleteObject(desertRef)
        .then(() => {
        console.warn("File deleted successfully");
        //
    })
        .catch((error) => {
        console.error("Uh-oh, an error occurred!", error);
        //
    });
};
