import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Space, Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ICEdit from "/public/assets/svg/inventory/ic-edit.svg";
import ICCancel from "/public/assets/svg/inventory/ic-cancel.svg";
import ICSave from "/public/assets/svg/inventory/ic-save.svg";
import { checkAuth } from "src/api/auth/login";
import ContentContext from "src/providers/content";
import { createInventory, updateInventory } from "src/api/inventory/inventoryService";
export const SiteContactsTable = ({ inventoryData }) => {
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const userInfo = useSelector(checkAuth.dataSelector);
    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(null); // Track the index of the record being edited
    const handleEditClick = (index) => {
        setEditMode(true);
        setEditIndex(index);
    };
    const handleCancelClick = () => {
        setEditMode(false);
        setEditIndex(null);
        setData(inventoryData);
    };
    const handleSaveClick = async () => {
        setEditMode(false);
        if (editIndex !== null && editIndex >= 0 && editIndex < data.length) {
            const combinedData = data[editIndex];
            openLoadingScreen();
            try {
                if (combinedData.id === "") {
                    // If the item doesn't have an id, it's a new inventory item
                    const response = await createInventory(userInfo.accessToken, combinedData);
                    if (response.status !== "success") {
                        throw new Error("Failed to create inventory");
                    }
                }
                else {
                    // If the item has an id, it's an existing inventory item
                    const response = await updateInventory(userInfo.accessToken, combinedData.id, combinedData);
                    if (response.status !== "success") {
                        throw new Error("Failed to update inventory");
                    }
                }
                openToast("Saved successfully");
                setEditMode(false);
            }
            catch (error) {
                openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again.");
            }
            finally {
                closeLoadingScreen();
            }
        }
        setEditIndex(null);
    };
    const handleInputChange = (value, dataIndex, recordIndex) => {
        const newData = [...data];
        newData[recordIndex][dataIndex] = value;
        setData(newData);
    };
    useEffect(() => {
        setData(inventoryData);
    }, [inventoryData]);
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "User Defined Location Label",
            dataIndex: "userDefinedName",
            key: "userDefinedName",
            sorter: (a, b) => a.userDefinedName.localeCompare(b.userDefinedName),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "userDefinedName", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Collection point Name",
            dataIndex: "collectionPointName",
            key: "collectionPointName",
            sorter: (a, b) => a.userDefinedName.localeCompare(b.userDefinedName),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Contact Name",
            dataIndex: "contactName",
            key: "contactName",
            sorter: (a, b) => a.contactName.localeCompare(b.contactName),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "contactName", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Contact Mobile",
            dataIndex: "contactMobile",
            key: "contactMobile",
            sorter: (a, b) => a.contactMobile.localeCompare(b.contactMobile),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "contactMobile", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "email", index) }) })) : (_jsx(_Fragment, { children: text })) })),
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "address", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            width: 100,
            render: (_, record, index) => (_jsx(_Fragment, { children: editMode && editIndex === index ? (_jsxs(_Fragment, { children: [_jsx(ICCancel, { onClick: handleCancelClick, style: { cursor: "pointer", marginRight: "10px" } }), _jsx(ICSave, { onClick: handleSaveClick, style: { cursor: "pointer" } })] })) : (_jsx(ICEdit, { onClick: () => handleEditClick(index), style: { cursor: "pointer" } })) })),
        },
    ];
    return (_jsx("div", { children: _jsx(Table, { columns: columns, dataSource: data.length > 0 ? data.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                border: "1px solid #D9D9D9",
                overflow: "hidden",
                marginTop: "10px",
            } }) }));
};
