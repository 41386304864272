import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getCollectionManagerSetting = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/setting/cm`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateCollectionManagerSetting = async (idToken, requestBody) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/setting/cm`, requestBody, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateCommunicationSetting = async (idToken, requestBody) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/setting/cm/communication`, requestBody, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateGeoSetting = async (idToken, requestBody) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/setting/cm/geoFence`, requestBody, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateCalendarSetting = async (idToken, requestBody) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/setting/cm/calendar`, requestBody, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
