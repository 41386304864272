import axios from "axios";
import { getById } from "src/api/database";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
import { makeFetchAction } from "src/utils/apiRedux";
import * as collectionDB from "./collectionDatabase";
export const getCollectionCentreById = makeFetchAction("getCollectionCentreById", (id) => ({
    requestHandler: async () => {
        const collectionCentre = await collectionDB.getById(id);
        return { data: { data: collectionCentre } };
    },
}));
export const getCollectionPointByCentreId = makeFetchAction("getCollectionPointByCentreId", (collectionOrganizationId) => ({
    requestHandler: async () => {
        const collectionPoints = await collectionDB.getCollectionPointByCentreId(collectionOrganizationId);
        return { data: { data: collectionPoints } };
    },
}));
export const getCollectionOrganization = async (collectionOrganizationId) => await getById("collectionOrganization", collectionOrganizationId);
const API_URL = env.DOMAIN;
export const getCollectionOrganizationDetails = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/collection-organization/detail`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const UpdateOrganizationDetails = async (idToken, organizationDetail, addedCollectionPoint, existedCollectionPoint) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/collection-organization/update`, {
            organizationDetail: organizationDetail,
            newCollectionPoints: addedCollectionPoint,
            oldCollectionPoints: existedCollectionPoint,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const UpdateLaboratoryDetails = async (idToken, addedLaboratories, existedLaboratories) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/collection-organization/laboratory`, {
            newLaboratories: addedLaboratories,
            oldLaboratories: existedLaboratories,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const deleteOrganizationCollectionPoint = async (idToken, collectionPointId) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/collection-organization/collection-point/delete/${collectionPointId}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const deleteLab = async (idToken, labId) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/collection-organization/laboratory/delete/${labId}`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getCollectionCentreByUUID = makeFetchAction("getCollectionCentreByUUID", (collectionCentreUUID) => ({
    requestHandler: async () => {
        const collectionCentre = await getById("collectionOrganization", collectionCentreUUID);
        return {
            data: {
                data: collectionCentre,
            },
        };
    },
}));
