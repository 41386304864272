import styled from "styled-components";
import { SAColourScheme, mainAppColours } from "src/constants";
export const GridContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;
`;
export const Card = styled.div `
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const CardWrapper = styled.div ` {
margin-top: 10px;

.cover-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: 20px;
}

.card-item {
  background: #f7f8f8;
  border-top: 10px solid #ebedf0;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-row {
  margin-top: 5px;
}


.ant-card-bordered {
  border: none;
}

.ant-card-body {
  padding: 20px;
}
.card-heading {
  font-size: 11px;
  color: #4B5767;
}

.card-text {
  font-size: 14px;
  color: #000000;
}

.card-files {
  padding: 0px;
border: none;
background: none;
}

.menu-options {
  cursor: pointer;
  background-color: ${SAColourScheme.WHITE}
  border-radius: 50%;
}

.options-dropdown {
  border: 1px solid ${mainAppColours.MID_BLUE};
}

}`;
