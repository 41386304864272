import axios from "axios";
import { env } from "src/constants";
import { makeFetchAction } from "src/utils/apiRedux";
import { throwHttpRequestError } from "src/infra/http";
import { addRequestTestType, getAppointmentByUid, getAppointments, getRequestTestCategories, getRequestTestParents, } from "./appointmentDatabase";
export const getAppointmentTypes = makeFetchAction("getAppointmentTypes", () => ({
    requestHandler: async () => {
        const appointmentTypes = await getRequestTestParents();
        return { data: { data: appointmentTypes } };
    },
}));
export const getAppointmentsTestTypes = makeFetchAction("getAppointmentsTestTypes", () => ({
    requestHandler: async () => {
        const appointmentTypes = await getRequestTestCategories();
        return { data: { data: appointmentTypes } };
    },
}));
export const addAppointmentsTestType = makeFetchAction("addAppointmentsTestType", (testName) => ({
    requestHandler: async () => {
        const createdData = await addRequestTestType(testName);
        return { data: { data: createdData } };
    },
}));
export const getAppointmentById = makeFetchAction("getAppointmentById", (uid) => ({
    requestHandler: async () => {
        const result = await getAppointmentByUid(uid);
        return { data: { data: result } };
    },
}));
export const getApmtsByDate = makeFetchAction("getApmtsByDate", (collectionCentreUUID = null, fromDate = null, toDate = null) => ({
    requestHandler: async () => {
        const appointments = await getAppointments(collectionCentreUUID, fromDate, toDate);
        return { data: { data: appointments } };
    },
}));
const API_URL = env.DOMAIN;
export const createAppointment = async (requestBody, idToken) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/appointment/create`, requestBody, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const cancelAppointmentService = async (appointmentId, idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/appointment/cancel/${appointmentId}`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response?.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return {
            status: "fail",
        };
    }
};
export const restoreAppointmentService = async (appointmentId, idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/appointment/restore/${appointmentId}`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response?.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return {
            status: "fail",
        };
    }
};
export const resendEmailAppointmentService = async (appointmentId, idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/appointment/resend-email/${appointmentId}`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response?.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return {
            status: "fail",
        };
    }
};
export const updateAppointmentService = async (appointmentId, bodyUpdate, idToken) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/appointment/update/${appointmentId}`, bodyUpdate, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response?.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return {
            status: "fail",
        };
    }
};
export const getAppointmentInfoById = async (appointmentId, idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/appointment/${appointmentId}`, {
            headers: {
                idToken,
                "Content-Type": "application/json",
            },
        });
        return response.data.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return {};
    }
};
