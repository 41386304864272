import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { $get, $post } from "src/infra/http";
export const getAuthorityInformation = createAsyncThunk("getAuthorityInformation", async () => await $get(`/collection-manager/authority/`));
export const updateAuthorityInformation = createAsyncThunk("updateAuthorityInformation", async (data) => await $post(`/collection-manager/authority/`, { ...data }));
export const verifyPin = createAsyncThunk("authorities/verifyPin", async ({ pin }) => {
    const response = await axios.post(`/collection-manager/authority/verify-pin/`, {
        pin,
    });
    return response.data;
});
export const updateSignature = createAsyncThunk("authorities/updateSignature", async ({ signature }) => {
    const response = await axios.put(`/collection-manager/authority/update-signature/`, {
        signature,
    });
    return response.data;
});
export const updatePin = createAsyncThunk("authorities/updatePin", async ({ oldPin, newPin }) => {
    const response = await axios.put(`/collection-manager/authority/update-pin/`, {
        oldPin,
        newPin,
    });
    return response.data;
});
export const createPin = createAsyncThunk("authorities/createPin", async ({ newPin }) => {
    const response = await axios.post(`/collection-manager/authority/create-pin/`, {
        pin: newPin,
    });
    return response.data;
});
