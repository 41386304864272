import styled from "styled-components";
import { SAColourScheme, mainAppColours } from "src/constants";
export const TestingDevicesWrapper = styled.div `
  background-color: ${SAColourScheme.WHITE};

  .menu-options {
    cursor: pointer;
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .ant-table-footer {
    background-color: ${SAColourScheme.PLASSMA10};
    border-top: 1px solid #d9d9d9;
  }
`;
