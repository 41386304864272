import { getIdTokenResult } from "@firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { activateAccount } from "src/api/user/userService";
import { auth } from "src/firebase";
import { makeFetchAction } from "src/utils/apiRedux";
export const loginUser = makeFetchAction("loginUser", (email, password) => ({
    requestHandler: async () => {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const idToken = await getIdTokenResult(userCredential.user);
        const { userStatus } = idToken.claims;
        if (!userStatus) {
            await activateAccount(idToken.token);
        }
        // TODO: implement user activation when the userStatus is ready in the JWT token
        // TODO: route user to the Collection Centre page when organizationStatus is ready in the JWT token
        return { data: { data: { idToken } } };
    },
}));
export const checkAuth = makeFetchAction("checkAuth", () => ({
    requestHandler: async () => {
        const user = await new Promise((resolve, reject) => {
            auth.onAuthStateChanged(async (currentUser) => {
                if (currentUser) {
                    const idToken = await getIdTokenResult(currentUser, true);
                    const userInfo = idToken.claims;
                    resolve({
                        ...userInfo,
                        accessToken: idToken.token,
                        screens: [],
                    });
                }
                return reject(new Error("User is not logged in"));
            }, (error) => {
                reject(error);
            });
        });
        return { data: { data: user } };
    },
}));
