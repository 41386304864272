import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { deleteClientRepresentative, deleteClientRepresentativeFile, getListClientRepresentative, updateAuthorisedRep, } from "./action";
export const initialState = {
    listClientRepresentative: [],
    listClientRepresentativeFiles: [],
    isEdit: false,
    isUploading: false,
    validationFail: false,
};
const ClientRepresentativeSlice = createSlice({
    name: "ClientRepresentative",
    initialState,
    reducers: {
        setRepresentatives(state, action) {
            state.listClientRepresentative = action.payload;
        },
        setFiles(state, action) {
            state.listClientRepresentativeFiles = action.payload;
        },
        setIsEdit(state, action) {
            state.isEdit = action.payload;
        },
        setIsUploading(state, action) {
            state.isUploading = action.payload;
        },
        setValidationFail(state, action) {
            state.validationFail = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClientRepresentative.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listClientRepresentative = initialState.listClientRepresentative;
                state.listClientRepresentativeFiles = initialState.listClientRepresentativeFiles;
                message.error("Failed to fetch list of client ClientRepresentative");
            }
            else {
                state.listClientRepresentative = action.payload.data.representativesData;
                state.listClientRepresentativeFiles = action.payload.data.filesData;
            }
        })
            .addCase(getListClientRepresentative.rejected, () => {
            message.error("Failed to fetch list of client representatives");
        })
            .addCase(updateAuthorisedRep.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update data");
            }
            else {
                message.success("Representative data updated successfully");
            }
        })
            .addCase(updateAuthorisedRep.rejected, () => {
            message.error("Failed to update data");
        })
            .addCase(deleteClientRepresentative.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete the representative");
            }
            else {
                message.success("Representative deleted successfully");
            }
        })
            .addCase(deleteClientRepresentative.rejected, () => {
            message.error("Failed to delete the representative");
        })
            .addCase(deleteClientRepresentativeFile.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete file");
            }
            else {
                message.success("File deleted successfully");
            }
        })
            .addCase(deleteClientRepresentativeFile.rejected, () => {
            message.error("Failed to delete file");
        });
    },
});
export const { setRepresentatives, setFiles, setIsEdit, setIsUploading, setValidationFail, setResetStore, } = ClientRepresentativeSlice.actions;
export default ClientRepresentativeSlice.reducer;
