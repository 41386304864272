import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { PageLoading } from "src/components/Loading/PageLoading";
import { checkAuthentication, getAppInformation } from "src/store/app/action";
import { getApplicationInformation, getAuthStatus } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
sessionStorage.setItem("errorMessage", "");
const PrivatePage = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isAuth = useAppSelector(getAuthStatus);
    const app = useAppSelector(getApplicationInformation);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        const checkingAuth = async () => {
            await dispatch(checkAuthentication());
            // TODO: this is old store which should be removed later
            await checkAuth.actionCreator();
            setIsLoading(false);
        };
        checkingAuth();
    }, []);
    useEffect(() => {
        const loadAppInformation = async () => {
            if (isAuth && !app.collectionOrganization.collectionName)
                dispatch(getAppInformation());
        };
        loadAppInformation();
    }, [isAuth]);
    if (!isAuth && isLoading)
        return _jsx(PageLoading, {});
    if (isAuth)
        return _jsx(Outlet, {});
    else
        return _jsx(Navigate, { to: "/login", state: { preLocation: location } });
};
export default PrivatePage;
