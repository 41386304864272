import axios from "axios";
import { makeFetchAction } from "src/utils/apiRedux";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const faqDataListCM = makeFetchAction("faqDataListCM", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListPA = makeFetchAction("faqDataListPA", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListCA = makeFetchAction("faqDataListCA", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListRTOMedical = makeFetchAction("faqDataListRTOMedical", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const getFAQList = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/FAQ/faq`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return [];
    }
};
export const getQuestionList = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/FAQ/questions`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
        return [];
    }
};
export const createSupportQuestion = async (idToken, formData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/FAQ/faq-support-create`, formData, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
