const selectClientRepresentativeList = (state) => {
    const representatives = state.clientManagementPage.clientRepresentativePage.listClientRepresentative;
    return representatives.map((representative, index) => ({
        ...representative,
        index: index + 1,
    }));
};
const selectClientRepresentativeFileList = (state) => state.clientManagementPage.clientRepresentativePage.listClientRepresentativeFiles;
const selectIsEdit = (state) => state.clientManagementPage.clientRepresentativePage.isEdit;
const selectIsUploading = (state) => state.clientManagementPage.clientRepresentativePage.isUploading;
const selectValidationFail = (state) => state.clientManagementPage.clientRepresentativePage.validationFail;
export { selectClientRepresentativeList, selectClientRepresentativeFileList, selectIsEdit, selectIsUploading, selectValidationFail, };
