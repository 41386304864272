import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from "antd/es/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { getTestingDeviceHistory } from "src/api/testingDevices/testingDeviceService";
import { Image, Space } from "src/components/base";
import { PageWrapper } from "../index.style";
export const History = ({ id }) => {
    const userInfo = useSelector(checkAuth.dataSelector);
    const [data, setData] = useState([]);
    //Ge the device history data based on the device Id
    const getDeviceHistory = async () => {
        const result = await getTestingDeviceHistory(userInfo.accessToken, id);
        if (result.status === "success") {
            setData(result.data);
        }
        else {
            setData([]);
        }
    };
    useEffect(() => {
        getDeviceHistory();
    }, []);
    const columns = [
        {
            title: "No.",
            dataIndex: "number",
            width: 40,
            display: true,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            titleType: "button",
            dataIndex: "deviceName",
            display: true,
        },
        {
            title: "Serial Number",
            titleType: "button",
            dataIndex: "serialNumber",
            display: true,
        },
        {
            title: "Certificate No.",
            titleType: "button",
            dataIndex: "certificateNumber",
            display: true,
        },
        {
            title: "Calibration Date",
            titleType: "button",
            dataIndex: "calibrationAt",
            display: true,
            render: (text, record) => (_jsx(Space, { children: new Date(record.calibrationAt).toLocaleDateString("en-AU") })),
        },
        {
            title: "Certificate",
            dataIndex: "certificate",
            display: true,
            render: (_, record) => record.calibrationCertificateUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })),
        },
        {
            title: "Report",
            dataIndex: "report",
            display: true,
            render: (_, record) => record.calibrationReportUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })),
        },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx("div", { style: { float: "right", marginTop: "10px" }, children: _jsx("div", { style: {
                        background: "#83ABBB",
                        borderRadius: "8px 8px 0 0",
                        padding: "10px",
                    }, children: _jsx("div", { style: {
                            width: "158px",
                            color: "#FFFFFF",
                            fontSize: "13px",
                            fontWeight: "700",
                            textAlign: "center",
                        }, children: "Calibration History" }) }) }), _jsx(Table, { className: "history-device-table", style: {
                    border: "1px solid #D9D9D9",
                    maxHeight: "450px",
                    overflow: "scroll",
                }, pagination: false, columns: columns, dataSource: data.length > 0 ? data : [] })] }));
};
