import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoCircleFilled } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Flag from "react-country-flag";
import { checkAuth } from "src/api/auth/login";
import { deleteOrganizationCollectionPoint, UpdateOrganizationDetails, } from "src/api/collection/collectionService";
import { Button, Col, Form, FormBox, Image, InputWithLabel, Row, Text } from "src/components/base";
import { mainAppColours, MESSAGE_BOX_TYPE, SAColourScheme, validateMessage } from "src/constants";
import ContentContext from "src/providers/content";
import { getAllCountryMapping, getAllTimezones } from "src/utils/helper";
import { CollectionCentreFormConfig } from "../config";
import { CollectionPointForm } from "./CollectionPointForm";
export const CollectionOrganisationForm = (props) => {
    const [form] = Form.useForm();
    const userInfo = useSelector(checkAuth.dataSelector);
    const { data } = props;
    const [countries, setCountries] = useState({});
    const [collectionPoint, setCollectionPoint] = useState([]);
    const [deletedCollectionPoint, setDeletedCollectionPoint] = useState([]);
    const [isNewCentre, setIsNewCentre] = useState(true);
    const [editCollectionCentre, setEditCollectionCentre] = useState(false);
    const [timezones, setTimezones] = useState([]);
    const [reloadComponent, setReloadComponent] = useState(false);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const getData = async () => {
        if (!data ||
            !data.collectionOrganizationDetail?.length ||
            !data?.collectionOrganizationDetail[0]?.email) {
            setIsNewCentre(true);
            if (data?.collectionOrganizationDetail?.length) {
                form.setFieldsValue({
                    collectionCentre: {
                        country: "Australia",
                        ...data?.collectionOrganizationDetail[0],
                    },
                });
            }
            else {
                form.setFieldsValue({
                    collectionCentre: {
                        country: "Australia",
                    },
                });
            }
        }
        else {
            setIsNewCentre(false);
            const getTimezones = getAllTimezones();
            const convertedTimeZones = getTimezones.map((timeZone) => {
                const matchResult = timeZone.match(/\) (.+)/);
                const extractedTimeZone = matchResult ? matchResult[1] : timeZone;
                return {
                    label: timeZone,
                    value: extractedTimeZone,
                };
            });
            setTimezones(convertedTimeZones);
            const collectionCentreData = data?.collectionOrganizationDetail[0];
            const collectionPointData = data.collectionPoints[0];
            const collectionCentre = collectionCentreData;
            const existCollectionPoints = collectionPointData;
            setCollectionPoint(existCollectionPoints);
            const fieldsValue = form.getFieldsValue();
            form.resetFields();
            form.setFieldsValue({
                ...fieldsValue,
                collectionCentre: {
                    ...collectionCentre,
                    listCollectionPoint: existCollectionPoints,
                },
            });
        }
    };
    useEffect(() => {
        getData();
        setCountries(getAllCountryMapping());
    }, [data, reloadComponent]);
    const addCollectionPoint = () => {
        setCollectionPoint(() => {
            const formValues = form.getFieldsValue();
            const newCollectionPoints = [...(formValues.collectionCentre?.listCollectionPoint ?? []), {}];
            form.resetFields();
            form.setFieldsValue({
                ...formValues,
                collectionCentre: {
                    ...formValues.collectionCentre,
                    listCollectionPoint: newCollectionPoints,
                },
            });
            return newCollectionPoints;
        });
    };
    const deleteCollectionPoint = (index, point) => {
        setDeletedCollectionPoint((prevDeletedCP) => [...prevDeletedCP, point]);
        setCollectionPoint(() => {
            const fieldsValue = form.getFieldsValue();
            let newCollectionPoints = fieldsValue.collectionCentre.listCollectionPoint.filter((_, i) => i !== index);
            if (newCollectionPoints.length === 0) {
                newCollectionPoints = [];
            }
            form.resetFields();
            form.setFieldsValue({
                ...fieldsValue,
                collectionCentre: Object.assign(fieldsValue.collectionCentre, {
                    listCollectionPoint: newCollectionPoints,
                }),
            });
            return newCollectionPoints;
        });
    };
    const onSaveEdit = async () => {
        // openLoadingScreen();
        setEditCollectionCentre(false);
        openLoadingScreen();
        const { collectionCentre } = form.getFieldsValue();
        const processCollectionPoint = async () => {
            const { listCollectionPoint } = collectionCentre;
            const addedCollectionPoint = [...(listCollectionPoint ?? [])].filter((item) => item && !item.id);
            const existedCollectionPoint = [...(listCollectionPoint ?? [])].filter((item) => item &&
                !deletedCollectionPoint.flatMap((i) => i.id).includes(item.id) &&
                item.id);
            if (deletedCollectionPoint.length > 0) {
                const deleteRequests = deletedCollectionPoint.map(async (item) => {
                    const collectionPointId = item.id;
                    try {
                        await deleteOrganizationCollectionPoint(userInfo.accessToken, collectionPointId);
                    }
                    catch (error) {
                        console.error(error);
                    }
                });
                try {
                    await Promise.all(deleteRequests);
                }
                catch (error) {
                    console.error("One or more delete requests failed", error);
                    closeLoadingScreen();
                }
            }
            return {
                existedCollectionPoint,
                addedCollectionPoint,
            };
        };
        const { existedCollectionPoint, addedCollectionPoint } = await processCollectionPoint();
        const keysToRemove = ["listCollectionPoint", "uuid", "id"];
        const organizationDetail = Object.fromEntries(Object.entries(collectionCentre).filter(([key]) => !keysToRemove.includes(key)));
        const result = await UpdateOrganizationDetails(userInfo.accessToken, organizationDetail, addedCollectionPoint, existedCollectionPoint);
        if (result.status === "success") {
            closeLoadingScreen();
            openToast("Saved successfully");
        }
        else {
            closeLoadingScreen();
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, "Something went wrong. Please try again");
        }
    };
    const onCancelEdit = () => {
        setEditCollectionCentre(false);
        setReloadComponent(true);
    };
    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }
    // TODO remove custom onInputChange in InputWithLabel component
    const onInputChange = () => () => void 0;
    const renderForm = () => CollectionCentreFormConfig.map((row, rowIndex) => (_jsx(Row, { gutter: [10, 10], children: row.map((col, colIndex) => col.label !== "Country" ? (_jsx(Col, { offset: col.offset, span: col.col, children: _jsx(InputWithLabel, { label: col.label, field: col.field, fieldKey: col.fieldKey, onInputChange: onInputChange, inputProps: {
                    disabled: !editCollectionCentre,
                }, rules: [
                    {
                        required: col?.require ?? true,
                        type: col?.type,
                        pattern: col?.pattern,
                    },
                ], className: col.class ?? "" }) }, `form-col-${rowIndex}-${colIndex}`)) : (_jsx(Col, { offset: col.offset, span: col.col, children: _jsx(InputWithLabel, { label: col.label, field: col.field, fieldKey: col.fieldKey, onInputChange: onInputChange, inputProps: {
                    type: "select",
                    disabled: !editCollectionCentre,
                    options: Object.entries(countries).map(([_, name]) => ({
                        label: (_jsxs(_Fragment, { children: [" ", _jsx(Flag, { countryCode: getKeyByValue(countries, name) || "" }), " ", name, " "] })),
                        value: name,
                    })),
                    defaultValue: "Australia", // Replace "Australia" with your desired default country
                }, rules: [
                    {
                        required: col?.require ?? true,
                        type: col?.type,
                        pattern: col?.pattern,
                    },
                ], className: col.class ?? "" }) }, `form-col-${rowIndex}-${colIndex}`))) }, `form-row-${rowIndex}`)));
    return (_jsx(Form, { form: form, validateMessages: validateMessage, children: _jsxs(FormBox, { title: "Collection Organisation", backgroundColor: SAColourScheme.MID_BLUE, icon: "/assets/svg/ic-cm-collection-organisation.svg", titleComponent: 
            // <div style={{display: "flex", alignItems:"center", width:"100%", flexWrap:"wrap", marginTop:"10px"}}>
            _jsxs("div", { className: "form-box-title", children: [_jsx(InputWithLabel, { className: "title-input", field: "collectionCentre", fieldKey: ["collectionName"], 
                        // onInputChange={onInputChange}
                        inputProps: {
                            disabled: true,
                        } }), _jsx("div", { className: "title-action", children: !editCollectionCentre ? (_jsxs(Button, { onClick: () => {
                                setEditCollectionCentre(true);
                            }, disabled: isNewCentre, children: [_jsx(Image, { src: "/assets/svg/ic-form-edit.svg", style: { width: 20, marginRight: 8 }, preview: false }), "Edit"] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { className: "btn-cancel", onClick: () => {
                                        onCancelEdit();
                                    }, children: "Cancel" }), _jsx(Button, { className: "btn-save", onClick: () => {
                                        form.validateFields().then(() => {
                                            onSaveEdit();
                                            // onSaveEdit();
                                        });
                                    }, children: "Save" })] })) })] }), children: [_jsxs("div", { className: "form-centre-info", style: {
                        backgroundColor: editCollectionCentre ? SAColourScheme.WHITE : "#e4f6fb",
                    }, children: [renderForm(), isNewCentre && (_jsx(_Fragment, { children: _jsxs("div", { className: "start-panel", children: [_jsx(InfoCircleFilled, { style: {
                                            fontSize: 52,
                                            color: mainAppColours.BLUE,
                                            marginBottom: 30,
                                        } }), _jsx(Text, { className: "start-text", name: "Please complete Collection Organisation details." }), _jsx(Button, { className: "start-button", onClick: () => {
                                            setIsNewCentre(false);
                                            setEditCollectionCentre(true);
                                        }, children: "Start" })] }) }))] }), _jsx(InputWithLabel, { label: "ID", field: "collectionCentre", fieldKey: ["id"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } }), _jsx(InputWithLabel, { label: "UUID", field: "collectionCentre", fieldKey: ["uuid"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } }), _jsx(Row, { gutter: [20, 10], children: collectionPoint.map((item, index) => (_jsx(CollectionPointForm, { id: item.id, index: index, form: form, timezones: timezones, collectionPoint: item, onInputChange: onInputChange, editCollectionCentre: editCollectionCentre, setCollectionPoints: setCollectionPoint, deleteCollectionPoint: deleteCollectionPoint }, index))) }), editCollectionCentre && (_jsx(Row, { gutter: [20, 10], justify: "end", children: _jsx(Col, { children: _jsx(Button, { className: "add-collection-point", onClick: () => {
                                addCollectionPoint();
                            }, children: "Add Collection Point" }) }) }))] }) }));
};
