import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DatePicker, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftArrowIcon from "src/assets/svg/collection-reports/left-arrow-icon.svg";
import RightArrowIcon from "src/assets/svg/collection-reports/right-arrow-icon.svg";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { SAColourScheme } from "src/constants";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { formatToTwoDigits } from "src/utils/helper";
import MailIcon from "src/assets/svg/collection-reports/mail-icon.svg";
import { TimeFormat } from "src/store/app/types";
import StatusCard from "../components/StatusCard";
import { getListOnsiteCollectionReports } from "../store/action";
import { selectCollectionPoint, selectCurrentDate, selectListCollectionPoints, selectListOnsiteCollectionReports, } from "../store/selector";
import { setCollectionPoint, setCurrentDate, setResetStore } from "../store/slice";
import { CollectionReportStatusDetails } from "../typing";
import ReportTable from "./ReportTable";
const OnsiteReport = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentDate = useAppSelector(selectCurrentDate);
    const isLoading = useAppSelector(selectIsLoading);
    const collectionReports = useAppSelector(selectListOnsiteCollectionReports);
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const currentCollectionPoint = useAppSelector(selectCollectionPoint);
    const appInformation = useAppSelector(getApplicationInformation);
    useEffect(() => {
        dispatch(setResetStore());
    }, []);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListOnsiteCollectionReports());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [currentDate]);
    return (_jsxs(MainContainer, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(ContentContainer, { children: [_jsx(SAColumn, { lg: 8, md: 24, children: _jsxs(SARow, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(CustomButton, { onClick: () => {
                                                    dispatch(setCurrentDate(moment().toISOString()));
                                                }, children: "Today" }) }), _jsx(SAColumn, { span: 18, children: _jsxs(NavigationDate, { children: [_jsx(Button, { onClick: () => {
                                                            dispatch(setCurrentDate(moment(currentDate).subtract(1, "days").toISOString()));
                                                        }, children: _jsx(LeftArrowIcon, {}) }), _jsx(TitleBox, { format: "dd, DD MMM YYYY", value: moment(currentDate), suffixIcon: null, clearIcon: null, onChange: (date) => {
                                                            if (!date)
                                                                return;
                                                            dispatch(setCurrentDate(date.toISOString()));
                                                        } }), _jsx(Button, { onClick: () => {
                                                            dispatch(setCurrentDate(moment(currentDate).add(1, "days").toISOString()));
                                                        }, children: _jsx(RightArrowIcon, {}) })] }) })] }), _jsx(SARow, { children: _jsx(SAColumn, { span: 24, children: _jsx(CustomSelect, { placeholder: "Please select location", value: currentCollectionPoint.name, options: collectionPoints, onChange: (_, option) => {
                                                dispatch(setIsLoading(true));
                                                const selectedValue = option;
                                                dispatch(setCollectionPoint({
                                                    id: selectedValue.name,
                                                    collectionPointName: selectedValue.value,
                                                }));
                                                dispatch(getListOnsiteCollectionReports());
                                                dispatch(setIsLoading(false));
                                            } }) }) }), _jsx(SARow, { children: _jsx(SAColumn, { children: _jsx(ScrollContainer, { children: collectionReports.map((report, index) => (_jsx(StatusCard, { tagName: formatToTwoDigits(index + 1), mainHeading: report.clientName, subHeading: _jsxs(React.Fragment, { children: [_jsx(DonorLabel, { children: "Processed Donors" }), _jsx(DonorCount, { children: report.processedDonors })] }), location: report.clientLocation, status: _jsxs(StatusContainer, { children: [_jsx(MailIcon, { color: CollectionReportStatusDetails[report.status].color }), _jsx(StatusText, { color: CollectionReportStatusDetails[report.status].color, children: CollectionReportStatusDetails[report.status].message })] }), time: report.sentAt
                                                    ? moment(report.sentAt).format(appInformation?.collectionOrganization.timeFormat ===
                                                        TimeFormat.TwelveHour
                                                        ? "HH:mm a"
                                                        : "HH:mm")
                                                    : "", tagBg: CollectionReportStatusDetails[report.status].color, onClick: () => {
                                                    navigate(`/collection-reports/on-site/${report.clientSetupId}`);
                                                }, componentId: report.clientSetupId }, report.clientSetupId))) }) }) })] }) }), _jsx(SAColumn, { lg: 16, md: 24, children: _jsx(ReportTable, {}) })] })] }));
};
export default OnsiteReport;
const MainContainer = styled.div `
  background-color: #ffffff;
  height: 100%;
`;
const ContentContainer = styled(SARow) `
  height: 100%;
`;
const CustomButton = styled(Button) `
  background: #005277;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 100%;
`;
const ScrollContainer = styled.div `
  max-height: 600px;
  overflow-y: auto;
  width: 100%;
  padding: 0.1rem;
`;
const DonorLabel = styled.div `
  color: ${SAColourScheme.PLASMA};
  font-size: 0.75rem;
  margin-right: 0.625rem;
  font-weight: 700;
`;
const DonorCount = styled.div `
  font-size: 0.75rem;
`;
const StatusContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  width: fit-content;
  justify-content: center;
  height: 100%;
`;
const StatusText = styled.p `
  color: ${({ color }) => color ?? "#ffffff"};
`;
const NavigationDate = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  width: 100%;

  > button:nth-child(1) {
    background: #005277;
    border: 0;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  > button:last-child {
    background: #005277;
    border: 0;
    border-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;
const TitleBox = styled(DatePicker) `
  width: 100%;
  background: #005277;
  border: 0;
  border-radius: 0;
  height: 100%;

  .ant-picker-input {
    input {
      text-align: center;
      color: #ffffff;
    }
    ::placeholder {
      color: #ffffff;
    }
  }
`;
const CustomSelect = styled(Select) `
  .ant-select-selector {
    background: #4c859f !important;
    border: 0;
    border-radius: 0.25rem !important;
    color: #ffffff;
  }
  > span,
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: #ffffff;
  }
`;
