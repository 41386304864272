/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createPin, getAuthorityInformation, updateAuthorityInformation, updatePin, updateSignature, verifyPin, } from "./action";
import { initialAuthorityInformation } from "./types";
const initialState = {
    authorityInformation: {
        data: initialAuthorityInformation,
        status: false,
        error: null,
    },
};
const authorisingAuthoritySlice = createSlice({
    name: "authorisingAuthority",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuthorityInformation.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.authorityInformation.data = initialAuthorityInformation;
                message.error("Failed to get authorities");
            }
            else {
                state.authorityInformation.data = action.payload.data;
            }
        })
            .addCase(getAuthorityInformation.rejected, (state) => {
            state.authorityInformation.data = initialAuthorityInformation;
            message.error("Failed to get authorities");
        })
            .addCase(updateAuthorityInformation.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to save authorities");
            }
            else {
                message.success("Succeed to save authorities");
            }
        })
            .addCase(updateAuthorityInformation.rejected, () => {
            message.error("Failed to save authorities");
        })
            .addCase(verifyPin.fulfilled, () => { })
            .addCase(verifyPin.rejected, () => {
            message.error("Failed to verify pin");
        })
            .addCase(updateSignature.fulfilled, () => { })
            .addCase(updateSignature.rejected, () => {
            message.error("Failed to update signature");
        })
            .addCase(updatePin.fulfilled, () => { })
            .addCase(updatePin.rejected, () => {
            message.error("Failed to update pin");
        })
            .addCase(createPin.fulfilled, () => { })
            .addCase(createPin.rejected, () => {
            message.error("Failed to create pin");
        });
    },
});
export const { setResetStore } = authorisingAuthoritySlice.actions;
export default authorisingAuthoritySlice.reducer;
