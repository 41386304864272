import { jsx as _jsx } from "react/jsx-runtime";
import { Space, Spin } from "antd";
import React from "react";
import styled from "styled-components";
export const PageLoading = () => (_jsx(PageLoadingWrapper, { children: _jsx(LoadingBox, { children: _jsx(Spin, { size: "large" }) }) }));
const LoadingBox = styled(Space) `
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1.25rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.625 rgba(0, 0, 0, 0.1);
`;
const PageLoadingWrapper = styled.div `
  background-image: url("/assets/images/background.png");
  background-color: #cccccc;
  background-size: 100vw 101vh;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
