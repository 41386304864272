import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { checkAuth } from "src/api/auth/login";
import { Col, Form, FormBox, InputWithLabel, Row } from "src/components/base";
import { fontWeight, SAColourScheme, validateMessage } from "src/constants";
export const PracticeForm = ({ practice }) => {
    const [form] = Form.useForm();
    const userInfo = useSelector(checkAuth.dataSelector);
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(practice);
    }, [practice, userInfo]);
    const onInputChange = () => () => void 0;
    return (_jsx(Form, { form: form, validateMessages: validateMessage, children: _jsxs(FormBox, { backgroundColor: SAColourScheme.PLASMA, icon: "/assets/svg/ic-form-practice-manager.svg", iconBorderColor: "#49ADB2", title: "Service Manager", titleStyle: {
                marginLeft: 0,
                flex: 1,
                justifyContent: "center",
                fontSize: "22px",
                fontWeight: fontWeight.BOLD,
                width: "100%",
            }, children: [_jsxs("div", { className: "form-principal-info", children: [_jsxs(Row, { gutter: [10, 10], children: [_jsx(Col, { xs: 6, xl: 6, children: _jsx(InputWithLabel, { label: "Honorific", field: "honorific", onInputChange: onInputChange, inputProps: {
                                            disabled: true,
                                        }, rules: [{ required: true }] }) }), _jsx(Col, { xs: 24, xl: 18, children: _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { span: 12, children: _jsx(InputWithLabel, { label: "First Name", field: "firstName", onInputChange: onInputChange, inputProps: {
                                                        disabled: true,
                                                    }, rules: [{ required: true }] }) }), _jsx(Col, { span: 12, children: _jsx(InputWithLabel, { label: "Last Name", field: "lastName", onInputChange: onInputChange, inputProps: {
                                                        disabled: true,
                                                    }, rules: [{ required: true }] }) })] }) })] }), _jsx(Row, { gutter: [10, 10], children: _jsx(Col, { offset: 12, span: 12, children: _jsx(InputWithLabel, { label: "Mobile Number", field: "mobile", onInputChange: onInputChange, inputProps: {
                                        disabled: true,
                                    }, rules: [{ required: true }] }) }) }), _jsx(InputWithLabel, { label: "Email Address", field: "email", onInputChange: onInputChange, inputProps: {
                                disabled: true,
                            }, rules: [{ required: true }] })] }), _jsx(InputWithLabel, { label: "ID", field: "uuid", onInputChange: onInputChange, inputProps: {
                        disabled: true,
                        hidden: true,
                    } }), _jsx(InputWithLabel, { label: "Collection Centre UUID", field: "collectionCentreUUID", onInputChange: onInputChange, inputProps: {
                        disabled: true,
                        hidden: true,
                    } })] }) }));
};
