/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import moment from "moment";
import { $get } from "src/infra/http";
const getListClockInClockOut = createAsyncThunk("getListClockInClockOut", async (_, thunkApi) => {
    const state = thunkApi.getState().platformManagementPage.clockOnClockOffPage;
    const date = moment(state.selectedDate).format("YYYY-MM-DD");
    const queryString = stringify({
        date,
    });
    return await $get(`/collection-manager/pm/collect-assist/list?${queryString}`);
});
const getListCollectors = createAsyncThunk("getListCollectors", async () => await $get(`/collection-manager/pm/collect-assist/get-collectors`));
const getCollectorWorkRecord = createAsyncThunk("getCollectorWorkRecord", async (_, thunkApi) => {
    const state = thunkApi.getState().platformManagementPage.clockOnClockOffPage;
    const collectorId = state.selectedCollector?.id;
    const startDate = state.workRecordStartDate && moment(state.workRecordStartDate).format("YYYY-MM-DD");
    const endDate = state.workRecordEndDate && moment(state.workRecordEndDate).format("YYYY-MM-DD");
    const queryString = stringify({
        collectorId,
        startDate,
        endDate,
    });
    return await $get(`/collection-manager/pm/collect-assist/work-record?${queryString}`);
});
export { getListClockInClockOut, getListCollectors, getCollectorWorkRecord };
