import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useEffect, useRef, useState } from "react";
import { Test_Type } from "src/constants";
import { RequestTestContent, RequestTestWrapper } from "./index.style";
const TEST_TYPE = {
    DRUG_TEST: 1,
    ALCOHOL_TEST: 2,
};
export const RequestTest = (props) => {
    const buttonRef = useRef(null);
    const [contentWidth, setContentWidth] = useState(0);
    const [value, setValue] = useState("");
    const [drugTest, setDrugTest] = useState("");
    const [alcoholTest, setAlcoholTest] = useState("");
    const [open, setOpen] = useState(false);
    const { requestedTestType, field, testTypeUUIDList, disabled } = props;
    const [testList, setTestList] = useState([]);
    useEffect(() => {
        if (requestedTestType && requestedTestType.length) {
            const curTestList = requestedTestType.map((item) => ({
                uuid: item.id,
                testName: item.name,
                isCheck: false,
                isDisabled: !item.isValid || false,
                type: item.type || "Drug Test",
            }));
            setTestList(curTestList);
        }
    }, [requestedTestType]);
    useEffect(() => {
        if (testList.length && testTypeUUIDList && testTypeUUIDList.length) {
            const curTestList = testList.map((test) => {
                if (testTypeUUIDList.indexOf(test.uuid) !== -1) {
                    return {
                        ...test,
                        isCheck: true,
                    };
                }
                else {
                    return test;
                }
            });
            curTestList.forEach((test) => {
                if (test.type == TEST_TYPE.DRUG_TEST && test.isCheck) {
                    setDrugTest(test.testName);
                }
                if (test.type == TEST_TYPE.ALCOHOL_TEST && test.isCheck) {
                    setAlcoholTest(test.testName);
                }
            });
            setTestList(curTestList);
        }
    }, [testTypeUUIDList]);
    const onCheck = (test) => {
        if (test.type == 1) {
            const newList = testList.map((item) => {
                if (item.uuid == test.uuid) {
                    return {
                        ...item,
                        isCheck: true,
                    };
                }
                else if (item.type == 2) {
                    return item;
                }
                else {
                    return {
                        ...item,
                        isCheck: false,
                    };
                }
            });
            setTestList(newList);
            setDrugTest(test.testName);
        }
        else {
            const newList = testList;
            newList[testList.indexOf(test)] = {
                ...test,
                isCheck: true,
            };
            setTestList(newList);
            setAlcoholTest(test.testName);
        }
    };
    const onUnCheck = (test) => {
        const newList = testList.map((item) => {
            if (item.uuid == test.uuid) {
                return {
                    ...item,
                    isCheck: false,
                };
            }
            else {
                return item;
            }
        });
        setTestList(newList);
        if (test.type == 1) {
            setDrugTest("");
        }
        else {
            setAlcoholTest("");
        }
    };
    useEffect(() => {
        if (drugTest && alcoholTest) {
            setValue(`${drugTest}, ${alcoholTest}`);
        }
        else if (drugTest && !alcoholTest) {
            setValue(drugTest);
        }
        else if (!drugTest && alcoholTest) {
            setValue(alcoholTest);
        }
        else {
            setValue("");
        }
        //
        const valueList = testList
            .filter((item) => item.isCheck)
            .map((item) => ({
            uuid: item.uuid,
            testName: item.testName,
            type: item.type,
        }));
        if (valueList && valueList.length) {
            props.onInputChange({ field, value: valueList });
        }
        else {
            props.onInputChange({ field, value: null });
        }
    }, [drugTest, alcoholTest]);
    useEffect(() => {
        setContentWidth(buttonRef?.current?.clientWidth);
    }, [buttonRef.current]);
    const onConfirm = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const content = (_jsxs(RequestTestContent, { style: { width: contentWidth }, children: [_jsx("p", { className: "title", children: "Drug Test" }), testList
                .filter((item) => item.type == Test_Type.DRUG_TEST)
                .map((test, index) => (_jsxs("div", { className: `value ${test.isDisabled ? "disabled" : ""}`, children: [_jsx("p", { children: test.testName }), test.isCheck && (_jsx(Button, { className: "check-btn", disabled: test.isDisabled, onClick: () => onUnCheck(test), icon: _jsx(CheckOutlined, { style: { color: "#156CC9", fontWeight: "bold", fontSize: 16 } }) })), !test.isCheck && (_jsx(Button, { className: "check-btn", onClick: () => onCheck(test), disabled: test.isDisabled }))] }, index))), _jsx("p", { className: "title", children: "Alcohol Test" }), testList
                .filter((item) => item.type == Test_Type.ALCOHOL_TEST)
                .map((test, index) => (_jsxs("div", { className: `value ${test.isDisabled ? "disabled" : ""}`, children: [_jsx("p", { children: test.testName }), test.isCheck && (_jsx(Button, { className: "check-btn", onClick: () => onUnCheck(test), icon: _jsx(CheckOutlined, { style: { color: "#156CC9", fontWeight: "bold", fontSize: 16 } }) })), !test.isCheck && _jsx(Button, { className: "check-btn", onClick: () => onCheck(test) })] }, index))), _jsx(Button, { className: "ok-btn", onClick: onConfirm, children: "OK" })] }));
    const onSelect = () => {
        setContentWidth(buttonRef?.current?.clientWidth);
    };
    return (_jsxs(RequestTestWrapper, { children: [!disabled && (_jsx(Popover, { placement: "bottomLeft", content: content, trigger: "click", visible: open, onVisibleChange: handleOpenChange, children: _jsx(Button, { ref: buttonRef, className: `request-test-btn ${value ? "value" : ""}`, size: "large", onClick: onSelect, icon: _jsx(DownOutlined, { style: { color: "#00000040", fontSize: 12 } }), children: value ? value : "- Select -" }) })), disabled && (_jsx(Button, { ref: buttonRef, className: `request-test-btn ${value ? "value" : ""} disabled`, size: "large", 
                // onClick={onSelect}
                icon: _jsx(DownOutlined, { style: { color: "#00000040", fontSize: 12 } }), children: value ? value : "- Select -" }))] }));
};
