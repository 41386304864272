import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import IcAttachment from "src/assets/svg/ic-attachment.svg";
import { IconButton } from "src/components/base";
import { SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
export const HtmlAttachment = ({ templateHtml }) => {
    const componentRef = useRef(null);
    const { openPopupBox } = useContext(ContentContext);
    const handlePrint = useReactToPrint({
        pageStyle: `
      @page {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
      }
      html {
        -webkit-print-color-adjust: exact;
      }
      body 
      {
          background-color:#FFFFFF; 
          height: 296mm;
          border: 1px solid transparent;
          margin: 0px;  /* this affects the margin on the content before sending to printer */
      }
    `,
        content: () => componentRef.current,
    });
    return (_jsx(IcAttachment, { className: "attachment-icon", onClick: () => {
            openPopupBox(_jsxs(HtmlContentWrapper, { style: {
                    background: "#FFFFFF",
                    width: "100%",
                    overflowY: "scroll",
                    padding: "0 53px 0 37px",
                    border: "1px solid #ADB3BC",
                }, children: [_jsx(IconButton, { style: {
                            position: "absolute",
                            width: "28px",
                            height: "auto",
                            borderRadius: "4px",
                            right: 30,
                            top: 8,
                        }, icon: "/assets/svg/ic-print-2.svg", onClick: handlePrint }), _jsx("div", { ref: componentRef, dangerouslySetInnerHTML: { __html: templateHtml } })] }), null, {
                closeIconStyle: {
                    left: 14,
                    top: 11,
                    right: "unset",
                },
                popupStyle: {
                    width: 893,
                    height: 698,
                    background: "#E4E6EA",
                    padding: "40px 15px 17px 18px",
                    position: "relative",
                },
            });
        } }));
};
const HtmlContentWrapper = styled.div `
  background-color: ${SAColourScheme.WHITE};
`;
