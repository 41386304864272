import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { isString } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Col, Divider, Form, Image, InputWithLabel, Row, SAButton, Text, } from "src/components/base";
import IconTitle from "src/components/IconTitle";
import MainLayout from "src/components/layouts/MainLayout";
import { uploadFile } from "src/infra/file";
import { UploadPrefix } from "src/infra/file/types";
import DeleteAccountPopup from "src/pages/UserProfilePage/components/DeleteAccountPopup";
import DeleteAccountPage from "src/pages/UserProfilePage/components/DeletedAccountPage";
import PasswordUpdatePage from "src/pages/UserProfilePage/components/PasswordUpdatePage";
import UpdatePasswordPopup from "src/pages/UserProfilePage/components/UpdatePasswordPopup";
import { getUserInformation, updateUserAvatar, updateUserDetail, } from "src/pages/UserProfilePage/store/action";
import { selectUserProfile } from "src/pages/UserProfilePage/store/selector";
import { setUserAvatar } from "src/pages/UserProfilePage/store/slice";
import { auMobilePattern } from "src/patterns/patterns";
import ContentContext from "src/providers/content";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { UploadPopup } from "./components/UploadPopup";
import { UserProfilePageWrapper } from "./index.style";
const validateMessage = {
    required: "is required",
};
export var UserAction;
(function (UserAction) {
    UserAction[UserAction["MAIN_PAGE"] = 0] = "MAIN_PAGE";
    UserAction[UserAction["CHANGE_PASSWORD"] = 1] = "CHANGE_PASSWORD";
    UserAction[UserAction["DELETE_ACCOUNT"] = 2] = "DELETE_ACCOUNT";
})(UserAction || (UserAction = {}));
export const UserProfilePage = () => {
    const dispatch = useAppDispatch();
    const [profileForm] = Form.useForm();
    const userProfile = useAppSelector(selectUserProfile);
    const [onEdit, setOnEdit] = useState(false);
    const [action, setAction] = useState(UserAction.MAIN_PAGE);
    const { openMessageBox, openPopupBox, closePopupBox } = useContext(ContentContext);
    const isLoading = useAppSelector(selectIsLoading);
    const [showCard, setShowCard] = useState(false);
    const handleImageClick = () => {
        setShowCard(!showCard);
    };
    const onSubmit = async () => {
        const userInfo = profileForm.getFieldsValue();
        dispatch(setIsLoading(true));
        await dispatch(updateUserDetail(userInfo));
        dispatch(setIsLoading(false));
        setOnEdit(false);
    };
    const removeUserAvatar = async () => {
        await dispatch(updateUserAvatar(""));
        dispatch(setUserAvatar(""));
        setShowCard(!showCard);
        closePopupBox();
    };
    const uploadUserAvatar = async (uploadedFile) => {
        const savedPath = await uploadFile(uploadedFile, UploadPrefix.Avatar);
        if (isString(savedPath)) {
            await dispatch(updateUserAvatar(savedPath));
            setShowCard(!showCard);
            closePopupBox();
        }
    };
    useEffect(() => {
        dispatch(getUserInformation());
    }, []);
    useEffect(() => {
        const dataForm = {
            ...userProfile,
        };
        profileForm.setFieldsValue(dataForm);
    }, [userProfile]);
    const handleChangeActionType = (userAction) => {
        setAction(userAction);
        closePopupBox();
    };
    return (_jsx(MainLayout, { children: _jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs("div", { style: {
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                        minHeight: "79vh",
                    }, children: [action === UserAction.MAIN_PAGE && (_jsxs(Row, { style: {
                                justifyContent: "space-around",
                                backgroundColor: "white",
                                padding: "4rem 8%",
                            }, children: [_jsx(Col, { style: {
                                        background: "white",
                                        padding: "20px",
                                    }, xs: 24, xxl: 15, children: _jsxs(UserProfilePageWrapper, { children: [_jsxs(Col, { style: { background: "#E4F6FB", overflow: "hidden" }, xs: 24, md: 8, lg: 8, children: [_jsxs("div", { style: {
                                                            height: "40%",
                                                            background: "#01B4D2",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            position: "relative",
                                                        }, children: [_jsx(Image, { src: userProfile.avatarUrl, width: "70%", height: "95%", style: {
                                                                    border: "4px solid #80d9e8",
                                                                    borderRadius: 8,
                                                                    objectFit: "cover",
                                                                }, preview: false, onClick: handleImageClick, fallback: "/assets/svg/ic-user-avatar-placeholder.svg" }), showCard && (_jsxs(Card, { style: {
                                                                    position: "absolute",
                                                                    top: "65%",
                                                                    left: "50%",
                                                                    flexDirection: "column",
                                                                    display: "flex",
                                                                    padding: "20px",
                                                                    backgroundColor: "white",
                                                                    translate: "-50% -50%",
                                                                    gap: "5px",
                                                                }, children: [_jsx(Button, { style: { textTransform: "none" }, variant: "contained", startIcon: _jsx(UploadIcon, {}), onClick: () => {
                                                                            openPopupBox(_jsx(UploadPopup, { uploadUserAvatar: uploadUserAvatar, accept: "image/*" }));
                                                                        }, children: "Upload" }), _jsx(Button, { startIcon: _jsx(DeleteOutlineIcon, {}), onClick: removeUserAvatar, style: {
                                                                            textTransform: "none",
                                                                            backgroundColor: "#ADB3BC",
                                                                        }, variant: "contained", children: "Delete" })] }))] }), _jsx("div", { className: "user-name", children: _jsx(Text, { name: userProfile
                                                                ? userProfile.firstName + " " + userProfile.lastName
                                                                : "User name" }) }), _jsx("div", { className: "user-role", children: _jsx(Text, { name: userProfile.role }) }), _jsxs("div", { style: {
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            margin: "15px 25px",
                                                        }, children: [_jsx("div", { className: "user-id-number title", style: {
                                                                    display: "flex",
                                                                    flex: 0.3,
                                                                }, children: _jsx(Text, { name: "ID" }) }), _jsx("div", { className: "user-id-number value", style: {
                                                                    display: "flex",
                                                                    flex: 1,
                                                                }, children: _jsx(Text, { name: userProfile.saId || "ID NUMBER" }) })] }), _jsxs("div", { className: "qr-code", children: [_jsx(QRCode, { value: userProfile ? userProfile.saId : "ID NUMBER", ecLevel: "H" }), _jsx(Image, { wrapperStyle: {
                                                                    position: "absolute",
                                                                    zIndex: 1,
                                                                    height: "65px",
                                                                    width: "65px",
                                                                }, src: "/assets/svg/qr-logo.svg", preview: false })] })] }), _jsx(Col, { lg: 16, md: 16, xs: 24, style: {
                                                    padding: "20px 60px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }, children: _jsxs(Form, { form: profileForm, onFinish: onSubmit, validateMessages: validateMessage, children: [_jsx("div", { className: "button-edit", children: onEdit ? (_jsxs(_Fragment, { children: [_jsx(SAButton, { name: "Cancel", style: {
                                                                            color: "#1A8CFF",
                                                                            background: "#FFFFFF",
                                                                            fontSize: "16px",
                                                                            fontWeight: "500",
                                                                            height: "30px",
                                                                            width: "83px",
                                                                            border: "1px solid #1A8CFF",
                                                                        }, onClick: () => {
                                                                            profileForm.resetFields();
                                                                            profileForm.setFieldsValue(userProfile);
                                                                            setOnEdit(!onEdit);
                                                                        } }), _jsx(SAButton, { name: "Save", htmlType: "submit", style: {
                                                                            color: "white",
                                                                            background: "#1A8CFF",
                                                                            fontSize: "16px",
                                                                            fontWeight: "500",
                                                                            height: "30px",
                                                                            width: "83px",
                                                                            border: "2px solid #FFFFFF",
                                                                        } })] })) : (_jsx(SAButton, { onClick: () => {
                                                                    setOnEdit(!onEdit);
                                                                }, name: "Edit", style: {
                                                                    color: "white",
                                                                    background: "#ADB3BC",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                    height: "30px",
                                                                    width: "83px",
                                                                    border: "2px solid #FFFFFF",
                                                                } })) }), _jsxs("div", { className: "basic-info", children: [_jsx(Typography, { className: "info-title", children: "BASIC INFO" }), _jsxs(Row, { gutter: [10, 10], children: [_jsx(Col, { md: 8, xs: 24, children: _jsx(InputWithLabel, { label: "Title", field: "title", 
                                                                                //
                                                                                inputProps: {
                                                                                    type: "select",
                                                                                    options: [
                                                                                        {
                                                                                            label: "Dr",
                                                                                            value: "dr",
                                                                                        },
                                                                                        {
                                                                                            label: "Miss",
                                                                                            value: "miss",
                                                                                        },
                                                                                        {
                                                                                            label: "Mr",
                                                                                            value: "mr",
                                                                                        },
                                                                                        {
                                                                                            label: "Mrs",
                                                                                            value: "mrs",
                                                                                        },
                                                                                        {
                                                                                            label: "Ms",
                                                                                            value: "ms",
                                                                                        },
                                                                                    ],
                                                                                    disabled: !onEdit,
                                                                                }, rules: [{ required: true }] }) }), _jsxs(Col, { span: 24, children: [_jsx(InputWithLabel, { label: "First Name", field: "firstName", inputProps: {
                                                                                        disabled: !onEdit,
                                                                                    }, rules: [{ required: true }] }), _jsx(InputWithLabel, { label: "Last Name", field: "lastName", inputProps: {
                                                                                        disabled: !onEdit,
                                                                                    }, rules: [{ required: true }] })] })] })] }), _jsx(Divider, {}), _jsxs("div", { className: "contact-info", children: [_jsx(Typography, { className: "info-title", children: "CONTACT INFO" }), _jsx(Row, { children: _jsxs(Col, { span: 24, children: [_jsx(InputWithLabel, { label: "Email Address", field: "email", inputProps: {
                                                                                    disabled: !onEdit,
                                                                                }, rules: [{ required: true }] }), _jsx(InputWithLabel, { label: "Mobile Number", field: "mobile", inputProps: {
                                                                                    disabled: !onEdit,
                                                                                    prefix: "+61",
                                                                                }, rules: [
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Mobile number is required",
                                                                                    },
                                                                                    {
                                                                                        pattern: auMobilePattern,
                                                                                        message: "Please enter a valid phone number",
                                                                                    },
                                                                                ] })] }) })] }), _jsx(InputWithLabel, { label: "UID", field: "uid", inputProps: {
                                                                hidden: true,
                                                            } })] }) })] }) }), _jsxs(Col, { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "20px",
                                        padding: "20px",
                                    }, xs: 24, xxl: 9, children: [_jsx(Card, { style: { backgroundColor: "#F6FBFB" }, children: _jsxs("div", { style: {
                                                    padding: "2vh",
                                                    gap: "1vh",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "grey",
                                                }, children: [_jsx(IconTitle, { title: "Registered Email", icon: _jsx(MailOutlineIcon, { fontSize: "medium" }) }), _jsx(Typography, { children: "Your registered email address is your Sign In ID and it can not be changed." }), _jsx(TextField, { value: userProfile?.email, style: { backgroundColor: "white" }, size: "small", fullWidth: true, InputProps: {
                                                            readOnly: true,
                                                        } }), _jsx(Typography, { children: "If you would like to change your email address, please ask your Service Manager for a new invitation email with your new email address." }), _jsx(Typography, { children: "After you have registered with your new email address successfully, then you can delete your current account." }), _jsx(Typography, { children: "If you are the Service Manager or Service Owner then please contact Sample Assist." })] }) }), _jsx(Card, { style: { backgroundColor: "#F6FBFB" }, children: _jsxs("div", { style: {
                                                    padding: "2vh",
                                                    gap: "1vh",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "grey",
                                                }, children: [_jsx(IconTitle, { title: "Password", icon: _jsx(LockResetIcon, { fontSize: "medium" }) }), _jsx(Typography, { children: "Choose a strong password and please don\u2019t reuse it for other accounts." }), _jsx(Button, { onClick: () => {
                                                            openPopupBox(_jsx(UpdatePasswordPopup, { onUpdateStatus: handleChangeActionType }));
                                                        }, style: {
                                                            alignSelf: "flex-end",
                                                            color: "white",
                                                            background: "#1A8CFF",
                                                            borderRadius: "8px",
                                                            border: "2px solid #FFFFFF",
                                                            textTransform: "none",
                                                        }, children: "Change Password" })] }) }), _jsx(Card, { style: { backgroundColor: "#F6FBFB" }, children: _jsxs("div", { style: {
                                                    padding: "2vh",
                                                    gap: "1vh",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "grey",
                                                }, children: [_jsx(IconTitle, { title: "Delete Account", icon: _jsx(DeleteForeverIcon, { fontSize: "medium" }) }), _jsx(Typography, { children: "It will delete your profile and you will not be able to access to Collection Manager." }), _jsx(Button, { onClick: () => {
                                                            openPopupBox(_jsx(DeleteAccountPopup, { onUpdateStatus: handleChangeActionType, openMessageBox: openMessageBox }));
                                                        }, style: {
                                                            alignSelf: "flex-end",
                                                            color: "white",
                                                            background: "#ADB3BC",
                                                            borderRadius: "8px",
                                                            border: "2px solid #FFFFFF",
                                                            textTransform: "none",
                                                        }, children: "Delete Account" })] }) })] })] })), action === UserAction.CHANGE_PASSWORD && (_jsx(PasswordUpdatePage, { onStatusUpdate: handleChangeActionType, openPopupBox: openPopupBox, openMessageBox: openMessageBox })), action === UserAction.DELETE_ACCOUNT && (_jsx(DeleteAccountPage, { onStatusUpdate: handleChangeActionType, openPopupBox: openPopupBox, closePopupBox: closePopupBox }))] })] }) }));
};
