import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { OnsiteClientType } from "src/pages/JobManagement/store/types";
import { archiveClient, createClientDetails, getClientDetail, getListArchivedClients, getListClients, restoreClient, updateClientDetails, } from "./action";
export const initialState = {
    listClientDetails: [],
    listArchivedClientDetails: [],
    listClientType: [
        {
            label: "All",
            value: 0,
        },
        {
            label: "Non-Call Out",
            value: OnsiteClientType.Normal,
        },
        {
            label: "Call Out",
            value: OnsiteClientType.Callout,
        },
    ],
    clientDetails: null,
    isEdit: false,
    archiveTableVisible: false,
    selectedClientType: 0,
    clientName: "[New Cllient]",
    pagingMetaData: null,
    pagingMetaDataArchive: null,
    searchString: null,
};
const ClientDetailsSlice = createSlice({
    name: "ClientDetailsSlice",
    initialState,
    reducers: {
        setArchiveTableVisible(state, action) {
            state.archiveTableVisible = action.payload;
        },
        setIsEdit(state, action) {
            state.isEdit = action.payload;
        },
        setSelectedClientType(state, action) {
            state.selectedClientType = action.payload;
        },
        setClientName(state, action) {
            state.clientName = action.payload;
        },
        setSearchString(state, action) {
            state.searchString = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClients.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listClientDetails = initialState.listClientDetails;
                message.error("Failed to fetch list of clients");
            }
            else {
                state.pagingMetaData = action.payload.pagingMeta;
                state.listClientDetails = action.payload.data;
            }
        })
            .addCase(getListClients.rejected, () => {
            message.error("Failed to fetch list of clients");
        })
            .addCase(getListArchivedClients.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch list of archived clients");
            }
            else {
                state.pagingMetaDataArchive = action.payload.pagingMeta;
                state.listArchivedClientDetails = action.payload.data;
            }
        })
            .addCase(getListArchivedClients.rejected, () => {
            message.error("Failed to fetch list of archived clients");
        })
            .addCase(archiveClient.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error(action.payload.message);
            }
            else {
                message.success("Client archived successfully");
            }
        })
            .addCase(archiveClient.rejected, () => {
            message.error("Failed to archive client");
        })
            .addCase(restoreClient.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to restore client");
            }
            else {
                message.success("Client restored successfully");
            }
        })
            .addCase(restoreClient.rejected, () => {
            message.error("Failed to restore client");
        })
            .addCase(getClientDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.clientDetails = initialState.clientDetails;
                message.error("Failed to fetch location details");
            }
            else {
                state.clientDetails = action.payload.data;
                state.clientName = action.payload.data.name;
            }
        })
            .addCase(getClientDetail.rejected, () => {
            message.error("Failed to fetch location details");
        })
            .addCase(createClientDetails.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create client details");
            }
            else {
                message.success("Client details created successfully");
            }
        })
            .addCase(createClientDetails.rejected, () => {
            message.error("Failed to create client details");
        })
            .addCase(updateClientDetails.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update client details");
            }
            else {
                message.success("Client details updated successfully");
            }
        })
            .addCase(updateClientDetails.rejected, () => {
            message.error("Failed to update client details");
        });
    },
});
export const { setIsEdit, setSearchString, setArchiveTableVisible, setResetStore, setSelectedClientType, setClientName, } = ClientDetailsSlice.actions;
export default ClientDetailsSlice.reducer;
