import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { NewDevice } from "./components/NewDevice";
import { DeviceTypes } from "./components";
const TestingDeviceRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/testing-devices") {
            navigate("/testing-devices/0");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/testing-devices/*", element: _jsx(DeviceTypes, {}) }), _jsx(Route, { path: "/testing-devices/AddNew", element: _jsx(NewDevice, {}) })] }) }));
};
export default TestingDeviceRoute;
