import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { getAllPatients } from "src/api/patientList/patientListService";
import ContentContext from "src/providers/content";
import { HeaderFilter } from "./components/HeaderFilter";
import PatientList from "./components/PatientList";
import { PageWrapper } from "./index.style";
const PatientListPage = () => {
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [collectionPoint, setCollectionPoint] = useState("All");
    const [patientListData, setPatientListData] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [searchString, setSearchString] = useState("");
    const [reload, setReload] = useState(false);
    const [displayColumns, setDisplayColumns] = useState([
        "date",
        "time",
        "firstName",
        "lastName",
        "dob",
        "age",
        "sex",
        "rto",
        "requestTest",
    ]);
    const selectColumn = (cols) => {
        setDisplayColumns(cols);
    };
    const selectCollectionPoint = (point) => {
        setCollectionPoint(point);
    };
    const getData = async () => {
        const result = await getAllPatients(startDate, endDate);
        setPatientListData(result.data);
        setReload(false);
        closeLoadingScreen();
        const tempValue = searchString;
        setSearchString("");
        setTimeout(() => {
            setSearchString(tempValue);
        }, 2000);
    };
    useEffect(() => {
        openLoadingScreen();
        getData();
    }, [startDate, endDate, reload]);
    return (_jsxs(PageWrapper, { children: [_jsx(HeaderFilter, { selectColumn: selectColumn, selectCollectionPoint: selectCollectionPoint, selectStartDate: setStartDate, selectEndDate: setEndDate, onSearchString: setSearchString, startDate: startDate, endDate: endDate }), _jsx(PatientList, { displayColumns: displayColumns, searchString: searchString, patientListData: patientListData, reload: setReload, collectionPoint: collectionPoint })] }));
};
export default PatientListPage;
