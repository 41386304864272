import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Col, Dropdown, Image, Menu, Row, Space, Switch } from "antd";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "src/api/auth/login";
import { createTestingDevice, toggleInUseTestingDevice, } from "src/api/testingDevices/testingDeviceService";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDate, getDateFormat } from "src/utils/helper";
import { CardWrapper } from "./index.style";
import ICColumnOptions from "/public/assets/svg/ic-column-view-options.svg";
// List view common for all device type
// Few fields modified based on the device type
export const ColumnView = ({ name, noDataImage, getData, data, collectionPoints, deleteTD, archiveTD, }) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    // For filtering data based on collection points
    const getCollectionPointName = (id) => {
        const collectionPoint = collectionPoints.find((point) => point.id === id);
        return collectionPoint ? collectionPoint.name : "Unknown Collection Point";
    };
    const userInfo = useSelector(checkAuth.dataSelector);
    return (_jsx(CardWrapper, { children: data.length > 0 ? (_jsx("div", { className: "grid-container", children: data.map((item, index) => (_jsx(Card, { style: {
                    background: "#F7F8F8",
                    borderTop: "10px solid #EBEDF0",
                }, children: _jsxs("div", { style: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }, children: [_jsx("div", { style: { width: 180, height: 180, marginRight: "20px" }, children: item.fontImageUrl !== "" ? (_jsx(Image, { className: "cover-image", src: item.fontImageUrl, alt: "No File", preview: false, height: "100%", width: "100%", style: {
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    background: "white",
                                    border: "1px solid #D9D9D9",
                                    padding: "10px",
                                } })) : (_jsx(Image, { className: "cover-image", src: noDataImage, alt: "No File", preview: false, height: "100%", width: "100%", style: {
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    background: "white",
                                    border: "1px solid #D9D9D9",
                                    padding: "10px",
                                } })) }), _jsxs("div", { style: { width: "100%" }, children: [_jsxs(Row, { children: [_jsx(Col, { span: 14, children: _jsx(Space, { children: _jsx("a", { onClick: () => navigate(`/testing-devices/AddNew?name=${name}`, {
                                                        state: { record: item },
                                                    }), style: {
                                                        color: "#2A44FF",
                                                        textDecoration: "underline",
                                                    }, children: item.deviceName }) }) }), _jsxs(Col, { span: 10, style: { display: "flex", justifyContent: "flex-end" }, children: [_jsx("span", { children: "In Use" }), _jsx(Switch, { style: {
                                                        marginLeft: "10px",
                                                        backgroundColor: item.isInUse ? "#1A8CFF" : "#C5C7D1",
                                                    }, checked: item.isInUse, onChange: async () => {
                                                        openLoadingScreen();
                                                        const updateUsage = {
                                                            isInUse: !item.isInUse,
                                                        };
                                                        const response = await toggleInUseTestingDevice(userInfo.accessToken, item.id, updateUsage);
                                                        if (response.status === "success") {
                                                            openToast("Updated successfully");
                                                            getData();
                                                        }
                                                        else {
                                                            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                                                        }
                                                        closeLoadingScreen();
                                                    } })] })] }), _jsx("div", { style: {
                                        borderTop: "1px solid #ADB3BC",
                                        marginTop: "5px",
                                    } }), _jsxs(Row, { style: { marginTop: "2px" }, children: [name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Serial Number" }) })) : (_jsx(Col, { span: 8, children: _jsxs("span", { className: "card-heading", children: ["Batch Number ", _jsx("br", {}), "(Lot Number)"] }) })), name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Certificate No." }) })) : (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Expiry Date" }) })), _jsx(Col, { span: 8, children: _jsxs("span", { className: "card-heading", children: ["Collection Point ", _jsx("br", {}), "(Usage Location)"] }) })] }), _jsxs(Row, { style: { marginTop: "2px" }, children: [_jsx(Col, { span: 8, children: name === "Breathalysers" ? (_jsx("span", { className: "card-text", children: item.serialNumber })) : (_jsx("span", { className: "card-text", children: item.lotNumber })) }), name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-text", children: item.certificateNumber }) })) : (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-text", children: (() => {
                                                    const { expireAt } = item;
                                                    let formattedDate = "";
                                                    if (expireAt !== "") {
                                                        formattedDate = getDateFormat(new Date(expireAt), settings);
                                                    }
                                                    return (_jsx(_Fragment, { children: new Date(expireAt) < new Date() ? (_jsx("div", { style: {
                                                                width: "90px",
                                                                height: "25px",
                                                                background: "#FD0000",
                                                                fontSize: "13px",
                                                                color: "#FFFFFF",
                                                                paddingTop: "2px",
                                                                borderRadius: "15px",
                                                                textAlign: "center",
                                                            }, children: "Expired" })) : (formattedDate) }));
                                                })() }) })), _jsx(Col, { span: 8, children: _jsx("span", { className: "card-text", children: item.collectionPointIds.length > 1
                                                    ? "Multiple Locations"
                                                    : getCollectionPointName(item.collectionPointIds[0]) }) })] }), _jsx("div", { style: {
                                        borderTop: "1px solid #ADB3BC",
                                        marginTop: "5px",
                                    } }), _jsxs(Row, { children: [_jsxs(Col, { span: 22, children: [_jsxs(Row, { style: { marginTop: "2px" }, children: [name === "Breathalysers" ? (_jsx(Col, { span: 7, children: _jsx("span", { className: "card-heading", children: "Calibration Due Date" }) })) : (_jsx(Col, { span: 7, style: {
                                                                visibility: name === "Oral Fluid Drug Screen Devices" ||
                                                                    name === "Oral Fluid Collection Devices"
                                                                    ? "hidden"
                                                                    : "visible",
                                                            }, children: _jsx("span", { className: "card-heading", children: "Summary Sheet" }) })), name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Certificate" }) })) : (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Laboratory Verification" }) })), name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Report" }) })) : (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-heading", children: "Specification Sheet" }) }))] }), _jsxs(Row, { style: { marginTop: "2px" }, children: [name === "Breathalysers" ? (_jsx(Col, { span: 8, children: _jsx("span", { className: "card-text", children: (() => {
                                                                    const { expireAt } = item;
                                                                    let formattedDate = "";
                                                                    if (expireAt !== "") {
                                                                        formattedDate = getDateFormat(new Date(expireAt), settings);
                                                                    }
                                                                    return (_jsx(_Fragment, { children: new Date(expireAt) < new Date() ? (_jsx("div", { style: {
                                                                                width: "90px",
                                                                                height: "25px",
                                                                                background: "#FD0000",
                                                                                fontSize: "13px",
                                                                                color: "#FFFFFF",
                                                                                paddingTop: "2px",
                                                                                borderRadius: "15px",
                                                                                textAlign: "center",
                                                                            }, children: "Over Due" })) : (formattedDate) }));
                                                                })() }) })) : (_jsx(Col, { span: 7, style: {
                                                                visibility: name === "Oral Fluid Drug Screen Devices" ||
                                                                    name === "Oral Fluid Collection Devices"
                                                                    ? "hidden"
                                                                    : "visible",
                                                            }, children: _jsx(Button, { className: "card-files", children: item.artgSummarySheetUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) }) })), _jsx(Col, { span: 8, children: _jsx(Button, { className: "card-files", children: name === "Breathalysers" ? (item.calibrationCertificateUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 }))) : item.laboratorySheetUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) }) }), _jsx(Col, { span: 8, children: _jsx(Button, { className: "card-files", children: name === "Breathalysers" ? (item.deviceManualUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 }))) : item.specificationSheetUrl !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) }) })] })] }), _jsx(Col, { span: 2, style: { display: "flex", alignItems: "flex-end" }, children: _jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                                                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                                                        width: "150px",
                                                    }, children: [_jsx(Menu.Item, { onClick: () => navigate(`/testing-devices/AddNew?name=${name}`, {
                                                                state: { record: item },
                                                            }), children: "View Details" }, "1"), _jsx(Menu.Item, { onClick: async () => {
                                                                openLoadingScreen();
                                                                const duplicatedRecord = {
                                                                    ...item,
                                                                    expireAt: getDate(item.expireAt).toDateString(),
                                                                    deviceName: item.deviceName + " - Copy",
                                                                    id: undefined,
                                                                };
                                                                const response = await createTestingDevice(userInfo.accessToken, duplicatedRecord);
                                                                if (response.status === "success") {
                                                                    openToast("Successfully duplicated");
                                                                    getData();
                                                                }
                                                                else {
                                                                    openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, `Something went wrong.\nPlease try again`);
                                                                }
                                                                closeLoadingScreen();
                                                            }, children: "Duplicate" }, "2"), _jsx(Menu.Item, { onClick: () => {
                                                                name === "Breathalysers"
                                                                    ? openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${item.deviceName} - Serial No. ${item.serialNumber} will be archived and no longer can be used by collectors`, () => {
                                                                        archiveTD(item.id);
                                                                    })
                                                                    : openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${item.deviceName} - Lot No. ${item.lotNumber} will be archived and no longer can be used by collectors`, () => {
                                                                        archiveTD(item.id);
                                                                    });
                                                            }, children: "Archive" }, "3"), _jsx(Menu.Item, { style: {
                                                                borderTop: "1px solid #ADB3BC",
                                                                width: "80%",
                                                                margin: "0 auto",
                                                            }, onClick: () => {
                                                                name === "Breathalysers"
                                                                    ? openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${item.deviceName} - Serial No. ${item.serialNumber} will be removed permanently and no longer can be used by collectors?`, () => {
                                                                        deleteTD(item.id);
                                                                    })
                                                                    : openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${item.deviceName} - Lot No. ${item.lotNumber} will be removed permanently and no longer can be used by collectors?`, () => {
                                                                        deleteTD(item.id);
                                                                    });
                                                            }, children: _jsx("span", { style: {
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                }, children: "Delete" }) }, "4")] }), trigger: ["click"], children: _jsx("div", { style: {
                                                        height: "25px",
                                                        width: "25px",
                                                        borderRadius: "50%",
                                                        background: "white",
                                                        cursor: "pointer",
                                                    }, children: _jsx(ICColumnOptions, { style: { marginLeft: "4px", marginBottom: "2px" } }) }) }) })] })] })] }) }, index))) })) : (_jsx("div", { style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "250px",
                border: "1px solid #ADB3BC",
            }, children: _jsx(Image, { src: "/assets/images/no-data.png", preview: false, height: 75, width: 55 }) })) }));
};
