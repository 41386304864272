import axios from "axios";
import { env } from "src/constants";
import { throwHttpRequestError } from "src/infra/http";
const API_URL = env.DOMAIN;
export const getAuthorities2 = async (idToken) => {
    try {
        const response = await axios.get(`${API_URL}/collection-manager/authority/`, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const saveAuthorities2 = async (idToken, formData) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/authority/`, {
            ...formData,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const verifyPin = async (idToken, pin) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/authority/verify-pin/`, {
            pin,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updateSignature = async (idToken, signature) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/authority/update-signature/`, {
            signature,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const updatePin = async (idToken, oldPin, newPin) => {
    try {
        const response = await axios.put(`${API_URL}/collection-manager/authority/update-pin/`, {
            oldPin,
            newPin,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const createPin = async (idToken, newPin) => {
    try {
        const response = await axios.post(`${API_URL}/collection-manager/authority/create-pin/`, {
            pin: newPin,
        }, {
            headers: { idToken, "Content-Type": "application/json" },
        });
        return response.data;
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
